import sessionStorageKeys from '@constants/sessionStorageKeys';

import actionTypes from '@context/actionTypes';

import useSessionStorage from '@hooks/useSessionStorage';

import {
  addDays,
  format,
  getDate,
  getMonth,
  getYear,
  isAfter,
  setHours,
  setMinutes,
} from 'date-fns';

type IsWeekendProp = string | Date;

const { setItem } = useSessionStorage();

/**
 * Parses a date string with dashes (e.g., "2021-04-27") into a Date object.
 * @param date - The date string to parse.
 * @returns date - The parsed Date object.
 */
export function parseDateWithDashes(date: string) {
  const [year, month, day] = date.split('-');
  return new Date(+year, +month - 1, +day);
}

/*
    Does the given date fall on a weekend?
    Weekend = Friday, Saturday or Sunday.
    Param: date string: '05/27/2022' | Date object

   In case you only need to check for 'Saturday | Sunday' you can use isWeekend() from 'date-fns'. More details here: https://date-fns.org/v2.28.0/docs/isWeekend
*/
export const isWeekend = (date: IsWeekendProp) => {
  let selectedDay;

  if (typeof date === 'string') {
    selectedDay = new Date(date).getDay();
  } else {
    selectedDay = date.getDay();
  }

  switch (selectedDay) {
    case 5: // friday
    case 6: // saturday
    case 0: // sunday
      return true;

    default:
      return false;
  }
};

/**
 * Returns the current date, or if the current time is past 2:00 PM,
 * returns tomorrow's date.
 *
 * @returns {Date} The effective date.
 */
export function getDateToUse(): Date {
  const now = new Date();
  const targetTime = setMinutes(setHours(now, 14), 0);
  return isAfter(now, targetTime) ? addDays(now, 1) : now;
}

/**
 * @param Date object
 * @returns month name, day name, year ex: "October 19, 1991"
 */
export const getDetailedDate = (date: Date) => {
  if (!date) return '';

  return format(date, 'MMMM d, yyyy');
};

/**
 * @param Date object
 * @returns year, month, day ex: "1991-10-19"
 */
export const getDateWithDashes = (date: Date) => {
  if (!date) {
    return '';
  }
  const day = getDate(date);
  const dayText = day < 10 ? `0${day}` : day;
  const monthNumber = getMonth(date);
  const monthText = monthNumber < 9 ? `0${monthNumber + 1}` : monthNumber + 1;
  const year = getYear(date);
  return `${year}-${monthText}-${dayText}`;
};

export const getDateWithSlashes = (date: Date, dayFirst: boolean = false) => {
  if (!date) {
    return '';
  }

  const day = getDate(date);
  const dayText = day < 10 ? `0${day}` : day;
  const monthNumber = getMonth(date);
  const monthText = monthNumber < 9 ? `0${monthNumber + 1}` : monthNumber + 1;
  const year = getYear(date);
  return dayFirst ? `${dayText}/${monthText}/${year}` : `${monthText}/${dayText}/${year}`;
};

export const getDetailedDateWithDayOfWeek = (date: Date) => {
  if (!date) {
    return '';
  }
  let dayValue = '';
  const detailedDate = getDetailedDate(date);
  if (date instanceof Date) {
    dayValue = format(date, 'EEEE');
  }
  return `${dayValue}, ${detailedDate}`;
};

export const getDetailedDateWithDayOfWeekShort = (date: Date, showYear?: boolean) => {
  if (!date) {
    return '';
  }

  return format(date, showYear ? 'EEE, MMM d, yyyy' : 'EEE, MMM d');
};

/**
 * @param str a string representation of a date ex: "2022-03-30"
 * @returns day name, month name, day date ex: "Wednesday, March 30"
 */
export const getBookedDate = (str: string) => {
  const newDate = parseDateWithDashes(str);
  return format(newDate, 'EEEE, MMMM d');
};

/**
 * Convert military time string to 12 hours.
 * @param time as string, ex: "10:00:00" | "14:00:00"
 * @param showMinutes boolean
 * @returns string, ex: "10:00am | 02:00pm"
 */
export const parseMilitaryTime = (time: string | undefined, showMinutes = true) => {
  if (!time) return '';

  const [hours, minutes] = time.split(':');

  // eslint-disable-next-line no-nested-ternary
  const parsedTime = +hours === 0 ? 12 : +hours <= 12 ? +hours : +hours - 12;

  return `${parsedTime.toString()}${showMinutes ? `:${minutes}` : ''}${+hours < 12 ? 'am' : 'pm'}`;
};

/**
 * @param Date object
 * @returns month name, year ex: "Oct 1991"
 */
export const getMonthAndYear = (date: string, fullMonth?: boolean) => {
  if (!date) {
    return '';
  }
  // Hack to convert date-time to exact date
  const convertedDate = new Date(`${date}T15:00:00`);

  return fullMonth ? format(convertedDate, 'MMMM yyyy') : format(convertedDate, 'MMM yyyy');
};

/**
 * Formats a date string or a Date object as "Month Day" with
 * the month name abbreviated (e.g., "Oct 19").
 *
 * @param {string|Date} date - The date string or Date object to be formatted.
 * @returns {string} - The formatted date string.
 *
 * @example
 * formatMonthAndDay('2023-04-09'); // Returns "Apr 9"
 * formatMonthAndDay(new Date('2023-04-09')); // Returns "Apr 9"
 */
export const getMonthAndDay = (date: string | Date) => {
  if (!date) {
    return '';
  }

  const convertedDate = typeof date === 'string' ? new Date(`${date}T15:00:00Z`) : date;
  const formattedDate = format(convertedDate, 'MMM d');
  return formattedDate;
};

/**
 * Generates a new Date object by concatenating the `date` and `time` parameters.
 *
 * @param {string|undefined} date - The date string to use in generating the new Date object.
 * @param {string|undefined} time - The time string to concatenate with the `date` parameter.
 * @returns {Date|string} - A new Date object representing the combined date and time,
 *                          or an empty string if `date` is falsy.
 */
export const generateDateTime = (date?: string, time?: string) => {
  if (!date) return '';

  const parsedTime = time?.replace('+00', '');

  return new Date(`${date} ${parsedTime}`);
};

/**
 * Gets the time as a number from the given date and time string.
 *
 * @param {string|undefined} date - The date string to extract the time from.
 * @param {string|undefined} time - The time string to extract the time from.
 * @returns {number} - The time as a number from the given date and time string,
 *                     or 0 if both `date` and `time` are falsy.
 */
export const getTimeAsNumber = (date?: string, time?: string) => {
  if (!date && !time) return 0;

  const dateTime = generateDateTime(date, time);

  return dateTime ? dateTime.getTime() : 0;
};

/**
 * Formats a given search date into a human-readable string.
 *
 * The function handles the following cases:
 * - If `searchDate` is the string `'any'`, it returns `"Any Date"`.
 * - If `searchDate` is an empty string, it returns today's date formatted as `"LLL d"`.
 * - Otherwise, it treats `searchDate` as either a Date object or a date string
 *   (e.g. "2021-04-27") and returns it formatted as `"LLL d"`.
 *
 * @param {string | Date} searchDate - A date string (e.g. "2021-04-27") or a Date object.
 *                                     Special values: 'any' or ''.
 * @returns {string} The formatted date string (e.g., "Apr 27" or "Any Date").
 *
 * @example
 * // Returns "Any Date"
 * getFormattedDate('any');
 *
 * @example
 * // Returns today's date formatted as "Apr 27" (assuming today is April 27)
 * getFormattedDate('');
 *
 * @example
 * // Returns "Apr 27"
 * getFormattedDate('2021-04-27');
 *
 * @example
 * // Returns "Apr 27"
 * getFormattedDate(new Date(2021, 3, 27));
 */
export function getFormattedDate(searchDate: string | Date): string {
  if (searchDate === 'any') {
    return 'Any Date';
  }

  if (searchDate === '') {
    return format(getDateToUse(), 'LLL d');
  }

  return format(searchDate instanceof Date ? searchDate : parseDateWithDashes(searchDate), 'LLL d');
}

export const pastEscapedSearchDate = (unescapedSearchDate: Date, dispatch: Function) => {
  const today = new Date();
  if (unescapedSearchDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
    dispatch({
      type: actionTypes.SET_SEARCH_DATE,
      payload: today,
    });
    setItem(sessionStorageKeys.SMART_CALENDAR_DATE, getDateWithDashes(today));
    return today;
  }
  return unescapedSearchDate;
};
