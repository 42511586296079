/* eslint-disable function-paren-newline */
import Product from '@customTypes/product';
import { SearchState } from '@customTypes/search';

import { chain } from 'lodash';

import { GenerateSearchUrl } from './url';

export const filterProductsByCategory = (products?: Product[], category?: string) => {
  if (!products) return [];

  if (!category || category.toLowerCase() === 'all') {
    return products;
  }

  return products.filter((product) => {
    const aggregateProductCategories = (() => {
      const rootLevelPC = product.product_categories ?? [];
      if (!product.is_product_time_group) {
        return rootLevelPC;
      }
      return [
        ...rootLevelPC,
        ...(product.children?.flatMap((child) => child.product_categories) ?? []),
      ];
    })();
    return aggregateProductCategories.some(
      (productCategory) => productCategory.name.toLowerCase() === category.toLowerCase(),
    );
  });
};

export const getUniqueProductCategories = (products?: Product[]) => {
  if (products) {
    const uniqueCategories = chain(products)
      .flatMap((product) => (product.product_categories || []).map((category) => category.name))
      .uniq()
      .value();

    return ['All', ...uniqueCategories];
  }

  return ['All'];
};

export const generateProductTabsHref = (searchParams: SearchState, cat: string) => {
  const searchParamsCopy = JSON.parse(JSON.stringify(searchParams));

  if (searchParamsCopy.country_code === '') {
    searchParamsCopy.country_code = 'us';
  }

  searchParamsCopy.product_category = cat === 'all' ? 'day-passes' : cat;

  return GenerateSearchUrl(searchParamsCopy);
};
