import { createContext, useContext } from 'react';
import { GetServerSidePropsContext } from 'next';
import { Location } from '@components/Hotels/types';
import { getCurrencyData, getLocations } from '@utils/services';

export const UserLocationContext = createContext<Location | null>(null);

/**
 * Get the user location from the server side request headers.
 * This function should be called by `getServerSideProps` in a page component.
 * @param context
 * @returns Location | null
 */
// TODO: explore the idea of returning the currency data every time - used on Layout and header.
export async function getServerSideUserLocation(
  context: GetServerSidePropsContext,
): Promise<Location | null> {
  const state = context.req.headers['cf-region'];
  const city = context.req.headers['cf-ipcity'];
  try {
    // Try getting cloudflare headers directly
    if (city && state) {
      const locations = await getLocations(`"${city}, ${state}"`);
      return locations.length > 0 ? locations[0] : null;
    }

    // fallback to currency api
    const currencyList = await getCurrencyData();
    const userCityAndState =
      currencyList.city && currencyList.state ? `${currencyList.city}, ${currencyList.state}` : '';
    if (userCityAndState) {
      const locations = await getLocations(`"${userCityAndState}"`);
      return locations.length > 0 ? locations[0] : null;
    }
  } catch (error) {
    console.error('GetServersideGeo: Could not determine user location');
  }
  return null;
}

/**
 * Hook for getting the user location
 */
export function useUserLocation() {
  const context = useContext(UserLocationContext);
  return context;
}

export default UserLocationContext.Provider;
