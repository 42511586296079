import { Location } from '@components/Hotels/types';
import { getDateWithSlashes } from '@helpers/date';
import { sendToGTM } from './GTM';

const filterIconClicked = (searchLocation: Location | null, searchDate: Date | undefined) => {
  sendToGTM({
    event: 'filters_icon_clicked',
    filters: {
      search_location: searchLocation && searchLocation.name ? searchLocation.name : null,
      search_date: searchDate ? getDateWithSlashes(searchDate) : null,
    },
  });
};

const filterAvailableHotelsClicked = (
  searchLocation: Location | null,
  searchDate: Date | undefined,
) => {
  sendToGTM({
    event: 'filters_available_hotels_clicked',
    filters: {
      search_location: searchLocation && searchLocation.name ? searchLocation.name : null,
      search_date: searchDate ? getDateWithSlashes(searchDate) : null,
    },
  });
};

const filterValueClicked = (
  eventName: string,
  searchLocation: Location | null,
  searchDate: Date | undefined,
) => {
  sendToGTM({
    event: eventName,
    filters: {
      search_location: searchLocation && searchLocation.name ? searchLocation.name : null,
      search_date: searchDate ? getDateWithSlashes(searchDate) : null,
    },
  });
};

const filterHotelStyleShowAllClicked = (
  searchLocation: Location | null,
  searchDate: Date | undefined,
) => {
  sendToGTM({
    event: 'filters_hotel_style_show_all_clicked',
    filters: {
      search_location: searchLocation && searchLocation.name ? searchLocation.name : null,
      search_date: searchDate ? getDateWithSlashes(searchDate) : null,
    },
  });
};

const filterShowResultsClicked = (
  searchLocation: Location | null,
  searchDate: Date | undefined,
) => {
  sendToGTM({
    event: 'filters_show_results_clicked',
    filters: {
      search_location: searchLocation && searchLocation.name ? searchLocation.name : null,
      search_date: searchDate ? getDateWithSlashes(searchDate) : null,
    },
  });
};

const filterClearAllClicked = (searchLocation: Location | null, searchDate: Date | undefined) => {
  sendToGTM({
    event: 'filters_clear_all',
    filters: {
      search_location: searchLocation && searchLocation.name ? searchLocation.name : null,
      search_date: searchDate ? getDateWithSlashes(searchDate) : null,
    },
  });
};

const filterZeroResultsViewed = (
  searchLocation: Location | null,
  searchDate: Date | undefined,
  appliedFilters: string,
) => {
  sendToGTM({
    event: 'filters_zero_results_viewed',
    filters: {
      search_location: searchLocation && searchLocation.name ? searchLocation.name : null,
      search_date: searchDate ? getDateWithSlashes(searchDate) : null,
      applied_filters: appliedFilters ?? null,
    },
  });
};

const filterAnyFilterClicked = (searchLocation: Location | null, searchDate: Date | undefined) => {
  sendToGTM({
    event: 'filters_any_filter_clicked',
    filters: {
      search_location: searchLocation && searchLocation.name ? searchLocation.name : null,
      search_date: searchDate ? getDateWithSlashes(searchDate) : null,
    },
  });
};

export {
  filterIconClicked,
  filterAvailableHotelsClicked,
  filterValueClicked,
  filterHotelStyleShowAllClicked,
  filterShowResultsClicked,
  filterClearAllClicked,
  filterZeroResultsViewed,
  filterAnyFilterClicked,
};
