import { useCallback, useRef, useEffect } from 'react';

export default function useDebounce<F extends (...args: any[]) => void>(fn: F, delay: number): F {
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const debouncedFuncRef = useRef<F>();

  useEffect(() => {
    debouncedFuncRef.current = fn;
  }, [fn]);

  return useCallback(
    (...args: Parameters<F>) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        if (debouncedFuncRef.current) debouncedFuncRef.current(...args);
      }, delay);
    },
    [delay],
  ) as F;
}
