/* eslint-disable import/prefer-default-export */
import {
  HotelIcon,
  LocationIcon,
  LocationTargetIcon,
  NetworkIcon,
  StateIcon,
} from '@resortpass/solar-icons';
import { Location } from '@components/Hotels/types';

import React from 'react';
import { SearchContextType } from '@context/SearchContext2025';

const locationTypeToIconMap = {
  city: <LocationIcon />,
  country: <NetworkIcon />,
  alias: <LocationIcon />,
  state: <StateIcon />,
  hotel: <HotelIcon />,
};

export const getLocationTypeIcon = (
  resultLocation: Location,
  userLocation: Location | null = null,
) => {
  if (resultLocation.name === userLocation?.name) {
    return <LocationTargetIcon />;
  }

  return locationTypeToIconMap[resultLocation.type as keyof typeof locationTypeToIconMap];
};

type SearchActions = {
  setSearchTerm: SearchContextType['setSearchTerm'];
  setCountryCode: SearchContextType['setCountryCode'];
  setStateCode: SearchContextType['setStateCode'];
  setCity: SearchContextType['setCity'];
  setHotelName: SearchContextType['setHotelName'];
  setAliasId: SearchContextType['setAliasId'];
};

export function applyUserLocation(
  userLocation: {
    name: string;
    country_code: string;
    state_code: string;
    city_name?: string;
    type: string;
    id: number;
  },
  searchActions: SearchActions,
) {
  const { setSearchTerm, setCountryCode, setStateCode, setCity, setHotelName, setAliasId } =
    searchActions;

  setSearchTerm(userLocation.name);
  setCountryCode(userLocation.country_code.toLowerCase());
  setCity('');
  setStateCode('');
  setHotelName('');
  setAliasId(0);

  switch (userLocation.type) {
    case 'state':
      setStateCode(userLocation.state_code.toLowerCase());
      setCity('');
      break;
    case 'city':
    case 'alias':
      if (userLocation.city_name) {
        setStateCode(userLocation.state_code.toLowerCase());
        setCity(userLocation.city_name.toLowerCase());
        setAliasId(userLocation.id);
      }
      break;
    case 'hotel':
      if (userLocation.city_name) {
        setStateCode(userLocation.state_code.toLowerCase());
        setCity(userLocation.city_name.toLowerCase());
        setHotelName(userLocation.name.toLowerCase());
      }
      break;
    default:
      break;
  }
}
