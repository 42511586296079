import React, { ReactNode } from 'react';

type Props = {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  children: ReactNode;
  className?: string;
  [key: string]: any;
};

const fontFamily = {
  1: 'font-rp-basetica-light',
  2: 'font-rp-basetica',
  3: 'font-rp-basetica',
  4: 'font-rp-basetica',
  5: 'font-rp-basetica',
  6: 'font-rp-basetica',
};

const sizeClasses = {
  1: 'text-h1 d:text-d-h1',
  2: 'text-h2 d:text-d-h2',
  3: 'text-h3 d:text-d-h3',
  4: 'text-h4 d:text-d-h4',
  5: 'text-h5 d:text-d-h5',
  6: 'text-h6 d:text-d-h6',
};

export default function Heading({ level = 4, children, className = '', ...props }: Props) {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements;

  return (
    <Tag
      className={`${sizeClasses[level]} ${className || ''} ${fontFamily[level]}`.trim()}
      {...props}
    >
      {children}
    </Tag>
  );
}
