/* eslint-disable no-nested-ternary */
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Header from '@components/Header/Header';
import useCookieConsent from '@hooks/useCookieConsent';
import { getCurrencyData } from '@utils/services';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import TagManager from '@utils/tags';
import useIsMobile from '@hooks/useIsMobile';
import { HomePageHotelsProvider } from '@context/HomePageHotelsContext';
import SkinnyBanner from '@components/CaptureEmail/SkinnyBanner/SkinnyBanner';
import useIsStickyHeaderVisible from '@hooks/useIsStickyHeaderVisible';
import { CAPTURE_EMAIL_VIEWED, EMAIL_CAPTURED } from '@constants/amplitudeEvents';
import { useEvents } from '@events/EventsProvider';
import { Location } from '@components/Hotels/types';
import UserLocationProvider from '@context/GeoIpDetectionContext';
import { SearchCategories } from '@customTypes/search';
import SearchProvider, { useDefaultContextImplementation } from '@context/SearchContext2025';
import { GenerateSearchUrl } from '@helpers/url';
import { useAmplitudeSearchTracker } from '@hooks/useAmplitudeTrackers';
import { getDateToUse } from '@helpers/date';
import Footer from '../Footer/Footer';
import HeaderBanner from '../HeaderBanner/HeaderBanner';

type Props = {
  children: ReactNode;
  searchBarEnabled?: boolean;
  searchProps?: any;
  fullHeight?: boolean;
  noHeader?: boolean;
  noFooter?: boolean;
  userLocation: Location | null;
  initialProductCategories?: SearchCategories;
};

const pathsToHideFooter = [
  'guest-billing-detail',
  '/checkout/surveys',
  'login',
  'account-check',
  'forgot-password',
  'update-password',
  'create-password',
];
const pathsToHideFooterMobile = ['/products/checkout-confirmation'];
const pathsToHideHeader = [
  '/checkout/surveys',
  'login',
  'account-check',
  'forgot-password',
  'update-password',
  'create-password',
];

export default function Layout({
  children,
  searchBarEnabled = false,
  searchProps = {},
  fullHeight = false,
  noHeader = false,
  noFooter = false,
  userLocation = null,
  initialProductCategories,
}: Props) {
  const isStickyVisible = useIsStickyHeaderVisible();
  const router = useRouter();
  const { handleCookieConsentUpdate, allowCookies } = useCookieConsent();
  const { setItem } = useSessionStorage();
  const { track } = useEvents();
  const amplitudeSearchTracker = useAmplitudeSearchTracker();
  const searchContext = useDefaultContextImplementation(undefined, async (searchState) => {
    amplitudeSearchTracker(searchState);
    const url = searchState.country_code === '' ? '/browse-hotels' : GenerateSearchUrl(searchState);
    router.push(url);
  });

  useEffect(() => {
    if (!searchContext.getDate()) {
      searchContext.setDate(getDateToUse());
    }
  }, [searchContext]);

  const path = router.pathname;

  const shouldHideElement = (pathName: string, pathsToHide: string[]) =>
    pathsToHide.some((p) => pathName.includes(p));

  const isMobile = useIsMobile();

  const showFooter = useMemo(
    () =>
      !noFooter &&
      !shouldHideElement(path, [
        ...pathsToHideFooter,
        ...(isMobile ? pathsToHideFooterMobile : []),
      ]),
    [noFooter, path, isMobile],
  );

  const showHeader = useMemo(
    () => !noHeader && !shouldHideElement(path, pathsToHideHeader),
    [path, noHeader],
  );

  const removeMarketingModuleURLParam = useCallback(() => {
    const updatedQuery = { ...router.query };
    delete updatedQuery['marketing-module'];

    router.push(
      {
        pathname: router.pathname,
        query: updatedQuery,
      },
      undefined,
      { shallow: true, scroll: false },
    );
  }, [router]);

  useEffect(() => {
    (async () => {
      try {
        // TODO: Dispatch a state change to set currencyData globally.
        // This will avoid multiple api calls like on Header.tsx
        const currencyList = await getCurrencyData();
        handleCookieConsentUpdate(currencyList.user_from_usa);
        setItem(sessionStorageKeys.USER_FROM_USA, currencyList.user_from_usa.toString());
      } catch (error) {
        // No error popup. Fail silently.
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialize 3rd party scripts if cookies allowed
  useEffect(() => {
    if (allowCookies) {
      TagManager.initialize();
    }
  }, [allowCookies]);

  // Listen for any PostMessage events from Digioh
  useEffect(() => {
    const handlePostMessage = (event: MessageEvent) => {
      if (event.data?.type === 'digiohEmailCaptured') {
        track(EMAIL_CAPTURED, {
          source: router.query['marketing-module'],
          email: event.data.value,
        });
      }

      if (event.data?.type === 'digiohBoxClosed') {
        removeMarketingModuleURLParam();
      }

      if (event.data?.type === 'digiohBoxDisplayed') {
        track(CAPTURE_EMAIL_VIEWED, {
          source: 'PopUpOnLoad',
        });
      }
    };

    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, [removeMarketingModuleURLParam, router.query, track]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5, viewport-fit=cover"
        />
      </Head>

      {path === '/' ? (
        <div
          className={
            isStickyVisible ? 'invisible pointer-events-none' : 'visible pointer-events-auto'
          }
        >
          <SkinnyBanner />
        </div>
      ) : null}

      <HeaderBanner />

      <SearchProvider value={searchContext}>
        <UserLocationProvider value={userLocation}>
          <HomePageHotelsProvider>
            <div className={`relative ${fullHeight ? 'min-h-100vh flex flex-col ' : ''}`}>
              {showHeader ? (
                <Header
                  initialProductCategories={initialProductCategories}
                  searchBarEnabled={searchBarEnabled}
                  {...(path !== '/' ? { searchProps, hideVideo: true } : {})}
                />
              ) : null}

              {children}

              {showFooter ? <Footer /> : null}
            </div>
          </HomePageHotelsProvider>
        </UserLocationProvider>
      </SearchProvider>
    </>
  );
}
