import { Location } from '@components/Hotels/types';
import { environment } from '@helpers/environment';
import { DayRoomIcon, HotelIcon, PoolIcon, RobeIcon } from '@resortpass/solar-icons';
import React from 'react';

const DEFAULT_OPTIONS_POPULAR_TEXT = 'Popular';
const DEFAULT_OPTIONS_CURRENT_LOCATION_VALUE = 'current_location';

const SEARCH_DEFAULT_LOCATION_NAMES = [
  'Phoenix, AZ',
  'Miami, FL',
  'Orlando, FL',
  'West Hollywood, CA',
  'Austin, TX',
  'Honolulu, HI',
  'Fort Lauderdale, FL',
  'Palm Desert, CA',
];

const SEARCH_DEFAULT_OPTIONS = [
  { value: DEFAULT_OPTIONS_POPULAR_TEXT, label: DEFAULT_OPTIONS_POPULAR_TEXT },
  { value: 'Phoenix, AZ', label: 'Phoenix, AZ' },
  { value: 'Miami, FL', label: 'Miami, FL' },
  { value: 'Orlando, FL', label: 'Orlando, FL' },
  { value: 'West Hollywood, CA', label: 'West Hollywood, CA' },
  { value: 'Austin, TX', label: 'Austin, TX' },
  { value: 'Honolulu, HI', label: 'Honolulu, HI' },
  { value: 'Fort Lauderdale, FL', label: 'Fort Lauderdale, FL' },
  { value: 'Palm Desert, CA', label: 'Palm Desert, CA' },
];

const SEARCH_DEFAULT_LOCATIONS: Location[] = [
  {
    id: 12,
    name: 'Phoenix, Arizona',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/Phoenix-12',
    parent_id: 7,
    parent_type: 'state',
    city_name: 'Phoenix',
    state_code: 'AZ',
    country_code: 'US',
    indexName: environment.isProduction ? 'production_locations' : 'staging_locations',
    objectID: 'Phoenix, Arizona',
    queryID: '2c72badf5589c93210d8a6a6d7a34d00',
  },
  {
    id: 14,
    name: 'Miami, Florida',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/Miami-14',
    parent_id: 6,
    parent_type: 'state',
    city_name: 'Miami',
    state_code: 'FL',
    country_code: 'US',
    indexName: environment.isProduction ? 'production_locations' : 'staging_locations',
    objectID: 'Miami, Florida',
    queryID: '58a700e14d4b3493128769b7b6b0ae59',
  },
  {
    id: 36,
    name: 'Orlando, Florida',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/Orlando-36',
    parent_id: 6,
    parent_type: 'state',
    city_name: 'Orlando',
    state_code: 'FL',
    country_code: 'US',
    indexName: environment.isProduction ? 'production_locations' : 'staging_locations',
    objectID: 'Orlando, Florida',
    queryID: 'b16811f0490b200123f8cfe5523dac57',
  },
  {
    id: 4,
    name: 'West Hollywood, California',
    type: 'alias',
    detailed_type: 'city_alias',
    url: '/hotel-day-passes/West-Hollywood-4',
    parent_id: 4,
    parent_type: 'state',
    country_code: 'US',
    city_name: 'West Hollywood',
    state_code: 'CA',
    indexName: environment.isProduction ? 'production_locations' : 'staging_locations',
    objectID: 'West Hollywood, California',
    queryID: 'dddc41add78a5134cd0d7a88f33f9168',
  },
  {
    id: 23,
    name: 'Austin, Texas',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/Austin-23',
    parent_id: 8,
    parent_type: 'state',
    city_name: 'Austin',
    state_code: 'TX',
    country_code: 'US',
    indexName: environment.isProduction ? 'production_locations' : 'staging_locations',
    objectID: 'Austin, Texas',
    queryID: '7cd8b40386ddd4fe0002b1fbed355957',
  },
  {
    id: 35,
    name: 'Honolulu, Hawaii',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/Honolulu-35',
    parent_id: 9,
    parent_type: 'state',
    city_name: 'Honolulu',
    state_code: 'HI',
    country_code: 'US',
    indexName: environment.isProduction ? 'production_locations' : 'staging_locations',
    objectID: 'Honolulu, Hawaii',
    queryID: '3d11d996548a287890c7a40e80455dcf',
  },
  {
    id: 15,
    name: 'Fort Lauderdale, Florida',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/Fort-Lauderdale-15',
    parent_id: 6,
    parent_type: 'state',
    city_name: 'Fort Lauderdale',
    state_code: 'FL',
    country_code: 'US',
    indexName: environment.isProduction ? 'production_locations' : 'staging_locations',
    objectID: 'Fort Lauderdale, Florida',
    queryID: '04cc345452a525114e2c39e26c24cac2',
  },
  {
    id: 10,
    name: 'Palm Desert, California',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/Palm-Desert-10',
    parent_id: 4,
    parent_type: 'state',
    city_name: 'Palm Desert',
    state_code: 'CA',
    country_code: 'US',
    indexName: environment.isProduction ? 'production_locations' : 'staging_locations',
    objectID: 'Palm Desert, California',
    queryID: 'dcf16eb17350a935dcd625cb6623c68e',
  },
];

const SEARCH_DEFAULT_FILTERS = [
  {
    name: 'all',
    icon: <HotelIcon size={24} />,
  },
  {
    name: 'pool',
    icon: <PoolIcon size={24} />,
  },
  {
    name: 'spa',
    icon: <RobeIcon size={24} />,
  },
  {
    name: 'day room',
    icon: <DayRoomIcon size={24} />,
  },
];

export {
  SEARCH_DEFAULT_FILTERS,
  SEARCH_DEFAULT_LOCATIONS,
  DEFAULT_OPTIONS_POPULAR_TEXT,
  DEFAULT_OPTIONS_CURRENT_LOCATION_VALUE,
  SEARCH_DEFAULT_LOCATION_NAMES,
  SEARCH_DEFAULT_OPTIONS,
};
