/* eslint-disable @typescript-eslint/indent */
import React, { createContext, ReactNode, useContext, useMemo } from 'react';

interface ExperimentContextType {
  searchExperiment: 'on' | null;
}

const ExperimentContext = createContext<ExperimentContextType>({
  searchExperiment: null,
});

export function ExperimentProvider({
  children,
  searchExperiment,
}: {
  children: ReactNode;
  searchExperiment: 'on' | null;
}) {
  const value = useMemo(() => ({ searchExperiment }), [searchExperiment]);

  return <ExperimentContext.Provider value={value}>{children}</ExperimentContext.Provider>;
}

export const useExperiment = () => useContext(ExperimentContext);
