import React, { ReactNode, useContext } from 'react';
import { TabsContext } from './TabsContext';

type TabPanelProps = {
  children: ReactNode;
  value: string;
};

export default function TabPanel({ children, value }: TabPanelProps) {
  const context = useContext(TabsContext);

  if (!context) {
    throw new Error('TabPanel must be used within a Tabs component');
  }

  const { activeTab } = context;

  return activeTab === value ? <div>{children}</div> : null;
}
