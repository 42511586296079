/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/indent */
import useIsMobile from '@hooks/useIsMobile';
import { addMonths, isPast, isToday } from 'date-fns';
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { store } from '@context/store';
import actionTypes from '@context/actionTypes';
import { SEARCH, SELECT_SEARCH_DATE } from '@constants/amplitudeEvents';
import { getDateWithDashes } from '@helpers/date';
import { useEvents } from '@events/EventsProvider';
import { useRouter } from 'next/router';
import useAmplitudePayloads from '@hooks/useAmplitudePayloads';
import RoutingPath from '@constants/routingPath';
import { useSearchContext } from '@context/SearchContext';
import ClearDateSection from './ClearDateSection';

type Props = {
  startDate: Date | undefined;
  setStartDate: Dispatch<SetStateAction<Date | undefined>> | ((date: Date | undefined) => void);
  showClearDate: boolean;
  minDate?: Date;
  classes?: string;
  placeholder?: string | undefined;
  scrollIntoView?: boolean;
  isShortDateFormat?: boolean;
  inline?: boolean;
  wrapperClassName?: string;
  variantId?:
    | 'home-page-date-picker-variant'
    | 'home-page-mobile-date-picker-variant'
    | 'home-page-date-picker'
    | 'home-page-search-experiment';
  usePreSelectionScroll?: boolean;
};

export default function ReactDatePicker({
  minDate = new Date(),
  startDate,
  setStartDate,
  classes = '',
  placeholder = 'Date',
  showClearDate,
  scrollIntoView = false,
  isShortDateFormat = false,
  inline = false,
  variantId = 'home-page-date-picker',
  wrapperClassName = '',
  usePreSelectionScroll = true,
}: Props) {
  const instanceId = useMemo(() => `date-picker-input-${Math.ceil(Math.random() * 100)}`, []);
  const router = useRouter();
  const datePickerRef = useRef<any>();
  const { generateSearchLocationPayload } = useAmplitudePayloads();
  const { track } = useEvents();
  const isMobileView = useIsMobile();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { isDatePickerOpen } = state;
  const { setIsFlexibleDateSelected } = useSearchContext();

  useEffect(() => {
    if (isDatePickerOpen && datePickerRef && datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  }, [isDatePickerOpen]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    if (usePreSelectionScroll) {
      datePickerRef.current.setPreSelection(new Date());

      timer = setTimeout(() => {
        if (datePickerRef && datePickerRef.current && startDate && isMobileView) {
          const selectedDayElement = document.querySelector('.react-datepicker__day--selected');
          // Scroll to the element if found
          if (selectedDayElement) {
            selectedDayElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            });
          }
        }
      }, 500);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isMobileView, startDate, usePreSelectionScroll]);

  const onClearClick = () => {
    setIsFlexibleDateSelected(true);
    setStartDate(undefined);
    if (datePickerRef && datePickerRef.current) {
      datePickerRef.current.setOpen(false);
    }
  };

  const onCalendarOpen = () => {
    dispatch({
      type: actionTypes.SHOW_PAST_DATE_SELECTED_ERROR,
      payload: false,
    });

    if (scrollIntoView && !isMobileView) {
      document
        .getElementById('scroll-helper')
        ?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const onDateChange = (date: Date) => {
    if (isPast(date) && !isToday(date)) {
      dispatch({
        type: actionTypes.SHOW_PAST_DATE_SELECTED_ERROR,
        payload: true,
      });
      return;
    }

    setStartDate(date);

    track(SELECT_SEARCH_DATE, {
      page_url: window.location.href,
      search_date: getDateWithDashes(date),
    });

    if (
      router.pathname.startsWith(RoutingPath.SRP) ||
      router.pathname.startsWith(RoutingPath.RESULTS)
    ) {
      const searchPropertyGroup = state.searchedLocation
        ? generateSearchLocationPayload(state.searchedLocation)
        : {};

      track(
        SEARCH,
        {
          search_date: getDateWithDashes(date),
          ...searchPropertyGroup,
        },
        { withURLandTitle: true },
      );
    }
  };

  return (
    <div id={variantId} className={wrapperClassName}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={instanceId} className="sr-only">
        Date picker
      </label>

      <DatePicker
        ref={datePickerRef}
        id={instanceId}
        className={classes}
        selected={startDate}
        onChange={onDateChange}
        minDate={minDate}
        maxDate={addMonths(new Date(), 6)}
        fixedHeight
        isClearable={false}
        placeholderText={placeholder}
        onFocus={(e) => e.target.blur()}
        onCalendarOpen={onCalendarOpen}
        onCalendarClose={() => dispatch({ type: actionTypes.OPEN_DATE_PICKER, payload: false })}
        dateFormat={isShortDateFormat ? 'MMM d' : 'MM/dd/yyyy'}
        inline={inline}
        {...(variantId === 'home-page-mobile-date-picker-variant' ? { monthsShown: 7 } : {})}
      >
        {showClearDate && <ClearDateSection onClearClick={onClearClick} />}
        {scrollIntoView && <div className="h-20 w-20 -mb-24" id="scroll-helper" />}
      </DatePicker>
    </div>
  );
}
