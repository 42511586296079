/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/indent */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Head from 'next/head';
import { motion, AnimatePresence } from 'framer-motion';
import { getCdnImageUrl } from '@utils/cloudflare';
import Heading from '@solar/common/Heading/Heading';
import SearchBar from '@components/SearchExperiment/SearchBar';
import { useSearchContext } from '@context/SearchContext2025';
import { heroTitles } from '@constants/headingText';
import NextImage from 'next/image';
import { SearchCategories } from '@customTypes/search';
import { ImageWithBlur } from '@solar/common/ImageLoaded/ImageLoaded';
import styles from './Hero.module.css';

// Animation variants with overlap
const imageVariants = {
  initial: { opacity: 0, zIndex: 0 },
  animate: {
    opacity: 1,
    zIndex: 1,
    transition: { duration: 0.3, ease: 'easeOut' },
  },
  exit: {
    opacity: 0,
    zIndex: 0,
    transition: { duration: 0.3, ease: 'easeIn' },
  },
};

const textVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: 0.2, ease: 'easeOut' },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2, ease: 'easeIn' },
  },
};

type Props = {
  initialProductCategories: SearchCategories;
  isStickyHeaderVisible: boolean;
};

const defaultImages = {
  desktop: 'https://static-assets.resortpass.com/assets/homepage-hero/desktop-hero-all-min.jpg',
  mobile: 'https://static-assets.resortpass.com/assets/homepage-hero/mobile-hero-all-min.jpg',
};

export default function Hero({ initialProductCategories, isStickyHeaderVisible }: Props) {
  const {
    searchParams: { product_category: productCategory },
  } = useSearchContext();

  const getHeroImage = useCallback(
    (type: 'desktop' | 'mobile', pc = '') => {
      const pcLowerCase = pc.toLowerCase();
      if (!pcLowerCase || pcLowerCase === 'all') {
        return defaultImages[type];
      }

      const heroImage = initialProductCategories.find(
        (category) => category.name.toLowerCase() === pcLowerCase,
      )?.[`${type}_hero_url`];

      return heroImage || defaultImages[type];
    },
    [initialProductCategories],
  );

  const desktopHeroImage = useMemo(
    () => getHeroImage('desktop', productCategory),
    [productCategory, getHeroImage],
  );
  const mobileHeroImage = useMemo(
    () => getHeroImage('mobile', productCategory),
    [productCategory, getHeroImage],
  );

  // -------------------------------------------------------------------
  // Preload Hero Images -----------------------------------------------
  // -------------------------------------------------------------------
  useEffect(() => {
    const preload = (url: string) => {
      const img = new Image();
      img.src = url;
    };

    // Preload default images
    preload(getCdnImageUrl(1920, defaultImages.desktop, '', 100));
    preload(getCdnImageUrl(20, defaultImages.desktop, '', 10));
    preload(getCdnImageUrl(800, defaultImages.mobile, '', 100));
    preload(getCdnImageUrl(20, defaultImages.mobile, '', 10));

    // preload category images
    initialProductCategories.forEach((category) => {
      [
        ...(category.desktop_hero_url
          ? [
              getCdnImageUrl(1920, category.desktop_hero_url, '', 100),
              getCdnImageUrl(20, category.desktop_hero_url, '', 10),
            ]
          : []),
        ...(category.mobile_hero_url
          ? [
              getCdnImageUrl(800, category.mobile_hero_url, '', 100),
              getCdnImageUrl(20, category.mobile_hero_url, '', 10),
            ]
          : []),
      ].forEach(preload);
    });
  }, [initialProductCategories]);

  const [isMounted, setIsMounted] = useState(false);

  // Mark as mounted for animation (client-side only)
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const title =
    !productCategory || productCategory === 'all'
      ? heroTitles.all.title
      : heroTitles[productCategory as keyof typeof heroTitles]?.title || heroTitles.all.title;

  const subTitle =
    !productCategory || productCategory === 'all'
      ? heroTitles.all.subTitle
      : heroTitles[productCategory as keyof typeof heroTitles]?.subTitle || heroTitles.all.subTitle;

  return (
    <>
      <Head>
        <link rel="preload" as="image" href={defaultImages.desktop} />
        <link rel="preload" as="image" href={defaultImages.mobile} />
      </Head>

      <div className="relative w-full">
        <div className="relative w-full h-full">
          <div className={`${styles.heroImage} relative bg-gray-200`}>
            {/* Base image and text for no-JS crawlers */}
            <noscript>
              <div className="desktop:hidden">
                <NextImage
                  src={getCdnImageUrl(800, mobileHeroImage, '', 100)}
                  alt="hero background"
                  className="w-full h-full"
                  style={{ objectFit: 'cover', objectPosition: 'center' }}
                  sizes="100vw"
                  objectPosition="center"
                  objectFit="cover"
                  layout="fill"
                  priority
                />
              </div>
              <div className="hidden desktop:block">
                <NextImage
                  src={getCdnImageUrl(1920, desktopHeroImage, '', 100)}
                  alt="hero background"
                  className="w-full h-full"
                  style={{ objectFit: 'cover', objectPosition: 'center' }}
                  sizes="100vw"
                  width={640}
                  height={360}
                  objectPosition="center"
                  objectFit="cover"
                  layout="fill"
                  priority
                />
              </div>

              <div className="absolute bottom-6 px-4 space-y-4 desktop:bottom-246px desktop:px-16">
                <h1 className="text-white drop-shadow-lg">{title}</h1>

                <h3 className="text-white drop-shadow-md mt-spacing-sm desktop:mt-spacing-md">
                  {subTitle}
                </h3>
              </div>
            </noscript>

            {/* Animated layer for JS-enabled browsers */}
            <AnimatePresence mode="sync">
              <motion.div
                key={desktopHeroImage}
                variants={imageVariants}
                initial="initial"
                animate={isMounted ? 'animate' : false} // No animation on SSR
                exit="exit"
                className="absolute inset-0"
              >
                <div className="desktop:hidden w-full h-full overflow-hidden">
                  <ImageWithBlur
                    src={getCdnImageUrl(800, mobileHeroImage, '', 100)}
                    blur={getCdnImageUrl(20, mobileHeroImage, '', 10)}
                    alt="hero background"
                    className="object-cover object-center h-full w-full"
                    width={800}
                    height={800}
                  />
                </div>
                <div className="hidden desktop:block w-full h-full overflow-hidden">
                  <ImageWithBlur
                    src={getCdnImageUrl(1920, desktopHeroImage, '', 100)}
                    blur={getCdnImageUrl(20, desktopHeroImage, '', 10)}
                    alt="hero background"
                    className="object-cover object-center max-h-full w-full"
                    width={1920}
                    height={1080}
                  />
                </div>
              </motion.div>
            </AnimatePresence>
          </div>

          {/* Text overlay for JS-enabled browsers */}
          <div className="absolute bottom-8 px-4 space-y-4 desktop:bottom-246px desktop:px-16 desktop:max-w-1312 desktop:w-full desktop:left-1/2 desktop:transform desktop:-translate-x-1/2 z-10">
            <AnimatePresence mode="wait">
              <motion.div
                key={title + subTitle}
                variants={textVariants}
                initial="initial"
                animate={isMounted ? 'animate' : false} // No animation on SSR
                exit="exit"
              >
                <Heading level={1} className="text-white drop-shadow-lg">
                  {title}
                </Heading>

                {/* mobile heading */}
                <Heading
                  level={4}
                  className="text-white drop-shadow-md mt-spacing-sm desktop:mt-spacing-md desktop:hidden"
                >
                  {subTitle}
                </Heading>

                {/* desktop heading */}
                <Heading
                  level={3}
                  className="hidden desktop:block text-white drop-shadow-md mt-spacing-sm desktop:mt-spacing-md"
                >
                  {subTitle}
                </Heading>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>

        <div className="desktop:absolute desktop:bottom-0 desktop:w-full desktop:px-16 desktop:pb-16 desktop:max-w-1312 desktop:left-1/2 desktop:transform desktop:-translate-x-1/2 z-10">
          <SearchBar isStickyHeaderVisible={isStickyHeaderVisible} />
        </div>
      </div>
    </>
  );
}
