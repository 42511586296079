import { useState, useEffect } from 'react';

function useIsMobile({ isSolarSystem } = { isSolarSystem: false }) {
  const [isMobile, setIsMobile] = useState(true);
  // per the solar design system, anything under 1312px (desktop)
  // is considered mobile.
  // TODO: Change from 1311 to 367 once we start designing for tablet as well
  const mobileMaxWidth = isSolarSystem ? 1311 : 850;

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= mobileMaxWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [mobileMaxWidth]);

  return isMobile;
}

export default useIsMobile;
