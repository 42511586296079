/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import CheckoutMobileDetailsDrawer from '@components/Checkout/CheckoutMobileDetailsDrawer';
import { AnimatePresence, motion } from 'framer-motion';
import { CloseIcon } from '@resortpass/solar-icons';
import { useDrawer } from '@context/DrawerContext';
import LocationsSearchDrawer from '@components/SearchExperiment/SearchControls/LocationsSearchDrawer';
import DatePickerDrawer from '@components/SearchExperiment/SearchControls/DatePickerDrawer';
import Paragraph from '@solar/common/Paragraph/Paragraph';
import FiltersDrawer from '@components/Filters/FiltersDrawer';
import useScrollLock from '@hooks/useScrollLock';

// Mapping drawer types to components.
const modals: Record<string, React.FC<any>> = {
  CheckoutMobileDetailsDrawer,
  DatePickerDrawer,
  LocationsSearchDrawer,
  FiltersDrawer,
};

function DrawerManager() {
  const { drawerState, closeDrawer } = useDrawer();

  // Lock body scroll when drawer is open
  useScrollLock(!!drawerState);

  // Define Framer Motion variants for overlay and drawer.
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.2, ease: 'easeInOut' } },
    exit: { opacity: 0, transition: { duration: 0.2, ease: 'easeInOut' } },
  };

  const drawerVariants = {
    hidden: { y: '100%', opacity: 0 },
    visible: { y: '0%', opacity: 1, transition: { duration: 0.2, ease: 'easeInOut' } },
    exit: { y: '100%', opacity: 1, transition: { duration: 0.2, ease: 'easeInOut' } },
  };

  useEffect(() => {
    if (!drawerState) return undefined;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeDrawer();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [drawerState, closeDrawer]);

  if (!drawerState) return null;

  const { type, props, component } = drawerState;
  const SpecificModal = modals[type];
  if (!SpecificModal && !component) return null;

  // Destructure header-related props from drawer props.
  const { showHeader, title, subTitle, height, ...modalProps } = props;

  return (
    <AnimatePresence>
      <>
        {/* Overlay */}
        <motion.div
          className="fixed left-0 top-0 h-full w-full z-500 bg-rp-black-faded overflow-auto pointer-events-none"
          onClick={closeDrawer}
          onKeyDown={closeDrawer}
          role="button"
          tabIndex={0}
          variants={overlayVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        />

        {/* Drawer Container */}
        <motion.div
          className="fixed bg-white bottom-0 w-full rounded-solar-sm h-dvh"
          style={{
            paddingBottom: 'env(safe-area-inset-bottom)',
            maxHeight: height || '92dvh',
            zIndex: 10000,
          }}
          variants={drawerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {showHeader && (
            <div className="flex items-center p-spacing-md relative w-full">
              <button
                type="button"
                className="absolute flex h-6 items-center justify-center w-6"
                onClick={closeDrawer}
              >
                <CloseIcon height={24} width={24} />
              </button>
              {title ? (
                <div className="flex flex-col items-center justify-center w-full">
                  <Paragraph size="md" className="text-center max-w-280">
                    {title}
                  </Paragraph>
                  {subTitle && <p className="mt-1 leading-17px opacity-70 text-sm">{subTitle}</p>}
                </div>
              ) : (
                <div className="h-10 w-full mt-5" />
              )}
            </div>
          )}

          {/* Content */}
          {component}
          {SpecificModal ? <SpecificModal {...modalProps} closeModal={closeDrawer} /> : null}
        </motion.div>
      </>
    </AnimatePresence>
  );
}

export default DrawerManager;
