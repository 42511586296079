import sessionStorageKeys from '@constants/sessionStorageKeys';
import { ContextState, UserAction } from '@customTypes/context';
import useSessionStorage from '@hooks/useSessionStorage';
import React, { createContext, ReactNode, useReducer, useEffect } from 'react';
import { AMPLITUDE_EXPERIMENT_CONTROL_NAME } from '@constants/amplitudeExperiments';
import { addDays, isAfter, setHours, setMinutes } from 'date-fns';
import actionTypes from '@context/actionTypes';
import reducer from './reducer';

const initialState: ContextState = {
  numberOfCartItems: 0,
  popupValues: {
    isOpen: false,
    message: 'Something went wrong. Please try again.',
    isWarning: false,
    redirect: undefined,
  },
  onlyShowAvailableHotels: false,
  searchDate: isAfter(new Date(), setMinutes(setHours(new Date(), 14), 0))
    ? addDays(new Date(), 1)
    : new Date(),
  selectedCurrency: {
    name: 'United States Dollar',
    symbol: '$',
    iso_code: 'USD',
  },
  defaultCurrency: {
    name: 'United States Dollar',
    symbol: '$',
    iso_code: 'USD',
  },
  srpData: null,
  srpScrollPosition: null,
  windowUrl: null,
  isDatePickerOpen: false,
  seoCityPage: '',
  isFiltersExpanded: false,
  totalFilterCount: 0,
  showPastDateSelectedError: false,
  userCityAndState: '',
  smartCalendarProductFilter: null,
  selectedTimeSlot: undefined,
  guestsGroupBag: [],
  referer: null,
  searchResults: null,
  timeSlotGuestBag: {
    productId: 0,
    adultsCount: 0,
    childrenCount: 0,
  },
  showSameDayBookingWarning: false,
  searchFilters: null,
  availableSearchFilterOptions: null,
  searchedLocation: null,
  showCookiesConsentBanner: false,
  acceptCookies: false,
  userLocation: null,
  educationalModalOpen: false,
  pricingMarkdownExperiment: AMPLITUDE_EXPERIMENT_CONTROL_NAME,
  oneClickCheckoutExperiment: AMPLITUDE_EXPERIMENT_CONTROL_NAME,
  educationalModuleExperiment: AMPLITUDE_EXPERIMENT_CONTROL_NAME,
  bfCMPromoFlag: null,
  fixedDateSelectorExperiment: null,
};
const initialDispatch: React.Dispatch<UserAction> = () => null;
const store = createContext({ state: initialState, dispatch: initialDispatch });
const { Provider } = store;

type Props = {
  children: ReactNode;
};

function StateProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getItem } = useSessionStorage();
  useEffect(() => {
    dispatch({
      type: actionTypes.UPDATE_NUMBER_OF_CART_ITEMS,
      payload: parseInt(getItem(sessionStorageKeys.CART_NUMBER_OF_ITEMS) ?? 0, 10),
    });
  }, [getItem]);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

export { store, StateProvider };
