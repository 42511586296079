import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import useAmplitudePayloads from '@hooks/useAmplitudePayloads';
import useSessionStorage from '@hooks/useSessionStorage';
import { useEvents } from '@events/EventsProvider';
import useSmartCalendar from '@hooks/useSmartCalendar';
import { format } from 'date-fns';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { Location } from '@components/Hotels/types';
import {
  IM_FLEXIBLE_BUTTON_CLICKED,
  SEARCH,
  SELECT_SEARCH_LOCATION,
  START_SELECT_LOCATION,
} from '@constants/amplitudeEvents';
import { useRouter } from 'next/router';
import { getLocations } from '@utils/services';
import { debounce } from 'lodash';
import RoutingPath from '@constants/routingPath';
import { getDateWithDashes, getDateWithSlashes } from '@helpers/date';
import { searchButtonClick, searchNewLocationSelected } from '@events/HomePage';
import { store } from './store';
import actionTypes from './actionTypes';

export type SearchContextType = {
  formattedDate: string;
  handleClearInput: () => void;
  handleImFlexibleClick: () => void;
  handleOnResultSelect: (location: Location) => void;
  handleSearchClick: () => void;
  handleTypeAheadSearch: (searchTerm: string) => void;
  isFlexibleDateSelected: boolean;
  searchDate: Date | undefined;
  searchResults: Location[];
  searchValue: string;
  setIsFlexibleDateSelected: (isFlexibleDateSelected: boolean) => void;
  setSearchResults: Dispatch<SetStateAction<Location[]>>;
  setSearchValue: Dispatch<SetStateAction<string>>;
  updateStartDate: (dateValue: Date | undefined) => void;
};

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const useSearchContext = () => {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }

  return context;
};

type SearchProviderProps = {
  children: React.ReactNode;
};

export function SearchProvider({ children }: SearchProviderProps) {
  const [isFlexibleDateSelected, setIsFlexibleDateSelected] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Location[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<Location | undefined>(undefined);
  const [startSelectSearchEventFired, setStartSelectSearchEventFired] = useState<boolean>(false);
  const [searchInputCleared, setSearchInputCleared] = useState<boolean>(false);

  const router = useRouter();
  const { dispatch, state } = useContext(store);
  const { searchDate, userLocation } = state;
  const { setItem, removeItem } = useSessionStorage();
  const { generateSearchLocationPayload } = useAmplitudePayloads();
  const { track } = useEvents();
  const { setDate, removeDate } = useSmartCalendar();

  const formattedDate = useMemo(() => {
    if (isFlexibleDateSelected || !searchDate) {
      return 'Any Date';
    }

    return `${format(searchDate, 'LLL d')}`;
  }, [isFlexibleDateSelected, searchDate]);

  const handleImFlexibleClick = useCallback(() => {
    track(IM_FLEXIBLE_BUTTON_CLICKED, {});

    setIsFlexibleDateSelected(true);
    removeDate();
  }, [removeDate, track]);

  const updateStartDate = useCallback(
    (dateValue: Date | undefined) => {
      if (isFlexibleDateSelected) {
        setIsFlexibleDateSelected(false);
      }

      if (dateValue) {
        setDate(dateValue);
      } else {
        removeDate();
      }
    },
    [isFlexibleDateSelected, setDate, removeDate],
  );

  const trackSearchEvent = useCallback(
    (userSelectedLocation: Location) => {
      // GA - Event
      searchButtonClick();

      const searchPropertyGroup = generateSearchLocationPayload(userSelectedLocation);

      track(
        SEARCH,
        {
          search_date: searchDate ? getDateWithDashes(searchDate) : null,
          ...searchPropertyGroup,
        },
        { withURLandTitle: true },
      );
    },
    [generateSearchLocationPayload, searchDate, track],
  );

  const performSearch = useCallback(
    (location: Location) => {
      dispatch({
        type: actionTypes.UPDATE_SEARCHED_LOCATION,
        payload: location,
      });

      if (searchDate) {
        setItem(sessionStorageKeys.SMART_CALENDAR_DATE, getDateWithSlashes(searchDate));
      }

      setItem(sessionStorageKeys.SEARCHED_LOCATION, JSON.stringify(location));
      trackSearchEvent(location);
      const locationEncoded = encodeURIComponent(location.name);
      const url =
        location.type === 'hotel'
          ? `${RoutingPath.SRP}?hotel_name=${locationEncoded}&city_id=${location.parent_id}&highlighted=true`
          : location.url;

      router.push(url);
    },
    [dispatch, router, setItem, searchDate, trackSearchEvent],
  );

  const searchFunction = useCallback(async (searchTerm: string) => {
    try {
      if (searchTerm) {
        const results = await getLocations(`"${searchTerm}"`);
        setSearchResults(results);
        return results;
      }
      setSearchResults([]);
      return [];
    } catch (error) {
      return [];
      // Handle any error
    } finally {
      // turn off loading
    }
  }, []);

  const handleSearchClick = useCallback(async () => {
    if (searchValue === '') {
      router.push('/browse-hotels');
      return;
    }

    if (searchValue && !searchResults.length && !selectedLocation) {
      // Get the searchResults again, there might be a case where the user got back from the SRP.
      const results = await searchFunction(searchValue);

      if (!results.length) {
        // Redirect to No Results Page if no results are found.
        setItem(sessionStorageKeys.NO_RESULTS_SEARCH_TERM, searchValue);
        removeItem(sessionStorageKeys.SEARCHED_LOCATION);
        router.push('/browse-hotels?no_results=true');
        return;
      }
    }

    if (!searchValue && !selectedLocation) {
      // Redirect to browse-hotels if no location is selected.
      router.push('/browse-hotels');
      return;
    }

    // Search for the selected location or the first result in the list, if no selected location.
    performSearch(selectedLocation || searchResults[0]);
  }, [
    performSearch,
    removeItem,
    router,
    searchFunction,
    searchResults,
    searchValue,
    selectedLocation,
    setItem,
  ]);

  const handleOnResultSelect = useCallback(
    (location: Location) => {
      setSelectedLocation(location);
      setSearchValue(location.name);

      // Events:
      searchNewLocationSelected(location.name);
      track(SELECT_SEARCH_LOCATION, generateSearchLocationPayload(location));
      // algolia - look for: "trackAlgoliaInsightEvent"
    },
    [generateSearchLocationPayload, track],
  );

  const handleClearInput = useCallback(() => {
    // Reset everything
    removeItem(sessionStorageKeys.SEARCHED_LOCATION);
    setSelectedLocation(undefined);
    setSearchValue('');
    setSearchResults([]);
  }, [removeItem]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(searchFunction, 500), [searchFunction]);

  const handleTypeAheadSearch = useCallback(
    (searchTerm: string) => {
      // Event
      // Check if the field was cleared and user starts typing the first word
      if (!startSelectSearchEventFired) {
        if (!searchInputCleared && searchTerm.trim() === '') {
          setSearchInputCleared(true);
        } else if (searchInputCleared && searchTerm.trim() !== '') {
          // User starts typing the first word, trigger the event.
          track(START_SELECT_LOCATION, {});
          setStartSelectSearchEventFired(true);
          setSearchInputCleared(false);
        }
      }

      setSearchValue(searchTerm);
      debouncedSearch(searchTerm);
    },
    [debouncedSearch, searchInputCleared, startSelectSearchEventFired, setSearchValue, track],
  );

  useEffect(() => {
    if (userLocation) {
      setSearchValue(userLocation.name);
    }
  }, [userLocation, setSearchValue]);

  const contextValue = useMemo(
    () => ({
      formattedDate,
      handleClearInput,
      handleImFlexibleClick,
      handleOnResultSelect,
      handleSearchClick,
      handleTypeAheadSearch,
      isFlexibleDateSelected,
      searchDate,
      searchResults,
      searchValue,
      setIsFlexibleDateSelected,
      setSearchResults,
      setSearchValue,
      updateStartDate,
    }),
    [
      formattedDate,
      handleClearInput,
      handleImFlexibleClick,
      handleOnResultSelect,
      handleSearchClick,
      handleTypeAheadSearch,
      isFlexibleDateSelected,
      searchDate,
      searchResults,
      searchValue,
      setIsFlexibleDateSelected,
      setSearchResults,
      setSearchValue,
      updateStartDate,
    ],
  );

  return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>;
}
