import React from 'react';
import Paragraph from '@solar/common/Paragraph/Paragraph';
import useCurrencyConverter from '@hooks/useCurrencyConverter';
import { AddonItems } from '@customTypes/cart-items';

export default function CheckoutItemAddon({ item }: { item: AddonItems }) {
  const { convertCurrency } = useCurrencyConverter();

  return (
    <div className="border-b border-solar-secondary flex flex-row items-center justify-between pt-spacing-sm pb-spacing-md">
      <div className="flex flex-row space-x-spacing-sm">
        <span className="bg-solar-surface-secondary px-spacing-sm py-0.5 rounded-solar-sm self-start">
          <Paragraph size="sm">{item.units}</Paragraph>
        </span>

        <div className="flex flex-col">
          <Paragraph size="md">{item.name}</Paragraph>

          <Paragraph size="sm" className="text-solar-secondary">
            {convertCurrency(item.price)} ea.
          </Paragraph>
        </div>
      </div>

      <div className="flex flex-col">
        <Paragraph size="md" className="text-solar-primary">
          {convertCurrency(item.total)}
        </Paragraph>
      </div>
    </div>
  );
}
