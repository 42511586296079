/* eslint-disable @typescript-eslint/naming-convention */
import { store } from '@context/store';
import { convertCurrency, showIsoCode } from '@helpers/currencyConverter';
import useSessionStorage from '@hooks/useSessionStorage';
import React, { useContext } from 'react';

type Props = {
  value: number | string;
  precision?: number;
  spaceBetween?: boolean;
  valueClasses?: string;
  isoCodeClasses?: string;
  showAsterisk?: boolean;
};
export default function Currency({
  value,
  valueClasses = 'text-21 font-rp-pn-regular',
  isoCodeClasses = 'text-15 font-rp-pn-regular',
  precision = 0,
  spaceBetween = true,
  showAsterisk = false,
}: Props) {
  const globalState = useContext(store);
  const { state } = globalState;
  const { selectedCurrency } = state;
  const { iso_code } = selectedCurrency;
  const { getItem } = useSessionStorage();
  const isShowIsoCode = showIsoCode(state, getItem);
  const finalValue = convertCurrency(value, state, precision);

  return (
    <span className={valueClasses}>
      {finalValue}
      {isShowIsoCode && (
        <span className={isoCodeClasses}>{spaceBetween ? ` ${iso_code}` : iso_code}</span>
      )}
      {showAsterisk && ' *'}
    </span>
  );
}
