/* eslint-disable @typescript-eslint/indent */

import { createContext, useState, useContext, useRef, useEffect, useMemo } from 'react';
import { SearchState } from '@customTypes/search';
import { parse } from 'date-fns';

// AcceptedParameters is a utility type that takes a type K
// and returns an object with the same keys as K,
// but with boolean values. This is useful for updating the search parameters.
type AcceptedParameters<T> = { [k in keyof T]?: boolean };

const defaultSearchParams: SearchState = {
  q: '',
  date: '',
  product_category: '',
  city: '',
  search_term: '',
  state_code: '',
  country_code: '',
  hotel_name: '',
  filters: {
    amenities: {
      'all-inclusive': false,
      beach: false,
      'fitness-center': false,
      spa: false,
      'rooftop-pool': false,
      hottub: false,
      lazyriver: false,
      waterslide: false,
    },
    hotel_stars: {
      '=5': false,
      '>=4': false,
      '>=3': false,
    },
    guest_ratings: {
      '>=3.8': false,
    },
    vibes: {
      'family-friendly': false,
      party: false,
      serene: false,
      luxe: false,
      trendy: false,
    },
    only_available: false,
    only_recent: false,
    product_types: [],
    city_alias_id: 0,
  },
};

export function GetDefaultSearchState(): SearchState {
  return JSON.parse(JSON.stringify(defaultSearchParams)) as SearchState;
}

export const HotelStarsFriendlyNames: {
  [key in keyof SearchState['filters']['hotel_stars']]: string;
} = {
  '=5': '5 Star Hotels',
  '>=4': '4 Star+ Hotels',
  '>=3': '3 Star+ Hotels',
};

export const GuestRatingFriendlyNames: {
  [key in keyof SearchState['filters']['guest_ratings']]: string;
} = {
  '>=3.8': 'Top Rated',
};

export const VibesFriendlyNames: { [key in keyof SearchState['filters']['vibes']]: string } = {
  'family-friendly': 'Family Friendly',
  party: 'Party',
  serene: 'Serene',
  luxe: 'Luxe',
  trendy: 'Trendy',
};

export const AmenitiesFriendlyNames: {
  [key in keyof SearchState['filters']['amenities']]: string;
} = {
  'all-inclusive': 'All-Inclusive',
  beach: 'Beach Access',
  'fitness-center': 'Gym',
  spa: 'Spa',
  'rooftop-pool': 'Rooftop Pool',
  hottub: 'Hot Tub',
  lazyriver: 'Lazy River',
  waterslide: 'Waterslide',
};

export type SearchContextType = {
  searchParams: SearchState;
  getDate: () => Date | 'any' | '';
  setAmenities: (
    amenities: AcceptedParameters<SearchState['filters']['amenities']>,
  ) => SearchContextType;
  setHotelStars: (
    hotelStars: AcceptedParameters<SearchState['filters']['hotel_stars']>,
  ) => SearchContextType;
  setGuestRatings: (
    guestRatings: AcceptedParameters<SearchState['filters']['guest_ratings']>,
  ) => SearchContextType;
  setVibes: (vibes: AcceptedParameters<SearchState['filters']['vibes']>) => SearchContextType;
  setOnlyAvailable: (onlyAvailable: boolean) => SearchContextType;
  setCity: (city: string) => SearchContextType;
  setHotelName: (hotelName: string) => SearchContextType;
  setSearchTerm: (term: string) => SearchContextType;
  setStateCode: (stateCode: string) => SearchContextType;
  setCountryCode: (countryCode: string) => SearchContextType;
  setProductCategory: (productCategory: string) => SearchContextType;
  setDate: (date: Date | 'any' | '') => SearchContextType;
  setOnlyRecent: (onlyRecent: boolean) => SearchContextType;
  addProductType: (productType: number) => SearchContextType;
  removeProductType: (productType: number) => SearchContextType;
  updateSearchState: (state: SearchState) => SearchContextType;
  search: (debounce?: number) => void;
  clearFilters: () => SearchContextType;
  setAliasId: (aliasId: number) => SearchContextType;
  status: 'idle' | 'searchStart' | 'searchEnd';
};

export function useDefaultContextImplementation(
  s?: SearchState,
  customSearch?: (s: SearchState) => Promise<void>,
): SearchContextType {
  const [status, setStatus] = useState<'idle' | 'searchStart' | 'searchEnd'>('idle');
  const [searchParams, setSearchParams] = useState<SearchState>(
    JSON.parse(JSON.stringify(s ?? defaultSearchParams)) as SearchState,
  );
  const searchParamsRef = useRef(searchParams);
  useEffect(() => {
    searchParamsRef.current = searchParams;
  }, [searchParams]);

  const debounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const customSearcherRef = useRef(customSearch);
  useEffect(() => {
    customSearcherRef.current = customSearch;
  }, [customSearch]);

  useEffect(() => {
    if (status === 'searchEnd') {
      setStatus('idle');
    }
  }, [status]);

  return useMemo(
    () => ({
      status,
      searchParams,
      getDate() {
        if (searchParams.date === '') return '';
        if (searchParams.date === 'any') return 'any';
        return parse(searchParams.date, 'yyyy-MM-dd', new Date());
      },
      setAmenities(amenities: AcceptedParameters<SearchState['filters']['amenities']>) {
        setSearchParams((p) => {
          const copy = { ...p };
          copy.filters.amenities = { ...p.filters.amenities, ...amenities };
          return copy;
        });
        return this;
      },
      setHotelStars(hotelStars: AcceptedParameters<SearchState['filters']['hotel_stars']>) {
        setSearchParams((p) => {
          const copy = { ...p };
          copy.filters.hotel_stars = {
            ...p.filters.hotel_stars,
            ...hotelStars,
          };
          return copy;
        });

        return this;
      },
      setGuestRatings(guestRatings: AcceptedParameters<SearchState['filters']['guest_ratings']>) {
        setSearchParams((p) => {
          const copy = { ...p };
          copy.filters.guest_ratings = {
            ...p.filters.guest_ratings,
            ...guestRatings,
          };
          return copy;
        });
        return this;
      },
      setVibes(vibes: AcceptedParameters<SearchState['filters']['vibes']>) {
        setSearchParams((p) => {
          const copy = { ...p };
          copy.filters.vibes = {
            ...p.filters.vibes,
            ...vibes,
          };
          return copy;
        });
        return this;
      },
      setOnlyAvailable(onlyAvailable: boolean) {
        setSearchParams((p) => {
          const copy = { ...p };
          copy.filters.only_available = onlyAvailable;
          return copy;
        });
        return this;
      },
      setOnlyRecent(onlyRecent: boolean) {
        setSearchParams((p) => {
          const copy = { ...p };
          copy.filters.only_recent = onlyRecent;
          return copy;
        });
        return this;
      },
      setHotelName(hotelName: string) {
        setSearchParams((p) => {
          const copy = { ...p };
          copy.hotel_name = hotelName;
          return copy;
        });
        return this;
      },
      setCity(city: string) {
        setSearchParams((p) => {
          const copy = { ...p };
          copy.city = city;
          return copy;
        });
        return this;
      },
      setSearchTerm(term: string) {
        setSearchParams((p) => {
          const copy = { ...p };
          copy.search_term = term;
          return copy;
        });
        return this;
      },
      setStateCode(stateCode: string) {
        setSearchParams((p) => {
          const copy = { ...p };
          copy.state_code = stateCode;
          return copy;
        });
        return this;
      },
      setCountryCode(countryCode: string) {
        setSearchParams((p) => {
          const copy = { ...p };
          copy.country_code = countryCode;
          return copy;
        });
        return this;
      },
      setProductCategory(productCategory: string) {
        setSearchParams((p) => {
          const copy = { ...p };
          const lc = productCategory.toLowerCase();
          if (lc === 'all') {
            copy.product_category = '';
          } else {
            copy.product_category = productCategory;
          }
          return copy;
        });
        return this;
      },
      setDate(date: Date | 'any' | '') {
        const pad = (n: number) => (n < 10 ? `0${n}` : n);

        setSearchParams((p) => {
          const copy = { ...p };
          if (date === 'any') {
            copy.date = 'any';
            return copy;
          }

          if (date === '') {
            copy.date = '';
            return copy;
          }

          copy.date = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
          return copy;
        });

        return this;
      },
      addProductType(productType: number) {
        setSearchParams((p) => {
          const copy = { ...p };
          if (!copy.filters.product_types.includes(productType)) {
            copy.filters.product_types.push(productType);
          }
          return copy;
        });
        return this;
      },
      removeProductType(productType: number) {
        setSearchParams((p) => {
          const copy = { ...p };
          copy.filters.product_types = copy.filters.product_types.filter(
            (pt) => pt !== productType,
          );
          return copy;
        });
        return this;
      },
      updateSearchState(state: SearchState) {
        setSearchParams(state);
        return this;
      },
      clearFilters() {
        setSearchParams((p) => {
          const copy = { ...p };
          const resetFilters = GetDefaultSearchState().filters;
          copy.filters = resetFilters;
          return copy;
        });
        return this;
      },
      async search(debounce = 0) {
        if (debounceTimer.current) {
          clearTimeout(debounceTimer.current);
        }
        debounceTimer.current = setTimeout(async () => {
          setStatus('searchStart');
          if (customSearcherRef.current && searchParamsRef.current) {
            await customSearcherRef.current(searchParamsRef.current);
          }
          setStatus('searchEnd');
          debounceTimer.current = null;
        }, debounce);
      },
      setAliasId(aliasId: number = 0) {
        setSearchParams((p) => {
          const copy = { ...p };
          copy.filters.city_alias_id = aliasId;
          return copy;
        });
        return this;
      },
    }),
    [searchParams, status],
  );
}

const searchContext = createContext<SearchContextType | undefined>(undefined);

/**
 * Hook to access the search context
 *
 * @returns The search context
 * @throws Error if the hook is used outside of a SearchContextProvider
 */
export function useSearchContext() {
  const context = useContext(searchContext);
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchContextProvider');
  }
  return context;
}

/**
 * Provider for the search context
 */
export default searchContext.Provider;
