/* eslint-disable jsx-a11y/role-has-required-aria-props */
import React from 'react';
import Paragraph from '../Paragraph/Paragraph';

type Props = {
  text: React.ReactNode;
  icon?: React.ReactNode;
  /** Determines if the item is interactive (clickable). */
  clickable?: boolean;
  /** Optional click handler for interactive items. */
  onClick?: () => void;
  /** Optional URL; if provided, the item will render as a link. */
  href?: string;
  className?: string;
};

export default function List({
  className = '',
  icon = undefined,
  text,
  clickable = false,
  onClick,
  href,
}: Props) {
  // Define common content for the list item.
  const content = (
    <>
      {icon || null}
      <Paragraph size="sm">{text}</Paragraph>
    </>
  );

  // If the item is clickable and has a URL, render as an anchor.
  if (clickable && href) {
    return (
      <li role="option" className={className}>
        <a
          href={href}
          onClick={onClick}
          className="flex py-spacing-md px-spacing-sm space-x-spacing-sm hover:bg-solar-surface-secondary"
        >
          {content}
        </a>
      </li>
    );
  }

  // If clickable without a URL, render as a button.
  if (clickable) {
    return (
      <li role="option" className={className}>
        <button
          type="button"
          onClick={onClick}
          className="flex items-center py-spacing-md px-spacing-sm space-x-spacing-sm w-full hover:bg-solar-surface-secondary"
        >
          {content}
        </button>
      </li>
    );
  }

  // Otherwise, render as a non-clickable list item.
  return (
    <li role="option" className="flex py-spacing-md px-spacing-sm space-x-spacing-sm">
      {content}
    </li>
  );
}
