import { createContext, Dispatch, SetStateAction } from 'react';

export type TabsContextType = {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  size: 'sm' | 'md';
  onSelect?: (value: string) => void;
};

export const TabsContext = createContext<TabsContextType | undefined>(undefined);
