/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-wrap-multilines */
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import Heading from '@solar/common/Heading/Heading';
import Paragraph from '@solar/common/Paragraph/Paragraph';
import Chip from '@solar/common/Chip/Chip';
import CloseIcon from '@solar/assets/images/action/Close';
import TagIcon from '@solar/assets/images/action/Tag';
import SecureIcon from '@solar/assets/images/random/Secure';
import { CheckoutInfo } from '@customTypes/checkout-info';
import { format, isAfter, parseISO } from 'date-fns';
import { deleteCoupon } from '@utils/services';
import useCurrentUser from '@hooks/useCurrentUser';
import useCurrencyConverter from '@hooks/useCurrencyConverter';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import ImageLoaded from '@components/common/ImageLoaded/ImageLoaded';
import { getCdnImageUrl } from '@utils/cloudflare';
import CheckoutItem from './CheckoutItem';
import CheckoutItemAddon from './CheckoutItemAddon';

type Props = {
  checkoutDetails: CheckoutInfo;
  setCheckoutDetails: Dispatch<SetStateAction<CheckoutInfo | null | undefined>> | undefined;
};

export default function CheckoutDetails({ checkoutDetails, setCheckoutDetails }: Props) {
  const { getItem } = useSessionStorage();
  const currentUser = useCurrentUser();
  const { convertCurrency } = useCurrencyConverter();

  const storageCurrency = getItem(sessionStorageKeys.SELECTED_CURRENCY);

  const selectedCurrency = useMemo(() => {
    if (!storageCurrency) {
      return null;
    }

    return JSON.parse(storageCurrency);
  }, [storageCurrency]);

  const bookingDate = useMemo(
    () => parseISO(checkoutDetails.booked_date),
    [checkoutDetails.booked_date],
  );

  const addons = useMemo(
    () => checkoutDetails.extracted_items.flatMap((product) => product.addon_items || []),
    [checkoutDetails],
  );

  const removeCoupon = async () => {
    try {
      const jsonData = {
        coupon_code: checkoutDetails?.coupon_code,
      };

      const checkoutData = await deleteCoupon(
        checkoutDetails.id,
        jsonData,
        currentUser.valueOr(null),
      );

      if (setCheckoutDetails) setCheckoutDetails(checkoutData);
    } catch (error) {
      // TODO: handle error accordingly
    }
  };

  return (
    <div className="flex flex-col font-rp-basetica text-solar-primary space-y-spacing-sm">
      {/* Hotel Details */}
      <div className="flex flex-row items-center py-spacing-md">
        <div className="h-16 w-16 flex-shrink-0 mr-1 relative">
          <ImageLoaded
            url={[
              checkoutDetails.hotel_image,
              getCdnImageUrl(64, checkoutDetails.hotel_image, '', 100),
            ]}
            alt={`${checkoutDetails.hotel_name} image`}
            layout="fill"
            className="rounded-solar-sm"
            objectFit="cover"
            priority
          />
        </div>

        <div className="bg-solar-surface-brand-shadow flex flex-col flex-shrink-0 h-16 items-center justify-center mr-9px rounded-solar-sm w-16">
          <Heading level={5} className="text-solar-inverted-primary">
            {format(bookingDate, 'MMM')}
          </Heading>

          <Heading level={2} className="text-solar-inverted-primary">
            {format(bookingDate, 'dd')}
          </Heading>
        </div>

        <div className="flex flex-col space-y-1">
          <Paragraph size="sm">{checkoutDetails.hotel_name}</Paragraph>

          <Paragraph size="sm">{format(bookingDate, 'EEE. MMM dd yyyy')}</Paragraph>
        </div>
      </div>

      {/* Checkout Items */}
      <div className="flex flex-col space-y-spacing-sm">
        {checkoutDetails.extracted_items.map((item) => (
          <CheckoutItem checkoutDetails={checkoutDetails} item={item} />
        ))}

        {/* Addons */}
        {addons && addons.map((item) => <CheckoutItemAddon item={item} />)}
      </div>

      <div className="flex flex-col space-y-spacing-xs">
        {/* Subtotal */}
        <div className="flex flex-row items-center justify-between py-spacing-xs">
          <Paragraph size="sm">Subtotal</Paragraph>

          <Paragraph size="sm">{convertCurrency(checkoutDetails.subtotal)}</Paragraph>
        </div>

        {/* Service & Platform Fee */}
        <div className="flex flex-row items-center justify-between py-spacing-xs">
          <Paragraph size="sm">Service & Platform Fee</Paragraph>

          <div className="flex flex-row space-x-spacing-sm">
            {checkoutDetails.service_fee === 0 ? (
              <Paragraph size="sm" className="text-solar-alert-red">
                Free
              </Paragraph>
            ) : (
              <Paragraph
                size="sm"
                className={`${checkoutDetails.service_fee === 0 ? 'line-through' : ''}`.trim()}
              >
                {convertCurrency(checkoutDetails.service_fee)}
              </Paragraph>
            )}
          </div>
        </div>

        {/* Tax */}
        <div className="flex flex-row items-center justify-between py-spacing-xs">
          <Paragraph size="sm">Tax</Paragraph>

          <div className="flex flex-row space-x-spacing-sm">
            <Paragraph size="sm">{convertCurrency(checkoutDetails.tax)}</Paragraph>
          </div>
        </div>

        {/* RP - Credits */}
        {checkoutDetails.credits && checkoutDetails.credits !== 0 ? (
          <div className="flex flex-row items-center justify-between py-spacing-xs">
            <Paragraph size="sm">Credit</Paragraph>

            <div className="flex flex-row space-x-spacing-sm">
              <Paragraph size="sm" className="text-solar-alert-red">
                -{convertCurrency(checkoutDetails.credits)}
              </Paragraph>
            </div>
          </div>
        ) : null}

        {/* Discount: STP or HotSpot */}
        {!checkoutDetails.coupon_code && checkoutDetails.discount ? (
          <div className="flex flex-row items-center justify-between py-spacing-xs">
            <Paragraph size="sm">Savings</Paragraph>

            <div className="flex flex-row space-x-spacing-sm">
              <Paragraph size="sm" className="text-solar-alert-red">
                -{convertCurrency(checkoutDetails.discount)}
              </Paragraph>
            </div>
          </div>
        ) : null}

        {/* Coupon Code */}
        {checkoutDetails.coupon_code && checkoutDetails.discount ? (
          <div className="flex flex-row items-center justify-between py-spacing-xs">
            <div className="flex flex-row items-center space-x-spacing-sm">
              <Paragraph size="sm">Promo</Paragraph>

              <div>
                <Chip
                  text={checkoutDetails.coupon_code}
                  leftIcon={<TagIcon />}
                  rightIcon={
                    <button type="button" onClick={removeCoupon}>
                      <CloseIcon />
                    </button>
                  }
                />
              </div>
            </div>

            <div className="flex flex-row space-x-spacing-sm">
              <Paragraph size="sm" className="text-solar-alert-red">
                -{convertCurrency(checkoutDetails.discount)}
              </Paragraph>
            </div>
          </div>
        ) : null}

        {/* Total */}
        <div className="flex flex-row items-center justify-between py-spacing-md">
          <div className="flex flex-row space-x-spacing-sm">
            <Paragraph size="md">Total</Paragraph>
          </div>

          <div className="flex flex-row space-x-spacing-sm">
            <Paragraph size="lg">{convertCurrency(checkoutDetails.total)}</Paragraph>
          </div>
        </div>
      </div>

      {/* Cancellation Notice */}
      {checkoutDetails.cancellation_date &&
      isAfter(new Date(checkoutDetails.cancellation_date), new Date()) ? (
        <div className="flex flex-row items-center space-x-spacing-sm">
          <div className="flex-shrink-0">
            <SecureIcon />
          </div>

          <Paragraph size="sm">
            Cancel by{' '}
            {format(new Date(checkoutDetails.cancellation_date), 'EEE. MMM dd yyyy hh:mm a')}{' '}
            property local time for a full refund
          </Paragraph>
        </div>
      ) : null}

      {/* Different currency message */}
      {selectedCurrency && selectedCurrency.iso_code !== 'USD' ? (
        <div className="pt-spacing-sm">
          <Paragraph size="xs">
            {`Price displayed in ${selectedCurrency.iso_code}, however payment will be processed in USD and exchange rate may
          vary before processing. Card issuer may apply foreign transaction fee.`}
          </Paragraph>
        </div>
      ) : null}
    </div>
  );
}
