/* eslint-disable function-paren-newline */
/* eslint-disable @typescript-eslint/indent */
import { Location } from '@components/Hotels/types';
import {
  FILTER_CATEGORY_AMENITIES,
  FILTER_CATEGORY_GUEST_RATING,
  FILTER_CATEGORY_HOTEL_VIBES,
  FILTER_CATEGORY_STAR_CLASSIFICATION,
} from '@constants/filterValues';
import AVAILABILITY from '@constants/product';
import {
  BookingPropertyGroupType,
  CartItemPropertyGroupType,
  CouponPropertyGroupType,
  FilterPropertyGroupType,
  HotelPropertyGroupType,
  PopularHotelPropertyGroupType,
  ProductPropertyGroupType,
  SearchLocationPropertyGroupType,
} from '@customTypes/amplitude';
import BookingDetails from '@customTypes/booking-details';
import { CheckoutInfo, CheckoutItem } from '@customTypes/checkout-info';
import HotelApi, { HotelApiProduct } from '@customTypes/hotel-api';
import HotelDetails from '@customTypes/hotel-details';
import Product from '@customTypes/product';
import { PopularHotel } from '@customTypes/popular-hotel-data';
import {
  FilterOptionType,
  SrpFilterCategories,
  SrpFilterOptions,
  SrpFilters,
} from '@customTypes/srp-filters';
import { getDateWithDashes } from '@helpers/date';
import { getUnixTime } from 'date-fns';
import { isArray } from 'lodash';

type HotelProducts = Product | HotelApiProduct;

function generateBookingPayload(booking: BookingDetails): BookingPropertyGroupType | {} {
  if (!booking) {
    return {};
  }

  const payload: BookingPropertyGroupType = {
    booking_can_be_canceled: booking.can_be_canceled,
    booking_created_at_date: booking.booked_date,
    booking_credit: booking.credit,
    booking_credit_card: booking.credit_card,
    booking_customer_id: booking.customer_id,
    booking_discount: booking.discount,
    booking_id: booking.id,
    booking_new_or_existing_customer: booking.customer_status,
    booking_no_of_adults: booking.adults_number,
    booking_no_of_child: booking.children_number,
    booking_order_date: booking.order_date,
    booking_reservation_date: booking.booked_at,
    booking_service_fee: booking.service_fee,
    booking_status: booking.type,
    booking_subtotal: booking.subtotal,
    booking_tax: booking.tax,
    booking_total_price: booking.total,
  };

  return payload;
}

function generateCartItemPayload(checkoutItem: CheckoutItem): CartItemPropertyGroupType | {} {
  if (!checkoutItem) {
    return {};
  }

  const payload: CartItemPropertyGroupType = {
    cart_item_no_of_adults: checkoutItem.no_of_adults,
    cart_item_no_of_child: checkoutItem.no_of_childs,
    cart_item_no_of_infants: checkoutItem.no_of_infants,
    cart_item_product_addon_ids_selected: checkoutItem.addon_items.map((addon) => addon.addon_id),
    cart_item_product_addon_names_selected: checkoutItem.addon_items.map((addon) => addon.name),
    cart_item_product_addon_prices_selected: checkoutItem.addon_items.map((addon) =>
      addon.price.toString(),
    ),
    cart_item_product_id: checkoutItem.product_id,
    cart_item_timestamp: getUnixTime(new Date()),
    cart_item_adult_price: checkoutItem.adult_price,
    cart_item_child_price: checkoutItem.child_price,
    cart_item_total_adult_price: checkoutItem.adult_total,
    cart_item_total_child_price: checkoutItem.child_total,
    cart_item_total_price: checkoutItem.total,
  };

  return payload;
}

function generateCouponPayload(checkoutData: CheckoutInfo): CouponPropertyGroupType | {} {
  if (!checkoutData) {
    return {};
  }

  const payload: CouponPropertyGroupType = {
    coupon_id: checkoutData.coupon_id,
    coupon_name: checkoutData.coupon_name,
    coupon_product_type_name: checkoutData.coupon_product_type_name,
  };

  return payload;
}

function getActiveFiltersByCategory(
  filterCategory: SrpFilterCategories,
  filtersApplied: SrpFilters,
  filterOptions: SrpFilterOptions,
): string[] {
  const options = filtersApplied[filterCategory];

  const filterLabels = Object.entries(options)
    .filter(([, value]) => value)
    .map(([key]) => {
      const option = filterOptions[filterCategory].find((optionType: FilterOptionType) => {
        if (typeof optionType.name === 'string') {
          return optionType.name.toLowerCase() === key.toLowerCase();
        }

        return optionType.name === +key;
      });

      return option?.description || '';
    });

  return filterLabels || [];
}

/**
 * Calculate the percentage of available products in the given array.
 *
 * @param {Product[]} products - An array of product objects.
 * @returns {number} The percentage of available products. ex: 0.80
 */
function getPercentageOfAvailableProducts(products: Product[]): number {
  if (!products || products.length === 0) {
    return 0;
  }

  const availableProducts = products.filter(
    (product) => product.availability === AVAILABILITY.AVAILABLE,
  );

  const percentage = availableProducts.length / products.length;

  return percentage;
}

function getSingleActiveFilterByCategory(
  filterCategory: SrpFilterCategories,
  filtersApplied: SrpFilters,
  filterOptions: SrpFilterOptions,
): string {
  const option = filtersApplied[filterCategory];

  const [trueKey] = Object.entries(option).find(([key, value]) => value && key) || [];

  if (trueKey) {
    const optionLabel = filterOptions[filterCategory].find(
      (optionType: FilterOptionType) => optionType.name === trueKey,
    );
    return optionLabel?.description || '';
  }

  return '';
}

function generateFilterPayload(
  filtersApplied: SrpFilters | null,
  filterOptions: SrpFilterOptions | null,
  showOnlyAvailableHotels: boolean,
): FilterPropertyGroupType | {} {
  if (!filtersApplied || !filterOptions) {
    return {};
  }

  return {
    filter_by_amenities: getActiveFiltersByCategory(
      FILTER_CATEGORY_AMENITIES,
      filtersApplied,
      filterOptions,
    ),
    filter_by_guest_rating: getActiveFiltersByCategory(
      FILTER_CATEGORY_GUEST_RATING,
      filtersApplied,
      filterOptions,
    ),
    filter_by_hotel_vibes: getActiveFiltersByCategory(
      FILTER_CATEGORY_HOTEL_VIBES,
      filtersApplied,
      filterOptions,
    ),
    filter_by_only_available: showOnlyAvailableHotels,
    filter_by_hotel_class: getSingleActiveFilterByCategory(
      FILTER_CATEGORY_STAR_CLASSIFICATION,
      filtersApplied,
      filterOptions,
    ),
  };
}

function generateProductPayload(product: Product | undefined): ProductPropertyGroupType | {} {
  if (!product) {
    return {};
  }

  const payload: ProductPropertyGroupType = {
    product_addon_ids: product.is_product_time_group
      ? []
      : product.addons.filter((addon) => addon.active).map((addon) => addon.id) || [],
    product_addon_names: product.is_product_time_group
      ? []
      : product.addons.filter((addon) => addon.active).map((addon) => addon.name) || [],
    product_addons: product.is_product_time_group
      ? []
      : product.addons.filter((addon) => addon.active) || [],
    product_adult_price: +product.adult_price || 0,
    product_allow_adult: product.allow_adult,
    product_allow_child: product.allow_child,
    product_allow_infant: product.infants_allowed,
    product_availability: product.availability,
    product_blocked_out: product.blocked_out || false,
    product_booking_days_allowed_in_advance: null, // SRP
    product_children_price: product.children_price,
    product_end_time: product.end_time || null,
    product_id: product.product_id || null,
    product_individually_billed: product.individual_billing,
    product_is_product_time_group: product.is_product_time_group || false,
    product_max_people_allowed: product.max_person_occupancy,
    product_name: product.name,
    product_sort_order: product.sort_order,
    product_start_time: product.start_time || null,
    product_time_group_id: product.product_time_group_id ? +product.product_time_group_id : null,
    product_type: product.product_type,
    strikethrough_active: !!(
      product.is_adult_strikethrough_pricing && product.adult_discount_percentage > 0.1
    ),
    strikethrough_discount: product.adult_discount_percentage
      ? parseFloat(product.adult_discount_percentage.toFixed(2))
      : 0,
  };

  return payload;
}

function generateHotelPayload(
  hotel: HotelDetails | HotelApi | undefined,
  products: Product[] | undefined,
): HotelPropertyGroupType | {} {
  if (!hotel) {
    return {};
  }

  const avgRating = (hotel as HotelDetails).avg_rating || (hotel as HotelApi).avgRating;

  const cityId = (hotel as HotelDetails).city_id || (hotel as HotelApi).cityId;

  const cityName = (hotel as HotelDetails).city_name || (hotel as HotelApi).cityName;

  const closedForSeason =
    (hotel as HotelDetails).closed_for_season || (hotel as HotelApi).closedForSeason;

  const reopenDate = (hotel as HotelDetails).reopen_date || (hotel as HotelApi).reopenDate;

  const reviewsCount = (hotel as HotelDetails).reviews_count || (hotel as HotelApi).reviewsCount;

  const shortDescription =
    (hotel as HotelDetails).short_description || (hotel as HotelApi).shortDescription;

  const hotelStar = (hotel as HotelDetails).star_classification || (hotel as HotelApi).hotelStar;

  const state = (hotel as HotelDetails).state_name || (hotel as HotelApi).state;

  const hotelProducts: HotelProducts[] =
    'products' in hotel ? (hotel as HotelApi).products : (products as Product[]) || [];

  const payload: HotelPropertyGroupType = {
    hotel_active: hotel.active,
    hotel_amenities: hotel.amenities ? hotel.amenities.map((amenity) => amenity.name) : [],
    hotel_availability_relative_to_today:
      'availability' in hotel ? (hotel as HotelApi).availability : null,
    hotel_available_products: hotelProducts
      .filter((product) => product.availability === AVAILABILITY.AVAILABLE)
      .map((product) => product.name),
    hotel_avg_rating: +avgRating || 0,
    hotel_city_id: cityId,
    hotel_city_name: cityName,
    hotel_city_sort_order: 'citySortOrder' in hotel ? (hotel as HotelApi).citySortOrder : null,
    hotel_closed_for_season: closedForSeason,
    hotel_created_at:
      'createdAt' in hotel
        ? getDateWithDashes(new Date((hotel as HotelApi).createdAt))
        : getDateWithDashes(new Date((hotel as HotelDetails).metadata.created_at)),
    hotel_discounted: hotel.discounted,
    hotel_favorites_count: 'favoritesCount' in hotel ? (hotel as HotelApi).favoritesCount : null,
    hotel_id: hotel.id,
    hotel_low_quantity_products: hotelProducts
      .filter((product: Product | HotelApiProduct) => {
        if ('adult_available_unit' in product) {
          return product.adult_available_unit < 4;
        }

        return product.quantity && product.quantity < 4;
      })
      .map((product) => product.name),
    hotel_name: hotel.name,
    hotel_region: isArray(hotel.region) ? hotel.region[0] : hotel.region,
    hotel_reopen_date: reopenDate,
    hotel_reviews_count: reviewsCount || 0,
    hotel_short_description: shortDescription,
    hotel_star_classification: hotelStar,
    hotel_state_name: state,
    hotel_tags: hotel.tags ? hotel.tags.map((tag) => tag.name) : [],
    hotel_unavailable_products: hotelProducts
      .filter((product) => product.availability === AVAILABILITY.UNAVAILABLE)
      .map((product) => product.name),
    hotel_vibes:
      hotel.vibes && hotel.vibes.primary
        ? [hotel.vibes.primary, hotel.vibes.secondary || ''].filter((vibe) => vibe !== '')
        : [],
  };

  return payload;
}

function generatePopularHotelPayload(
  hotel: PopularHotel | undefined,
): PopularHotelPropertyGroupType | {} {
  if (!hotel) {
    return {};
  }

  const payload: PopularHotelPropertyGroupType = {
    hotel_avg_rating: +hotel.avg_rating || 0,
    hotel_city_name: hotel.city_name,
    hotel_id: hotel.id,
    hotel_name: hotel.name,
    hotel_reviews_count: hotel.reviews_count || 0,
    hotel_state_name: hotel.state_name,
  };

  return payload;
}

function generateSearchLocationPayload(
  searchLocation: Location | null,
): SearchLocationPropertyGroupType | {} {
  if (!searchLocation) {
    return {};
  }

  const payload: SearchLocationPropertyGroupType = {
    search_location_id: searchLocation.id,
    search_location_name: searchLocation.name,
    search_location_type: searchLocation.detailed_type,
    search_location_url: searchLocation.url,
  };

  return payload;
}

export default function useAmplitudePayloads() {
  return {
    generateBookingPayload,
    generateCartItemPayload,
    generateCouponPayload,
    generateFilterPayload,
    generateHotelPayload,
    generatePopularHotelPayload,
    generateProductPayload,
    generateSearchLocationPayload,
    getPercentageOfAvailableProducts,
  };
}
