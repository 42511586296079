import React from 'react';
import cn from '@helpers/className';

function Divider() {
  return <div className="h-6 border-l border-solar-secondary mx-spacing-sm" />;
}

export type SearchControlLayoutProps = {
  dateContent: React.ReactNode;
  locationContent: React.ReactNode;
  hasDateAndIconDivider?: boolean;
  hasLocationAndDateDivider?: boolean;
  iconContent?: React.ReactNode;
  layout?: 'row-same-width' | 'row-first-item-priority' | 'column';
};

export default function SearchControlLayout({
  dateContent,
  locationContent,
  hasDateAndIconDivider = false,
  hasLocationAndDateDivider = true,
  iconContent = undefined,
  layout = 'row-first-item-priority',
}: SearchControlLayoutProps) {
  return (
    <div className="flex flex-col">
      <div
        className={cn({
          'bg-solar-surface-primary border border-solar-secondary flex items-center p-spacing-sm relative rounded-solar-sm text-solar-primary w-full':
            true,
          'flex-col': layout === 'column',
          'flex-row': layout === 'row-same-width' || layout === 'row-first-item-priority',
        })}
      >
        <div className="flex flex-1 flex-shrink-0 relative w-full">{locationContent}</div>

        {hasLocationAndDateDivider ? <Divider /> : null}

        <div
          className={cn({
            'flex-shrink-0 items-center relative': true,
            'flex-1': layout === 'row-same-width',
            '': layout === 'row-first-item-priority',
          })}
        >
          {dateContent}
        </div>

        {hasDateAndIconDivider && iconContent ? <Divider /> : null}

        {iconContent ? <div>{iconContent}</div> : null}
      </div>

      {layout === 'column' && iconContent ? <div className="w-full">{iconContent}</div> : null}
    </div>
  );
}
