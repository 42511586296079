import { useEffect, useState } from 'react';

/**
 * Hook to lock/unlock body scroll based on a condition.
 * @param {boolean} isLocked - Whether to lock the body scroll.
 * @param {string} [className='no-scroll'] - CSS class to apply when locked (optional).
 * @returns {void}
 */
export default function useScrollLock(isLocked: boolean, className: string = 'no-scroll') {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true); // Only runs on client
  }, []);

  useEffect(() => {
    if (!isMounted) return; // Skip during SSR

    const disableBodyScroll = () => {
      const { scrollY } = window; // Capture current scroll position
      document.body.classList.add(className);
      document.body.style.width = '100%'; // Prevent width shift
      document.body.style.top = `-${scrollY}px`; // Store scroll position
      document.body.style.position = 'fixed'; // Lock body in place
    };

    const enableBodyScroll = () => {
      const scrollY = parseInt(document.body.style.top || '0', 10);
      document.body.classList.remove(className);
      document.body.style.width = '';
      document.body.style.top = '';
      document.body.style.position = '';
      window.scrollTo(0, -scrollY); // Restore scroll position
    };

    if (isLocked) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }

    // Cleanup on unmount or when isLocked changes
    // eslint-disable-next-line consistent-return
    return () => {
      enableBodyScroll();
    };
  }, [isLocked, isMounted, className]);
}
