/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useRef, useState } from 'react';
import { CloseIcon } from '@resortpass/solar-icons';

type Props = {
  onChange: (value: string) => void;
  searchTerm: string;
  placeholder?: string;
  onClick?: () => void;
  onClearInput?: () => void;
  isActive?: boolean;
};

export default function SelectSearchInput({
  onChange,
  onClick = () => {},
  onClearInput = () => {},
  placeholder = '',
  searchTerm = '',
  isActive = false,
}: Props) {
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const blurTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }

    onClick();
  };

  const handleClearInputClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    onClearInput();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleBlur = () => {
    blurTimeout.current = setTimeout(() => {
      setIsFocused(false);
    }, 300);
  };

  const handleFocus = () => {
    if (blurTimeout.current) {
      clearTimeout(blurTimeout.current);
    }
    setIsFocused(true);
  };

  return (
    <div className="flex flex-row items-center justify-between w-full">
      <label htmlFor="search-input">
        <span className="sr-only">Search</span>
      </label>

      <input
        ref={inputRef}
        id="search-input"
        type="text"
        autoComplete="off"
        inputMode="search"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        value={searchTerm}
        onClick={handleInputClick}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoFocus={isActive}
        className="w-full text-base leading-5 truncate bg-transparent outline-none font-rp-basetica placeholder-solar-secondary text-solar-primary"
        placeholder={placeholder || 'City, State, or Hotel'}
      />

      {isFocused && searchTerm.length ? (
        <div className="flex flex-shrink-0 h-6 w-6 items-center justify-center ml-3">
          <button
            className="flex h-full items-center justify-center w-full"
            type="button"
            onClick={handleClearInputClick}
          >
            <CloseIcon size={16} />
          </button>
        </div>
      ) : null}
    </div>
  );
}
