import React, { ReactNode, MouseEventHandler } from 'react';
import cn from '@helpers/className';

const baseStyles =
  'w-full flex justify-center items-center py-4 rounded-xl font-rp-pn-semi-bold tracking-wide';

type Props = {
  children: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  classes?: string;
  isDisabled?: boolean;
  outline?: boolean;
};

export default function ButtonPrimary({
  onClick,
  children,
  classes = '',
  isDisabled = false,
  outline = false,
}: Props) {
  const bOutline = !!outline;

  const btnStyles = cn({
    // outline button styles
    'border-solid': bOutline,
    border: bOutline,
    'border-rp-primary': bOutline,
    'text-rp-primary': bOutline,

    // regular styles
    'text-white': !bOutline,
    'bg-rp-primary': !bOutline,
    'hover:bg-rp-primary-light': !bOutline,
    'active:bg-rp-primary-dark': !bOutline,
  });

  return (
    <button
      type="button"
      className={`${classes} ${baseStyles} ${btnStyles} ${
        isDisabled ? 'pointer-events-none opacity-50' : 'pointer-events-auto opacity-100'
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
