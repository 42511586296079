import React from 'react';
import ReactDatePicker from '@components/common/ReactDatePicker/ReactDatePicker';
import Tabs from '@solar/common/Tabs/Tabs';
import Button from '@solar/common/Button/Button';
import { getDateToUse } from '@helpers/date';

type Props = {
  tab?: 'date' | 'flexible';
  date?: Date;
  onDateChange?: (date?: Date) => void;
  onCancelClick?: () => void;
  onApplyButtonClick?: () => void;
  onTabClick?: (tab: 'date' | 'flexible') => void;
  showButtons?: boolean;
};

export default function DatePicker({
  tab = 'date',
  date = getDateToUse(),
  onCancelClick = () => {},
  onApplyButtonClick = () => {},
  onTabClick = () => {},
  onDateChange = () => {},
  showButtons = true,
}: Props) {
  return (
    <div className="flex flex-col h-full text-solar-primary pt-spacing-xs w-full">
      <div className="flex-shrink-0 px-spacing-md sticky top-0 bg-solar-surface-primary">
        <Tabs defaultValue={tab} onSelect={onTabClick as (tab: string) => void} size="md">
          <Tabs.List alignment="flexed">
            <Tabs.Tab key="date" value="date" className="flex-1 w-full">
              Date
            </Tabs.Tab>

            <Tabs.Tab key="flexible" value="flexible" className="flex-1 w-full">
              Flexible
            </Tabs.Tab>
          </Tabs.List>
        </Tabs>
      </div>

      <div className="flex-1 mt-spacing-lg px-10 overflow-y-auto relative">
        <ReactDatePicker
          startDate={date}
          classes="font-rp-basetica text-solar-primary no-shadow w-full"
          setStartDate={onDateChange}
          inline
          variantId="home-page-search-experiment"
          wrapperClassName={`${
            tab === 'flexible'
              ? 'datepicker-forced-disabled cursor-default pointer-events-none'
              : ''
          }`}
          showClearDate={false}
          usePreSelectionScroll={false}
        />
      </div>

      {showButtons ? (
        <div className="border-t border-solar-secondary flex flex-row flex-shrink-0 items-center justify-between mt-spacing-lg p-spacing-md space-x-spacing-md sticky bottom-0 bg-solar-surface-primary">
          <Button color="transparent" size="lg" onClick={onCancelClick} fullWidth>
            Cancel
          </Button>

          <Button color="primarySRP" size="lg" onClick={onApplyButtonClick} fullWidth>
            Apply
          </Button>
        </div>
      ) : null}
    </div>
  );
}
