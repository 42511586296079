import React, { Dispatch, SetStateAction } from 'react';
import { CheckoutInfo } from '@customTypes/checkout-info';
import CheckoutDetails from './CheckoutDetails';

type Props = {
  checkoutDetails: CheckoutInfo;
  setCheckoutDetails: Dispatch<SetStateAction<CheckoutInfo | null | undefined>> | undefined;
};

export default function CheckoutMobileDetailsDrawer({
  checkoutDetails,
  setCheckoutDetails,
}: Props) {
  return (
    <div className="mx-spacing-md">
      <CheckoutDetails checkoutDetails={checkoutDetails} setCheckoutDetails={setCheckoutDetails} />
    </div>
  );
}
