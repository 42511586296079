/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  delay?: number;
};

export default function Popup({ children, delay = 0, isOpen, setIsOpen = () => null }: Props) {
  const closePopup = () => {
    if (setIsOpen) setIsOpen(false);
  };

  useEffect(() => {
    if (delay && setIsOpen && isOpen) {
      setTimeout(() => {
        setIsOpen(false);
      }, delay);
    }
  }, [delay, setIsOpen, isOpen]);

  return isOpen ? (
    <div>
      <div
        className="popupBackground fixed left-0 top-0 h-full w-full z-100 bg-rp-black-faded overflow-auto"
        onClick={closePopup}
        onKeyDown={() => closePopup}
        role="button"
        tabIndex={0}
      />
      <div className="popup mx-auto my-auto left-4 fixed top-1/5 bg-white h-1/2 w-11/12 z-100 rounded-xl flex items-center flex-col justify-center ml min-h-330 d:h-2/5 d:w-2/5 d:left-1/3 d:top-1/4 d:min-h-400">
        {children}
      </div>
    </div>
  ) : null;
}
