/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import pluralize from 'pluralize-esm';
import Paragraph from '@solar/common/Paragraph/Paragraph';
import SelectSearchInput from '@components/SearchExperiment/SelectSearchInput';
import { useSearchContext } from '@context/SearchContext2025';
import useIsMobile from '@hooks/useIsMobile';
import { upperFirst } from 'lodash';

type Props = {
  handleOnInputClick: () => void;
  handleOnChange?: (term: string) => void;
  handleOnClearInput?: () => void;
  value: string;
  showProductCategoriesText?: boolean;
};

export default function LocationInputControl({
  handleOnInputClick,
  handleOnChange = () => {},
  handleOnClearInput = () => {},
  showProductCategoriesText = false,
  value,
}: Props) {
  const isMobile = useIsMobile({ isSolarSystem: true });
  const {
    searchParams: { search_term: searchTerm, product_category: productCategory },
  } = useSearchContext();

  const displayText = useMemo(() => {
    const displayCategory =
      !productCategory || productCategory === '' || productCategory === 'all'
        ? 'all hotels'
        : pluralize(productCategory);

    return searchTerm
      ? productCategory && productCategory !== 'all' && productCategory !== ''
        ? `${upperFirst(displayCategory)} near`
        : null
      : `Search ${displayCategory} by location`;
  }, [searchTerm, productCategory]);

  const inputComponent = (
    <div className="flex items-center w-full">
      <SelectSearchInput
        searchTerm={value}
        onClick={handleOnInputClick}
        onChange={handleOnChange}
        onClearInput={handleOnClearInput}
      />
    </div>
  );

  return (
    <div className="flex items-center relative w-full space-x-1.5">
      {isMobile ? (
        <button
          type="button"
          onClick={handleOnInputClick}
          className="flex h-full items-center space-x-1 w-full"
        >
          {showProductCategoriesText ? (
            <Paragraph size="sm" className="flex-shrink-0 text-solar-secondary">
              {displayText}
            </Paragraph>
          ) : null}

          {value && (
            <Paragraph size="sm" className="truncate">
              {value}
            </Paragraph>
          )}
        </button>
      ) : (
        inputComponent
      )}
    </div>
  );
}
