import User from '@customTypes/user';

import { getDateWithDashes, getDateWithSlashes } from '@helpers/date';
import SHA256 from '@helpers/encoders';

import { sendToGTM } from './GTM';

const becomeHotelPartnerClickHomePage = () => {
  sendToGTM({
    event: 'become_hotel_partner_clicked_on_homepage',
  });
};

const exploreLocationThumbnailClick = (state: string) => {
  sendToGTM({
    event: 'explore_location_thumbnail_clicked',
    home_page: {
      explore_location: state,
    },
  });
};

const giftCardLearnMoreClick = () => {
  sendToGTM({
    event: 'gift_card_learn_more_clicked_on_homepage',
  });
};

const homePageView = async (user: User, sessionID: string, date: string) => {
  const email = user?.email ? await SHA256(user?.email.toLowerCase().trim()) : null;

  sendToGTM({
    event: 'homepage_viewed',
    sojern_homepage_viewed: {
      sha256_eml: email,
      ccid: user ? user.id?.toString() : sessionID,
      vcu: 'USD',
      vd1: date ? getDateWithDashes(new Date(date)) : null,
      vd2: date ? getDateWithDashes(new Date(date)) : null,
    },
  });
};

const popularHotelThumbnailClick = (hotel_name: string) => {
  sendToGTM({
    event: 'popular_hotel_thumbnail_clicked',
    home_page: {
      popular_hotel: hotel_name,
    },
  });
};

const searchDateEntered = (selectedDate: Date) => {
  sendToGTM({
    event: 'homepage_date_entered',
    selected_date: selectedDate ? getDateWithSlashes(selectedDate) : null,
  });
};

const searchNewLocationSelected = (location: string) => {
  sendToGTM({
    event: 'homepage_search_entered',
    home_page: {
      search_location: location,
    },
  });
};

const searchButtonClick = () => {
  // Custom event
  sendToGTM({
    event: 'search_button_clicked',
  });
};

export {
  becomeHotelPartnerClickHomePage,
  exploreLocationThumbnailClick,
  giftCardLearnMoreClick,
  homePageView,
  popularHotelThumbnailClick,
  searchButtonClick,
  searchDateEntered,
  searchNewLocationSelected,
};
