/* eslint-disable react/no-array-index-key */
import React, { forwardRef, useCallback } from 'react';
import { Location } from '@components/Hotels/types';
import { useSearchContext } from '@context/SearchContext';
import { sanitizeInput, validateInput } from '@helpers/forms';
import ResultLink from './ResultLink';

type Props = {
  onResultClick: () => void;
};

const LocationResultsList = forwardRef((props: Props, locationSelectionRef: any) => {
  const { onResultClick } = props;
  const { searchValue, searchResults } = useSearchContext();

  const boldWords = useCallback(
    (location: Location) => {
      const cleanSearchValue = sanitizeInput(searchValue);

      if (!validateInput(cleanSearchValue)) {
        return [<span key={`${location.id}-default`}>{location.name}</span>];
      }

      // Highlight matching parts of location name
      return location.name
        .split(new RegExp(`(${cleanSearchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi'))
        .map((part, index) => (
          <span
            key={`${location.id}-${index}`}
            className={
              part.toLowerCase() === cleanSearchValue.toLowerCase() ? 'text-custom-black' : ''
            }
          >
            {part}
          </span>
        ));
    },
    [searchValue],
  );

  return (
    <div ref={locationSelectionRef} className="flex flex-col gap-2">
      {searchResults.map((location, index) => (
        <ResultLink
          onResultClick={onResultClick}
          key={`${location.id}-${index}`}
          location={location}
          result={boldWords(location)}
        />
      ))}
    </div>
  );
});

export default LocationResultsList;
