export const heroTitles = {
  all: {
    title: 'Experience the best hotel amenities for the day',
    subTitle: 'No overnight stay needed, starting at $25.',
  },
  'day room': {
    title: 'Experience the best hotel rooms for the day',
    subTitle: 'No overnight stay needed.',
  },
  pool: {
    title: 'Experience the best hotel pools for the day',
    subTitle: 'No overnight stay needed, starting at $25.',
  },
  spa: {
    title: 'Experience the best hotel spas for the day',
    subTitle: 'No overnight stay needed, starting at $25.',
  },
};

export const srpHeading = {
  all: 'Hotel day passes in and near',
  'day room': 'Day Rooms near',
  pool: 'Pools near',
  spa: 'Spas near',
};
