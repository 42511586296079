/* eslint-disable @typescript-eslint/indent */
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import Image from 'next/image';
import searchIcon from '@assets/images/search-icon-white.svg';
import { FilterIcon, LocationIcon } from '@resortpass/solar-icons';
import Button from '@solar/common/Button/Button';
import SearchProvider, {
  useSearchContext,
  AmenitiesFriendlyNames,
  HotelStarsFriendlyNames,
  GuestRatingFriendlyNames,
  VibesFriendlyNames,
  useDefaultContextImplementation,
} from '@context/SearchContext2025';
import { getLocations } from '@utils/services';
import List from '@solar/common/Lists/List';
import { Location } from '@components/Hotels/types';
import { filter, isEmpty } from 'lodash';
import useOutsideClick from '@hooks/useOutsideClick';
import cn from '@helpers/className';
import { applyUserLocation, getLocationTypeIcon } from '@helpers/location';
import DatePicker from '@components/SearchExperiment/SearchControls/DatePicker';
import DatePickerControl from '@components/SearchExperiment/SearchControls/Controls/DatePickerControl';
import LocationInputControl from '@components/SearchExperiment/SearchControls/Controls/LocationInputControl';
import LocationsSearchDrawer from '@components/SearchExperiment/SearchControls/LocationsSearchDrawer';
import { SEARCH_DEFAULT_LOCATIONS } from '@constants/SearchDefaultOptions';
import useIsMobile from '@hooks/useIsMobile';
import useDebounce from '@hooks/useDebounce';
import { getBoldedText } from '@helpers/textUtils';
import { useDrawer } from '@context/DrawerContext';
import { useUserLocation } from '@context/GeoIpDetectionContext';
import { SearchState } from '@customTypes/search';
import { parseDateWithDashes } from '@helpers/date';
import SRPFilters from '@components/SearchExperiment/SRPFilters/SRPFilters';
import {
  useAmplitudeStartSelectSearchLocationTracker,
  useAmplitudeTypeaheadTracker,
  useAmplitudeFlexibleDateTracker,
  useAmplitudeDateCancelTracker,
  useAmplitudeApplyFilterTracker,
  useAmplitudeFilterCancelTracker,
} from '@hooks/useAmplitudeTrackers';
import SearchControlLayout, { SearchControlLayoutProps } from './SearchControlLayout';

export type SearchBarProps = {
  layout: SearchControlLayoutProps['layout'];
  showLocationIcon?: boolean;
  showCalendarIcon?: boolean;
  buttonVariant?: 'icon' | 'text' | 'filter' | 'no-button';
  dropdownLayout?: 'full' | 'compact';
  showProductCategoriesText?: boolean;
  hasDateAndIconDivider?: boolean;
  autoSearch?: boolean;
};

// Button configuration
const buttonConfig = {
  icon: {
    content: <Image src={searchIcon} alt="search-icon" width={16} height={16} />,
    color: 'primarySRP',
    size: 'sm',
    noPadding: true,
    widthClass: 'h-8 w-8 ml-spacing-md',
    action: 'search',
  },
  text: {
    content: 'Search',
    color: 'primarySRP',
    size: 'lg',
    noPadding: false,
    widthClass: 'w-30',
    action: 'search',
  },
  filter: {
    content: <FilterIcon />,
    color: 'transparent',
    size: 'sm',
    noPadding: true,
    widthClass: 'h-8 w-8',
    action: 'filter',
  },
} as const;

export default function SearchControls({
  layout,
  showLocationIcon = true,
  showCalendarIcon = true,
  buttonVariant = 'icon',
  dropdownLayout = 'full',
  showProductCategoriesText = false,
  hasDateAndIconDivider = false,
  autoSearch = false,
}: SearchBarProps) {
  const { closeDrawer, openDrawerWithComponent, rerender } = useDrawer();
  const isMobile = useIsMobile({ isSolarSystem: true });
  const {
    search,
    setSearchTerm,
    setCountryCode,
    setCity,
    setHotelName,
    setStateCode,
    setAliasId,
    updateSearchState,
    setDate,
    searchParams,
  } = useSearchContext();

  const { search_term: searchTerm, filters, date } = searchParams;
  const userLocation = useUserLocation();
  // -------------------------------------------------------------------
  // Typeahead State ---------------------------------------------------
  // -------------------------------------------------------------------
  const [typeaheadInput, setTypeaheadInput] = useState(searchTerm || userLocation?.name || '');
  const [typeAheadResults, setTypeAheadResults] = useState<Location[]>([]);

  // -------------------------------------------------------------------
  // DatePicker State --------------------------------------------------
  // -------------------------------------------------------------------
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const locationRemovalRef = useRef<HTMLDivElement>(null);
  const [dateSelectionRef] = useOutsideClick(() => setShowDatePicker(false));

  // Datepicker state
  const [dateTab, setDateTab] = useState<'date' | 'flexible'>(date === 'any' ? 'flexible' : 'date');
  const [drawerDatepickerTab, setDrawerDatepickerTab] = useState<'date' | 'flexible'>(
    date === 'any' ? 'flexible' : 'date',
  );
  const [drawerDatepickerDate, setDrawerDatepickerDate] = useState<Date>(
    date === 'any' || date === '' ? new Date() : parseDateWithDashes(date),
  );

  // Amplitude Trackers
  const amplitudeTypeaheadSelectTracker = useAmplitudeTypeaheadTracker();
  const amplitudeStartSelectSearchTracker = useAmplitudeStartSelectSearchLocationTracker();
  const amplitudeFlexibleDateTracker = useAmplitudeFlexibleDateTracker();
  const amplitudeCancelSearchDateTracker = useAmplitudeDateCancelTracker();
  const amplitudeApplyFilterTracker = useAmplitudeApplyFilterTracker();
  const amplitudeCancelSearchFilterTracker = useAmplitudeFilterCancelTracker();

  // -------------------------------------------------------------------
  // Typeahead Handlers ------------------------------------------------
  // -------------------------------------------------------------------
  const defaultResults = useMemo(() => {
    if (userLocation) {
      return [
        userLocation,
        ...filter(SEARCH_DEFAULT_LOCATIONS, (loc) => loc.id !== userLocation.id),
      ];
    }
    return SEARCH_DEFAULT_LOCATIONS;
  }, [userLocation]);

  const fetchTypeaheadResults = useDebounce(async (term: string) => {
    const trimmed = term.trim();
    if (trimmed) {
      const locations = await getLocations(`"${term.trim()}"`);
      setTypeAheadResults(locations);
    } else {
      setTypeAheadResults(defaultResults);
    }
    if (trimmed.length > 0) amplitudeStartSelectSearchTracker();
  }, 300);

  /**
   * Handle when a location is selected from the typeahead
   */
  const handleOnLocationSelect = useCallback(
    (location: Location) => {
      closeDrawer();
      setTypeAheadResults([]);
      applyUserLocation(location, {
        setCountryCode,
        setCity,
        setHotelName,
        setStateCode,
        setSearchTerm,
        setAliasId,
      });

      if (autoSearch && typeaheadInput !== location.name) {
        search();
      }
      setTypeaheadInput(location.name);
      amplitudeTypeaheadSelectTracker(location);
    },
    [
      autoSearch,
      closeDrawer,
      typeaheadInput,
      setTypeaheadInput,
      setTypeAheadResults,
      setCountryCode,
      setCity,
      setHotelName,
      setStateCode,
      setSearchTerm,
      setAliasId,
      search,
      amplitudeTypeaheadSelectTracker,
    ],
  );

  /**
   * Handle when the typeahead input is clicked / Focused
   */
  const handleOnInputClick = useCallback(() => {
    if (typeaheadInput) {
      fetchTypeaheadResults(typeaheadInput);
    } else {
      setTypeAheadResults(defaultResults);
    }
  }, [typeaheadInput, fetchTypeaheadResults, defaultResults]);

  /**
   * Handle when the typeahead input value changes
   */
  const handleOnSearchTermChange = useCallback(
    (term: string) => {
      setTypeaheadInput(term);
      fetchTypeaheadResults(term);
    },
    [setTypeaheadInput, fetchTypeaheadResults],
  );

  /**
   * Handle when the typeahead "x" is pressed
   */
  const handleOnClearInput = useCallback(() => {
    setTypeaheadInput('');
    setTypeAheadResults(defaultResults);
    setSearchTerm('');
  }, [setSearchTerm, setTypeaheadInput, setTypeAheadResults, defaultResults]);

  // -------------------------------------------------------------------
  // The Typeahead Locations Drawer Component --------------------------
  // -------------------------------------------------------------------
  const locationSearchDrawer = useMemo(
    () => (
      <LocationsSearchDrawer
        value={typeaheadInput}
        locations={typeAheadResults}
        onClose={closeDrawer}
        onInputClick={handleOnInputClick}
        onChange={handleOnSearchTermChange}
        onClear={handleOnClearInput}
        onSelect={handleOnLocationSelect}
        userLocation={userLocation}
      />
    ),
    [
      handleOnInputClick,
      handleOnSearchTermChange,
      handleOnClearInput,
      handleOnLocationSelect,
      closeDrawer,
      typeAheadResults,
      typeaheadInput,
      userLocation,
    ],
  );

  // rerender when the component changes
  useEffect(() => {
    rerender('locations-drawer', locationSearchDrawer);
  }, [locationSearchDrawer, rerender]);

  // Handle location input click
  const handleLocationInputClick = () => {
    if (isMobile) {
      openDrawerWithComponent(locationSearchDrawer, 'locations-drawer', {
        height: '99dvh',
      });
    }

    if (typeaheadInput) {
      fetchTypeaheadResults(typeaheadInput);
    } else {
      setTypeAheadResults(defaultResults);
    }
    setShowDatePicker(false);
  };

  const handleDatePickerDateChange = useCallback(
    (selectedDate?: Date) => {
      if (!selectedDate) setDate('');
      setDate(selectedDate as Date);
      setShowDatePicker(false);

      if (autoSearch) {
        search();
      }
    },
    [autoSearch, search, setDate],
  );

  const handleDrawerDatepickerDateChange = useCallback(
    (selectedDate?: Date) => {
      if (selectedDate) setDrawerDatepickerDate(selectedDate);
    },
    [setDrawerDatepickerDate],
  );

  const handleApplyDateChange = useCallback(() => {
    if (drawerDatepickerTab === 'flexible') {
      setDate('any');
    }

    if (drawerDatepickerTab === 'date' && drawerDatepickerDate) {
      setDate(drawerDatepickerDate);
    }

    if (autoSearch) {
      search();
    }

    closeDrawer();
  }, [autoSearch, drawerDatepickerTab, drawerDatepickerDate, setDate, search, closeDrawer]);

  const handleDrawerTabChange = useCallback(
    (tab: 'date' | 'flexible') => {
      if (tab === 'flexible') {
        setDate('any');

        if (autoSearch) {
          search();
        }

        amplitudeFlexibleDateTracker(searchParams);
        closeDrawer();
      }

      setDrawerDatepickerTab(tab);
    },
    [
      autoSearch,
      closeDrawer,
      search,
      setDrawerDatepickerTab,
      amplitudeFlexibleDateTracker,
      searchParams,
      setDate,
    ],
  );

  const handleDatepickerTabChange = useCallback(
    (tab: 'date' | 'flexible') => {
      if (tab === 'flexible') {
        setDate('any');
        setShowDatePicker(false);

        if (autoSearch) {
          search();
        }
        amplitudeFlexibleDateTracker(searchParams);
      }
      setDateTab(tab);
    },
    [autoSearch, search, setDate, searchParams, amplitudeFlexibleDateTracker],
  );

  const handleCancel = useCallback(() => {
    amplitudeCancelSearchDateTracker(searchParams);
    closeDrawer();
  }, [closeDrawer, searchParams, amplitudeCancelSearchDateTracker]);

  // -------------------------------------------------------------------
  // Mobile Datepicker -------------------------------------------------
  // -------------------------------------------------------------------
  // Rendered datepicker to be used with the bottom drawer
  // -------------------------------------------------------------------
  const renderedDatePicker = useMemo(
    () => (
      <DatePicker
        tab={drawerDatepickerTab}
        date={drawerDatepickerDate}
        onTabClick={handleDrawerTabChange}
        onDateChange={handleDrawerDatepickerDateChange}
        onCancelClick={handleCancel}
        onApplyButtonClick={handleApplyDateChange}
        showButtons
      />
    ),
    [
      drawerDatepickerDate,
      drawerDatepickerTab,
      handleCancel,
      handleDrawerTabChange,
      handleDrawerDatepickerDateChange,
      handleApplyDateChange,
    ],
  );

  // call rerender on the drawer every time the datepicker changes
  useEffect(() => {
    rerender('date-picker', renderedDatePicker);
  }, [renderedDatePicker, rerender]);

  const handleDatePickerClick = () => {
    if (isMobile) {
      openDrawerWithComponent(renderedDatePicker, 'date-picker', {
        height: '75dvh',
      });
      return;
    }

    setTypeAheadResults([]);
    setShowDatePicker(true);
  };

  const [locationSelectionRef] = useOutsideClick(
    () => {
      if (typeAheadResults.length) {
        handleOnLocationSelect(typeAheadResults[0]);
      }
      setTypeAheadResults([]);
    },
    '',
    locationRemovalRef,
  );

  // Scroll to element utility
  const scrollToElement = useCallback(
    (id: string) => {
      if (!isMobile) {
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
    [isMobile],
  );

  const appliedSRPFiltersCount = [
    ...(filters.only_available ? [true] : []),
    ...Object.entries(HotelStarsFriendlyNames)
      .filter(([key]) => filters.hotel_stars[key as keyof SearchState['filters']['hotel_stars']])
      .map(() => true),
    ...Object.entries(AmenitiesFriendlyNames)
      .filter(([key]) => filters.amenities[key as keyof SearchState['filters']['amenities']])
      .map(() => true),
    ...Object.entries(VibesFriendlyNames)
      .filter(([key]) => filters.vibes[key as keyof SearchState['filters']['vibes']])
      .map(() => true),
    ...Object.entries(GuestRatingFriendlyNames)
      .filter(
        ([key]) => filters.guest_ratings[key as keyof SearchState['filters']['guest_ratings']],
      )
      .map(() => true),
  ].length;

  // Scroll effects for type-ahead and datepicker
  useEffect(() => {
    if (!isEmpty(typeAheadResults)) scrollToElement('search-results');
  }, [scrollToElement, typeAheadResults]);

  useEffect(() => {
    if (showDatePicker) scrollToElement('date-picker');
  }, [scrollToElement, showDatePicker]);

  // Scroll to search results when location results are shown
  useEffect(() => {
    if (!isMobile && !isEmpty(typeAheadResults)) {
      const searchResultsElement = document.getElementById('search-results');

      if (searchResultsElement) {
        searchResultsElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [typeAheadResults, isMobile]);

  // Scroll to search results when location results are shown
  useEffect(() => {
    if (!isMobile && showDatePicker) {
      const searchResultsElement = document.getElementById('date-picker');

      if (searchResultsElement) {
        searchResultsElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [showDatePicker, isMobile]);

  // -------------------------------------------------------------------
  // Mobile SRP Filters ------------------------------------------------
  // -------------------------------------------------------------------
  const mobileSRPFiltersContext = useDefaultContextImplementation();
  useEffect(() => {
    mobileSRPFiltersContext.updateSearchState(searchParams);
  }, [mobileSRPFiltersContext, searchParams]);

  // Apply Filter
  const handleApplySearchFilters = useCallback(
    (s: SearchState) => {
      amplitudeApplyFilterTracker(s);
    },
    [amplitudeApplyFilterTracker],
  );

  // Close Drawer
  const handleCloseMobileSRPFilters = useCallback(() => {
    amplitudeCancelSearchFilterTracker(searchParams);
    closeDrawer();
  }, [closeDrawer, searchParams, amplitudeCancelSearchFilterTracker]);

  // Apply SRP Filters
  const handleSearchFromMobileFilters = useCallback(
    (s: SearchState) => {
      updateSearchState(s);
      search();
      closeDrawer();
    },
    [updateSearchState, search, closeDrawer],
  );

  const mobileSRPFilters = useMemo(
    () => (
      <SearchProvider value={mobileSRPFiltersContext}>
        <SRPFilters
          key="mobile-srp-filters"
          onClose={handleCloseMobileSRPFilters}
          onApply={handleSearchFromMobileFilters}
          onChange={handleApplySearchFilters}
          isOpen
        />
      </SearchProvider>
    ),
    [
      handleCloseMobileSRPFilters,
      handleApplySearchFilters,
      handleSearchFromMobileFilters,
      mobileSRPFiltersContext,
    ],
  );

  useEffect(() => {
    rerender('srp-filters', mobileSRPFilters);
  }, [mobileSRPFilters, rerender]);

  const renderSearchButton = () => {
    if (buttonVariant === 'no-button') return null;

    const config = buttonConfig[buttonVariant];
    const handleClick = (() => {
      if (config.action !== 'filter') return search;

      return () => {
        openDrawerWithComponent(mobileSRPFilters, 'srp-filters', {
          height: '99dvh',
        });
      };
    })();

    return (
      <div className={`flex flex-shrink-0 items-center ${config.widthClass}`}>
        <Button
          type="button"
          color={config.color}
          onClick={handleClick}
          fullWidth
          classes="h-full relative"
          aria-label={config.action === 'filter' ? 'Open filters' : 'Submit search'}
          size={config.size}
          noPadding={config.noPadding}
        >
          {config.content}

          {config.action === 'filter' && appliedSRPFiltersCount > 0 ? (
            <span
              style={{ right: '-2px' }}
              className="absolute bg-solar-surface-brand-shadow h-4 font-medium font-rp-basetica pb-1px pr-1px text-10 text-solar-inverted-primary top-0 rounded-full w-4 -tracking-1"
            >
              {appliedSRPFiltersCount}
            </span>
          ) : null}
        </Button>
      </div>
    );
  };

  /**
   * renderTypeaheadDropdown is used to render the SRP typeahead dropdown
   * (compact)
   */
  const renderTypeaheadDropdown = () => {
    if (isMobile || dropdownLayout === 'full') return null;

    return (
      <div
        ref={locationSelectionRef}
        className={cn({
          'absolute bg-solar-surface-primary border border-solar-secondary overflow-y-auto rounded-t-solar-sm shadow-solar-search z-30':
            true,
          'left-0 w-full': !isEmpty(typeAheadResults),
          'right-0': showDatePicker,
        })}
        style={{ top: '55px', maxHeight: '450px' }}
      >
        {!isEmpty(typeAheadResults) && (
          <ul className="flex flex-col">
            {typeAheadResults.map((result) => (
              <div key={result.name} className="relative">
                <List
                  icon={getLocationTypeIcon(result, userLocation)}
                  text={getBoldedText(result.name, typeaheadInput)}
                  onClick={() => handleOnLocationSelect(result)}
                  clickable
                />
              </div>
            ))}
          </ul>
        )}
      </div>
    );
  };

  /**
   * renderDatepickerDropdown is used to render the SRP datepicker dropdown
   * (compact)
   */
  const renderDatepickerDropdown = () => {
    if (isMobile || !showDatePicker || dropdownLayout === 'full') return null;
    return (
      <div
        ref={dateSelectionRef}
        id="date-picker"
        className="absolute bg-solar-surface-primary border border-solar-secondary overflow-y-auto rounded-t-solar-sm shadow-solar-search z-30 left-0"
        style={{ top: '55px', maxHeight: '450px' }}
      >
        <DatePicker
          date={date === 'any' || date === '' ? undefined : parseDateWithDashes(date)}
          tab={dateTab}
          onTabClick={handleDatepickerTabChange}
          onDateChange={handleDatePickerDateChange}
          onCancelClick={() => setShowDatePicker(false)}
          showButtons={false}
        />
      </div>
    );
  };

  /**
   * renderDropdown is used to render the HomePage typeahead and
   * datepicker dropdown (full-width)
   */
  const renderDropdown = () => {
    if (isMobile || dropdownLayout === 'compact') return null;

    return (
      <div
        ref={showDatePicker ? dateSelectionRef : locationSelectionRef}
        className={cn({
          'absolute bg-solar-surface-primary border border-solar-secondary overflow-y-auto rounded-t-solar-sm shadow-solar-search z-30':
            true,
          'left-0 w-full': !isEmpty(typeAheadResults),
          'right-0': showDatePicker,
        })}
        style={{ top: '55px', maxHeight: '450px' }}
      >
        {!isEmpty(typeAheadResults) && (
          <ul className="flex flex-col">
            {typeAheadResults.map((result) => (
              <div key={result.name} className="relative">
                <List
                  icon={getLocationTypeIcon(result, userLocation)}
                  text={getBoldedText(result.name, typeaheadInput)}
                  onClick={() => handleOnLocationSelect(result)}
                  clickable
                />
              </div>
            ))}
          </ul>
        )}

        {showDatePicker && (
          <div id="date-picker">
            <DatePicker
              tab={dateTab}
              date={date === 'any' || date === '' ? undefined : parseDateWithDashes(date)}
              onTabClick={handleDatepickerTabChange}
              onDateChange={(d) => handleDatePickerDateChange(d)}
              onCancelClick={() => setShowDatePicker(false)}
              showButtons={false}
            />
          </div>
        )}
      </div>
    );
  };

  const renderDatePicker = () => (
    <>
      <DatePickerControl
        showCalendarIcon={showCalendarIcon}
        handleOnInputClick={handleDatePickerClick}
        value={date}
      />

      {renderDatepickerDropdown()}
    </>
  );

  const renderLocationInput = () => (
    <>
      {showLocationIcon && (
        <div className="flex flex-shrink-0 mr-spacing-xs items-center pointer-events-none">
          <LocationIcon />
        </div>
      )}

      <LocationInputControl
        handleOnInputClick={handleLocationInputClick}
        handleOnChange={handleOnSearchTermChange}
        handleOnClearInput={handleOnClearInput}
        value={typeaheadInput}
        showProductCategoriesText={showProductCategoriesText}
      />

      {renderTypeaheadDropdown()}
    </>
  );

  return (
    <>
      <SearchControlLayout
        layout={layout}
        locationContent={renderLocationInput()}
        dateContent={renderDatePicker()}
        hasLocationAndDateDivider
        hasDateAndIconDivider={hasDateAndIconDivider}
        iconContent={renderSearchButton()}
      />

      {renderDropdown()}
    </>
  );
}
