import React, { useMemo } from 'react';
import Paragraph from '@solar/common/Paragraph/Paragraph';
import { CheckoutInfo, CheckoutItem as CheckoutInfoItemType } from '@customTypes/checkout-info';
import useCurrencyConverter from '@hooks/useCurrencyConverter';
import useHotSpot from '@hooks/useHotSpot';

export default function CheckoutItem({
  checkoutDetails,
  item,
}: {
  checkoutDetails: CheckoutInfo;
  item: CheckoutInfoItemType;
}) {
  const { applyHotSpotDiscountToTotal } = useHotSpot();
  const { convertCurrency } = useCurrencyConverter();

  const itemName = useMemo(() => {
    if (item.individual) {
      return item.type === 'adult' ? `Adult ${item.name}` : `Child ${item.name}`;
    }

    return item.name;
  }, [item]);

  return (
    <div className="border-b border-solar-secondary flex flex-row items-center justify-between pt-spacing-sm pb-spacing-md space-x-spacing-md">
      <div className="flex flex-row space-x-spacing-sm">
        <span className="bg-solar-surface-secondary px-spacing-sm py-0.5 rounded-solar-sm self-start">
          <Paragraph size="sm">{item.individual ? item.units : 1}</Paragraph>
        </span>

        <div className="flex flex-col">
          <Paragraph size="md">{itemName}</Paragraph>

          <Paragraph size="sm" className="text-solar-secondary">
            {convertCurrency(item.price)} ea.
          </Paragraph>
        </div>
      </div>

      <div className="flex flex-col">
        <Paragraph
          size="md"
          className={item.is_hot_spot ? 'text-solar-alert-red' : 'text-solar-primary'}
        >
          {convertCurrency(
            item.is_hot_spot && checkoutDetails.hot_spot
              ? applyHotSpotDiscountToTotal(item.total, checkoutDetails.hot_spot, false)
              : item.total,
          )}
        </Paragraph>

        {item.is_hot_spot ? (
          <Paragraph size="sm" className="text-right text-solar-secondary line-through">
            {convertCurrency(item.total)}
          </Paragraph>
        ) : null}
      </div>
    </div>
  );
}
