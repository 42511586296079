import sessionStorageKeys from '@constants/sessionStorageKeys';
import useSessionStorage from './useSessionStorage';

export default function useCurrencyConverter() {
  const { getItem } = useSessionStorage();
  const storageCurrency = getItem(sessionStorageKeys.SELECTED_CURRENCY);

  const formatCurrency = (amount: number, currency: string) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);

  const convertToSelectedCurrency = (amountUSD: number, exchangeRate: number) =>
    amountUSD * exchangeRate;

  const convertCurrency = (value: number) => {
    if (!storageCurrency) {
      return formatCurrency(value, 'USD');
    }

    const selectedCurrency = JSON.parse(storageCurrency);

    const convertedAmount = convertToSelectedCurrency(value, selectedCurrency.exchange_rate ?? 1);

    const formattedPrice = formatCurrency(convertedAmount, selectedCurrency.iso_code);

    return formattedPrice;
  };

  return { convertCurrency };
}
