import React from 'react';

interface IconProps {
  color?: string;
  height?: number;
  width?: number;
}

function CloseIcon({ color, height = 16, width = 16 }: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_49_10789)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.935 3.68122C12.8384 3.63543 12.7323 3.63347 12.6184 3.67538L12.5598 3.69697L10.6777 5.57454L8.79565 7.45212L6.91358 5.57454L5.03151 3.69697L4.96118 3.67154C4.87908 3.64185 4.77591 3.6388 4.69805 3.66378C4.61541 3.69028 4.5175 3.78131 4.47827 3.86808C4.4401 3.95251 4.43754 4.06557 4.47165 4.15985L4.49741 4.23107L6.37498 6.11314L8.25256 7.99521L6.37498 9.87727L4.49741 11.7593L4.47165 11.8306C4.35901 12.1419 4.64893 12.4318 4.96029 12.3192L5.03151 12.2934L6.91358 10.4159L8.79565 8.53829L10.6777 10.4159L12.5598 12.2934L12.631 12.3192C12.72 12.3514 12.8384 12.3506 12.9103 12.3174C12.9405 12.3034 12.9721 12.2916 12.9804 12.291C12.9997 12.2898 13.1155 12.1746 13.101 12.1712C13.095 12.1698 13.1021 12.1436 13.1167 12.1129C13.1512 12.0404 13.1524 11.9212 13.1196 11.8306L13.0939 11.7593L11.2163 9.87727L9.33873 7.99521L11.2163 6.11314L13.0939 4.23107L13.1196 4.15985C13.1534 4.06656 13.1512 3.95232 13.114 3.87071C13.08 3.79619 13.001 3.71254 12.935 3.68122ZM4.5596 3.75916C4.52463 3.79413 4.51182 3.80994 4.53113 3.79428C4.55045 3.77863 4.57906 3.75001 4.59472 3.73069C4.61038 3.71139 4.59457 3.72419 4.5596 3.75916Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_49_10789">
          <rect width="16" height="16" fill="white" transform="translate(0.799805)" />
        </clipPath>
      </defs>
    </svg>
  );
}

CloseIcon.defaultProps = {
  color: '#22272A',
  height: 16,
  width: 16,
};

export default CloseIcon;
