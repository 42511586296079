import validator from 'validator';

import DOMPurify from 'dompurify';

/**
 * Sanitize user input by applying a series of cleaning and security measures.
 *
 * This function performs the following operations on the input string:
 * 1. **Trim whitespace**: Removes leading and trailing spaces.
 * 2. **Remove control characters**: Excludes non-printable ASCII characters (0x00-0x1F).
 * 3. **Whitelist allowed characters**: Only allows alphanumeric characters,
 * spaces, and selected punctuation marks (`',._:;-`).
 * 4. **Normalize spaces**: Replaces multiple consecutive spaces with a single space.
 * 5. **Character limit**: Truncates the input to a maximum length of 100 characters.
 * 6. **XSS Protection**: Uses DOMPurify to sanitize the final output,
 * ensuring protection against Cross-Site Scripting (XSS) attacks.
 *
 * @param {string} input - The input string to be sanitized.
 * @returns {string} - The sanitized and safe input string.
 *
 * @example
 * sanitizeInput("  Hello World!  ");
 * // Returns: "Hello World"
 *
 * @example
 * sanitizeInput("<script>alert('XSS')</script>");
 * // Returns: ""
 *
 * @example
 * sanitizeInput("  Invalid  characters #$@!  ");
 * // Returns: "Invalid characters"
 *
 * @note
 * - If the input is empty or falsy, an empty string is returned.
 * - The function ensures that only safe characters are allowed,
 * making it suitable for form inputs and user-generated content.
 * - HTML tags and scripts are stripped using DOMPurify to prevent XSS attacks.
 *
 * @dependencies
 * - `validator` library for string trimming, blacklisting, and whitelisting.
 * - `DOMPurify` library for secure HTML sanitization.
 */
const sanitizeInput = (input: string) => {
  if (!input) return '';

  let sanitized = validator.trim(input); // Trim whitespace
  sanitized = validator.blacklist(sanitized, '\x00-\x1F'); // Remove control characters
  sanitized = validator.whitelist(sanitized, "a-zA-Z0-9',._:;-\\s"); // Allow safe characters
  sanitized = sanitized.replace(/\s+/g, ' '); // Normalize spaces
  sanitized = sanitized.slice(0, 100); // Limit to 100 characters

  return DOMPurify.sanitize(sanitized); // Final XSS-safe sanitization
};

const validateInput = (input: string) => {
  if (!input) return false; // Reject empty input

  if (input.length > 100) return false; // Reject overly long input

  // Reject if it contains only special characters or spaces
  if (/^[^a-zA-Z0-9]+$/g.test(input)) return false;

  return true;
};

export { sanitizeInput, validateInput };
