// TODO: deprecate function call in favor of environment object.
const isDevEnvironment = (): boolean =>
  process && process.env.NEXT_PUBLIC_NEXT_ENV === 'development';
const isNotDevEnvironment = (): boolean =>
  process && process.env.NEXT_PUBLIC_NEXT_ENV !== 'development';

const environment = {
  isDevelopment: process && process.env.NEXT_PUBLIC_NEXT_ENV === 'development',
  isProduction: process && process.env.NEXT_PUBLIC_NEXT_ENV === 'production',
  isStaging: process && process.env.NEXT_PUBLIC_NEXT_ENV === 'staging',
};

const GetHostName = () => {
  if (environment.isProduction) {
    return 'https://www.resortpass.com';
  }
  if (environment.isStaging) {
    return 'https://staging-web.resortpass.com';
  }
  return 'http://localhost:8000';
};

const GetCanonicalURL = (path: string) => {
  const nextjsJSONAPI = /\/_next\/data\/[^/]+\/(.+).json(.*)/;
  const base = GetHostName();

  let cleanPath = path;

  if (nextjsJSONAPI.test(path)) {
    cleanPath = path.replace(nextjsJSONAPI, '/$1$2');
  }

  const url = new URL(cleanPath, base);
  url.hash = '';
  url.search = '';
  return url.toString();
};

export { environment, GetCanonicalURL, GetHostName, isDevEnvironment, isNotDevEnvironment };
