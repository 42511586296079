/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState } from 'react';
import StarRating from '@components/common/StarRating';
// @ts-ignore
import jsonp from 'jsonp';

type Props = {
  isFooter?: boolean;
};

export default function AppleAppStoreRating({ isFooter = false }: Props) {
  const [appStoreRating, setAppStoreRating] = useState<Number>(0);
  const [numberOfAppStoreReviews, setNumberOfAppStoreReviews] = useState<number>(0);

  useEffect(() => {
    jsonp('https://itunes.apple.com/lookup?id=1557539492', null, (error: any, data: any) => {
      const { results } = data;
      const { averageUserRating, userRatingCount } = results[0];
      setAppStoreRating(averageUserRating);
      setNumberOfAppStoreReviews(userRatingCount);
    });
  }, []);

  const kFormatter = (number: number) =>
    Math.abs(number) > 999
      ? `${Math.sign(number) * +(Math.abs(number) / 1000).toFixed(1)}K`
      : Math.sign(number) * Math.abs(number);

  return isFooter && appStoreRating ? (
    <>
      <h1 className="font-rp-pn-semi-bold text-4xl mb-1">{appStoreRating.toFixed(1)}</h1>

      <StarRating rating={appStoreRating.toFixed(0)} size="4" />
      <div className="flex flex-row text-rp-primary-black-medium opacity-70 text-13 whitespace-nowrap mb-4 mt-1.5 d:mb-5">
        <p>Out of 5</p>
        {numberOfAppStoreReviews && <p className="-mt-2.5 text-xl mx-1">.</p>}
        {numberOfAppStoreReviews && <p>{`${kFormatter(numberOfAppStoreReviews)} Ratings`}</p>}
      </div>
    </>
  ) : (
    appStoreRating && (
      <div className="flex flex-row pl-3 d:pl-0 items-center">
        <p className="font-rp-pn-semi-bold text-5xl mt-2">{appStoreRating.toFixed(1)}</p>
        <div className="flex flex-col ml-6 d:ml-10">
          <StarRating rating={appStoreRating.toFixed(0)} size="5" />
          <div className="flex flex-row text-rp-primary-black-medium text-13 whitespace-nowrap d:text-15 d:mt-1">
            <p>Out of 5</p>
            {numberOfAppStoreReviews && <p className="-mt-2.5 text-xl mx-1">.</p>}
            {numberOfAppStoreReviews && (
              <p>
                {`${numberOfAppStoreReviews.toString()[0]}.${
                  numberOfAppStoreReviews.toString()[1]
                }K Ratings`}
              </p>
            )}
          </div>
        </div>
      </div>
    )
  );
}
