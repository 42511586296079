/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import useIsMobile from '@hooks/useIsMobile';
import SearchBarMobile from '@components/SearchBar/Mobile/SearchBarMobile';
import SearchBar from '@components/SearchBar/SearchBar';
import ImageLoaded from '@components/common/ImageLoaded/ImageLoaded';

type Props = {
  isStickyHeaderVisible: boolean;
  hideVideo?: boolean;
  showBottomDrawer: boolean;
  setShowBottomDrawer: React.Dispatch<React.SetStateAction<boolean>>;
};

const headerText = {
  main: 'Upgrade your day at a pool or spa',
  sub: 'Experience the world’s best hotels for the day, no overnight stay required.',
};
export default function HomePageVideo({
  isStickyHeaderVisible,
  hideVideo = false,
  showBottomDrawer,
  setShowBottomDrawer,
}: Props) {
  const isMobile = useIsMobile();

  return (
    <>
      {!hideVideo && (
        <div className="relative">
          <div className="block d:hidden" style={{ width: '100%', height: '680px' }}>
            <ImageLoaded
              url="https://static-assets.resortpass.com/assets/home-v2/still_image_mobile.jpg"
              classes="h-full"
              objectFit="cover"
              layout="fill"
              alt="ResortPass hero"
            />
          </div>
          <div className="hidden d:block" style={{ width: '100%', height: '800px' }}>
            <ImageLoaded
              url="https://static-assets.resortpass.com/assets/home-v2/still_image_desktop.jpg"
              classes="w-full"
              objectFit="cover"
              layout="fill"
              alt="ResortPass hero"
            />
          </div>
          <div
            className="absolute flex items-end mx-auto w-full top-0"
            style={{ height: `${isMobile ? '715px' : '800px'}` }}
          >
            <div className="flex flex-col w-full z-250">
              <div className="mb-24 px-5 mx-auto w-full d:pl-0 d:pr-5 d:max-w-1100">
                <div id="search-results" />
                <p className="text-40px font-rp-gelica-light text-white leading-tight d:leading-normal d:text-64px">
                  {headerText.main}
                </p>
                <p className="text-white mb-1 font-rp-pn-semi-bold text-lg whitespace-pre-wrap mt-0 d:mb-10 d:mt-3 d:text-2xl">
                  {headerText.sub}
                </p>

                <div className="mt-6">
                  {isMobile ? (
                    <SearchBarMobile
                      showBottomDrawer={showBottomDrawer}
                      setShowBottomDrawer={setShowBottomDrawer}
                    />
                  ) : (
                    <SearchBar isStickyHeaderVisible={isStickyHeaderVisible} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
