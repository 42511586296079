/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import styles from '../../styles/styles.module.css';

export type ButtonProps = {
  size: 'lg' | 'md' | 'sm';
  color: 'primary' | 'secondary' | 'transparent' | 'primarySRP';
  classes?: string;
  fullWidth?: boolean;
  type?: 'icon' | 'link' | 'button' | 'anchor';
  href?: string;
  onClick?: (e?: any) => void;
  children: React.ReactNode | string;
  disabled?: boolean;
  noPadding?: boolean;
  newTab?: boolean;
};

export default function Button({
  size,
  color,
  children,
  classes = undefined,
  fullWidth = false,
  type = 'button',
  href = '',
  onClick = () => null,
  disabled = false,
  noPadding = false,
  newTab = false,
}: ButtonProps) {
  const widthStyle = fullWidth ? 'w-full' : styles['w-fit'];
  const defaultPadding = 'py-2.5 px-4';
  const commonButtonStyles =
    'flex justify-center items-center rounded text-center font-rp-basetica';
  const sizeStyles = {
    lg: 'px-4 py-2.5 h-11 text-base leading-4',
    md: 'h-34px px-4 text-sm',
    sm: 'h-7 text-xs',
  };
  const typeStyles = {
    primary: 'bg-rp-primary hover:bg-rp-primary-light active:bg-rp-primary-dark text-rp-inverted',
    secondary:
      'bg-rp-inverted hover:bg-rp-inverted-hover active:bg-rp-inverted-pressed border border-rp-primary text-rp-primary-dark',
    transparent: 'bg-transparent text-solar-tertiary',
    primarySRP:
      'bg-solar-surface-brand-shadow hover:bg-solar-surface-brand-midnight text-solar-inverted-primary',
  };
  const disabledStyles =
    'bg-rp-button-disabled pointer-events-none cursor-not-allowed text-rp-text-disabled hover:bg-rp-button-disabled active:bg-rp-button-disabled';

  if (type === 'button') {
    return (
      <button
        type="button"
        className={`${commonButtonStyles} ${typeStyles[color]} ${sizeStyles[size]} ${widthStyle} ${
          disabled ? disabledStyles : ''
        } ${noPadding ? '' : defaultPadding} ${classes}`.trim()}
        disabled={disabled}
        onClick={!disabled ? onClick : undefined}
      >
        {children}
      </button>
    );
  }

  if (type === 'anchor') {
    return (
      <a
        href={href || undefined}
        onClick={onClick ? (e) => onClick(e) : undefined}
        className={`${commonButtonStyles} ${typeStyles[color]} ${sizeStyles[size]} ${widthStyle} ${
          disabled ? disabledStyles : ''
        } ${noPadding ? '' : defaultPadding} ${classes}`.trim()}
        target={newTab ? '_blank' : undefined}
        rel={newTab ? 'noreferrer' : undefined}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={
        !disabled
          ? () => {
              window.open(href!, type === 'link' ? '_blank' : '_self', 'noreferrer');
            }
          : undefined
      }
      className={`${commonButtonStyles} ${typeStyles[color]} ${sizeStyles[size]} ${widthStyle} ${
        disabled ? disabledStyles : ''
      } ${classes}`}
    >
      {children}
    </button>
  );
}
