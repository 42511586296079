import React, { useState, ReactNode, useMemo } from 'react';
import { TabsContext } from './TabsContext';
import TabList from './TabList';
import TabPanels from './TabPanels';
import TabPanel from './TabPanel';
import Tab from './Tab';

type TabsProps = {
  children: ReactNode;
  size?: 'sm' | 'md';
  defaultValue?: string;
  onSelect?: (value: string) => void;
};

export default function Tabs({ children, size = 'sm', defaultValue, onSelect }: TabsProps) {
  const [activeTab, setActiveTab] = useState<string>(defaultValue || '');

  const contextValue = useMemo(
    () => ({
      activeTab,
      setActiveTab,
      size,
      onSelect,
    }),
    [activeTab, setActiveTab, size, onSelect],
  );

  return <TabsContext.Provider value={contextValue}>{children}</TabsContext.Provider>;
}

Tabs.Tab = Tab;
Tabs.List = TabList;
Tabs.Panel = TabPanel;
Tabs.Panels = TabPanels;
