/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from 'react';
import NextImage, { StaticImageData } from 'next/image';

type SSRImageProps = {
  url: string | Array<string> | StaticImageData;
  alt: string;
  [x: string]: any;
  classes?: string;
  priority?: boolean;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  objectPosition?: string;
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive';
};

export default function ImageLoaded({
  url,
  alt,
  classes = '',
  objectFit = 'cover',
  objectPosition = 'center',
  layout = 'fill',
  priority = false,
  ...rest
}: SSRImageProps) {
  const [loadedImage, setLoadedImage] = useState<string | StaticImageData>();

  useEffect(() => {
    const loadImage = async () => {
      if (typeof url === 'string' || url instanceof Array) {
        const urls = typeof url === 'string' ? [url] : url;
        // Load the first available image
        const imagePromises = urls.map(
          (imgUrl: string) =>
            new Promise<string>((resolve) => {
              const image = new Image();
              image.src = imgUrl;
              image.onload = () => resolve(imgUrl);
            }),
        );
        const res = await Promise.any(imagePromises);
        setLoadedImage(res);
      } else {
        setLoadedImage(url);
      }
    };

    loadImage();
  }, [url]);

  // Server-side fallback for non-JS environments (SEO optimized)
  const fallbackUrl =
    typeof url === 'string'
      ? url
      : Array.isArray(url)
      ? url[0]
      : typeof url === 'object' && 'src' in url
      ? url.src // StaticImageData's URL
      : '';

  return (
    <>
      <noscript>
        <img
          src={fallbackUrl}
          alt={alt}
          className={`noscript ${classes}`}
          style={objectFit ? { objectFit } : undefined}
        />
      </noscript>

      {loadedImage ? (
        <NextImage
          src={loadedImage}
          alt={alt}
          className={classes}
          objectFit={objectFit}
          objectPosition={objectPosition}
          layout={layout}
          priority={priority}
          {...rest}
        />
      ) : null}
    </>
  );
}
