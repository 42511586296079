/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import { CartItemDetails } from '@customTypes/cart-items';
import HotelDetails from '@customTypes/hotel-details';
import { GuestGroupType } from '@customTypes/product-details';
import { HotSpotMin } from '@customTypes/hot-spot';

import { FAIR_PRICING_SERVICE_FEE_RATE, FAIR_PRICING_VARIANT } from '@constants/fairPricing';

import { has } from 'lodash';

import AVAILABILITY from '../constants/product';
import Product from '../types/product';

import showClosedForSeasonBanner from './showClosedForSeasonBanner';

const getPhotoUrlFromProduct = (product: Product) => {
  if (product.is_product_time_group && product.children?.length) {
    return product.children?.[0].photo_url;
  }

  return product.photo_url;
};

const numberOfAvailableUnits = (product: Product, cartData: CartItemDetails | undefined) => {
  const { product_id, adult_available_unit } = product;
  let count = adult_available_unit;
  if (cartData) {
    const cartItem = cartData.items.filter((item) => item.product_id === product_id);
    if (cartItem && cartItem.length > 0) {
      cartItem.forEach((item) => {
        if (item.individual) {
          count -= item.no_of_adults;
        } else {
          count -= 1;
        }
      });
    }
  }
  return count;
};

const isProductUnAvailable = (
  product: Product,
  cartData: CartItemDetails | undefined,
  hotelDetails?: HotelDetails | undefined,
  searchDate?: Date | undefined,
): boolean => {
  const availableUnits = numberOfAvailableUnits(product, cartData);
  if (
    hotelDetails &&
    hotelDetails.closed_for_season &&
    showClosedForSeasonBanner(hotelDetails.reopen_date, searchDate)
  ) {
    return true;
  }
  return (
    product.availability === AVAILABILITY.UNAVAILABLE ||
    availableUnits === 0 ||
    (product.adult_available_unit === 0 && product.child_available_unit === 0)
  );
};

const isAnyProductAvailable = (products: Product[]) => {
  for (let i = 0; i < products.length; i += 1) {
    if (!isProductUnAvailable(products[i], undefined)) {
      return true;
    }
  }

  return false;
};

const showAvailabilityWarning = (
  product: Product,
  cartData: CartItemDetails | undefined,
): boolean => {
  const availableUnits = numberOfAvailableUnits(product, cartData);
  return availableUnits > 0 && availableUnits < 5;
};

const calculateAddonsPrice = (addonItems: any) => {
  let addOnItemsPrice = 0;
  if (addonItems && addonItems.length > 0) {
    addOnItemsPrice = addonItems.reduce(
      (acc: number, item: { price: number; units: number; isChecked: boolean }) => {
        // Like on ProductSelector
        if (has(item, 'isChecked')) {
          if (item.isChecked === true) {
            return acc + item.price * item.units;
          }

          // If isChecked is false, do not add to the accumulator
          return acc;
        }

        // If isChecked is not present, like on the mini-cart (YourCart) add to the accumulator
        return acc + item.price * item.units;
      },
      0,
    );
  }

  return addOnItemsPrice;
};

const calculateTotalProductPrice = ({
  product,
  adultCount,
  childrenCount,
}: {
  product: Product;
  adultCount: number;
  childrenCount: number;
}) => {
  if (!product.individual_billing) {
    return adultCount * +product.adult_price;
  }

  return adultCount * +product.adult_price + childrenCount * +product.children_price;
};

const calculateFairPricingFee = (totalProductPrice: number) =>
  FAIR_PRICING_VARIANT === 'variant1'
    ? totalProductPrice * FAIR_PRICING_SERVICE_FEE_RATE
    : totalProductPrice;

const calculateHotSpotDiscount = ({
  totalProductPrice,
  applyHotSpot,
  hotSpot,
}: {
  totalProductPrice: number;
  applyHotSpot: boolean;
  hotSpot: HotSpotMin | undefined | null;
}) => {
  if (applyHotSpot && hotSpot) {
    return hotSpot.discount_type === 'cash'
      ? +totalProductPrice - hotSpot.discount
      : +totalProductPrice * (hotSpot.discount / 100);
  }

  return 0;
};

const getTotalForProductSelector = ({
  product,
  adultCount,
  childrenCount,
  addOnItems,
  hotSpot,
  applyHotSpot = false,
  applyFairPricing = true,
  guestsGroupBag,
}: {
  product: Product;
  adultCount: number;
  childrenCount: number;
  addOnItems: any;
  hotSpot: HotSpotMin | undefined;
  applyHotSpot: boolean;
  applyFairPricing: boolean;
  guestsGroupBag?: GuestGroupType[];
}) => {
  const adultCounter = product.individual_billing
    ? adultCount
    : guestsGroupBag
    ? guestsGroupBag?.length
    : adultCount;

  // Calculate prices
  const totalProductPrice = calculateTotalProductPrice({
    product,
    adultCount: adultCounter,
    childrenCount,
  });

  const fairPricingFee = applyFairPricing ? calculateFairPricingFee(totalProductPrice) : 0;

  const hotSpotDiscount = calculateHotSpotDiscount({ totalProductPrice, applyHotSpot, hotSpot });

  // Calculate add-ons price
  const baseAddOnsPrice = calculateAddonsPrice(addOnItems);
  const addOnItemsPrice =
    baseAddOnsPrice > 0 && applyFairPricing
      ? +baseAddOnsPrice + calculateFairPricingFee(baseAddOnsPrice)
      : baseAddOnsPrice;

  return Math.round(totalProductPrice + fairPricingFee - hotSpotDiscount + addOnItemsPrice);
};

const getHotSpotDiscountedPrice = (
  product: Product,
  personType: 'adult' | 'child',
  hotSpotObject: HotSpotMin,
) => {
  const price = personType === 'adult' ? product.adult_price : product.children_price;

  return hotSpotObject.discount_type === 'cash'
    ? +price - hotSpotObject.discount
    : +price * (1 - hotSpotObject.discount / 100);
};

const calculateTotalPrice = (
  product: Product,
  adultCounter: number,
  childrenCounter: number,
  addOnItems: any,
  guestsGroupBag?: GuestGroupType[],
  hotSpot?: HotSpotMin,
): number => {
  let addOnItemsPrice = 0;
  if (addOnItems && addOnItems.length > 0) {
    addOnItems.forEach((item: any) => {
      if (item.isChecked) {
        addOnItemsPrice += item.price * item.units;
      }
    });
  }

  const adultPrice =
    product.is_hot_spot && hotSpot
      ? getHotSpotDiscountedPrice(product, 'adult', hotSpot)
      : product.adult_price;

  const childrenPrice =
    product.is_hot_spot && hotSpot
      ? getHotSpotDiscountedPrice(product, 'child', hotSpot)
      : product.children_price;

  if (product.individual_billing) {
    return Math.round(
      adultCounter * +adultPrice + childrenCounter * +childrenPrice + addOnItemsPrice,
    );
  }

  const totalWithoutAddons = guestsGroupBag ? guestsGroupBag.length * +adultPrice : +adultPrice;

  return Math.round(totalWithoutAddons + addOnItemsPrice);
};

const areChildrenAllowed = (product: Product) =>
  product.allow_child || (!product.individual_billing && product.max_children_occupancy > 0);

const findProduct = (products: Product[], itemId: number): Product | null => {
  const foundProduct = products.find((product) => product.product_id === itemId);
  if (foundProduct) {
    return foundProduct;
  }
  let result = null;
  // eslint-disable-next-line no-restricted-syntax
  for (const product of products) {
    if (product.children) {
      const childResult: Product | null = findProduct(product.children, itemId);
      if (childResult) {
        result = childResult;
        break;
      }
    }
  }
  return result;
};

export {
  areChildrenAllowed,
  calculateAddonsPrice,
  calculateFairPricingFee,
  calculateHotSpotDiscount,
  calculateTotalPrice,
  findProduct,
  getPhotoUrlFromProduct,
  getTotalForProductSelector,
  isAnyProductAvailable,
  isProductUnAvailable,
  numberOfAvailableUnits,
  showAvailabilityWarning,
};
