/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

type Props = {
  children: React.ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  className?: string;
} & Omit<React.HTMLProps<HTMLParagraphElement>, 'size' | 'className'>;

const TEXT_STYLES = {
  xs: 'leading-3 text-xs',
  sm: 'leading-5 text-sm',
  md: 'leading-6 text-base', // default
  lg: 'leading-7 text-xl',
};

export default function Paragraph({ children, size = 'md', className = '', ...props }: Props) {
  return (
    <p
      className={`font-rp-basetica text-solar-primary ${TEXT_STYLES[size]} ${
        className || ''
      }`.trim()}
      {...props}
    >
      {children}
    </p>
  );
}
