/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-array-index-key */
import React from 'react';

/**
 * Returns an array of React nodes with matching text parts bolded.
 * @param text The full text to process (e.g. "Miami, Flor")
 * @param searchValue The search string (e.g. "miami flor")
 * @param idPrefix Optional prefix for React keys.
 */
export const getBoldedText = (text: string, searchValue: string): React.ReactNode[] => {
  // Basic sanitization: trim and convert to lowercase.
  const cleanSearchValue = searchValue.trim().toLowerCase();

  // If searchValue is empty, return the whole text in a span.
  if (!cleanSearchValue) {
    return [<span key={`${text}-default`}>{text}</span>];
  }

  // Split the search value into words and escape any regex special characters.
  const words = cleanSearchValue.split(/\s+/).filter(Boolean);
  const pattern = words.map((word) => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('[\\s,]+');

  // Create regex patterns (case-insensitive)
  const regex = new RegExp(`(${pattern})`, 'gi');
  const fullMatchRegex = new RegExp(`^${pattern}$`, 'i');

  // Split the text by the matching parts and map over the result.
  return text.split(regex).map((part, index) => (
    <span
      key={`${text}-${index}`}
      className={fullMatchRegex.test(part) ? 'font-rp-basetica-semibold' : 'font-rp-basetica'}
    >
      {part}
    </span>
  ));
};
