import React, { ReactNode } from 'react';
import { TabsContext } from './TabsContext';

type TabListProps = {
  children: ReactNode;
  alignment?: 'left' | 'spaceBetween' | 'flexed';
  className?: string;
};

const alignmentCssMap = {
  left: 'justify-start space-x-spacing-xl',
  spaceBetween: 'justify-between',
  flexed: 'justify-center',
};

export default function TabList({ alignment = 'flexed', children, className = '' }: TabListProps) {
  const context = React.useContext(TabsContext);

  if (!context) {
    throw new Error('TabList must be used within a Tabs component');
  }

  return (
    <div
      className={`border-b border-solar-secondary flex items-center w-full ${alignmentCssMap[alignment]} ${className}`.trim()}
    >
      {children}
    </div>
  );
}
