import React from 'react';
import List from '@solar/common/Lists/List';
import { ChevronLeftIcon } from '@resortpass/solar-icons';
import SelectSearchInput from '@components/SearchExperiment/SelectSearchInput';
import { Location } from '@components/Hotels/types';
import { getLocationTypeIcon } from '@helpers/location';
import { getBoldedText } from '@helpers/textUtils';

type Props = {
  value?: string;
  locations?: Location[];
  onInputClick?: () => void;
  onChange?: (term: string) => void;
  onSelect?: (location: Location) => void;
  onClear?: () => void;
  onClose?: () => void;
  userLocation?: Location | null;
};

export default function LocationsSearchDrawer({
  value = '',
  locations = [],
  onInputClick = () => {},
  onChange = () => {},
  onSelect = () => {},
  onClear = () => {},
  onClose = () => {},
  userLocation = null,
}: Props) {
  return (
    <div className="flex flex-col p-spacing-md w-full">
      <div className="border border-solar-secondary flex items-center px-spacing-sm py-10px rounded-solar-sm w-full">
        <button onClick={onClose} type="button" className="flex items-center flex-shrink-0">
          <ChevronLeftIcon size={24} />
        </button>

        <div className="ml-spacing-sm w-full">
          <SelectSearchInput
            searchTerm={value}
            onClick={onInputClick}
            onChange={onChange}
            onClearInput={onClear}
            isActive
          />
        </div>
      </div>

      <div className="h-dvh mt-spacing-sm overflow-y-auto pb-20 w-full">
        <ul className="flex flex-col">
          {locations.map((result) => (
            <List
              key={result.name}
              icon={getLocationTypeIcon(result, userLocation)}
              text={getBoldedText(result.name, value)}
              onClick={() => onSelect(result)}
              clickable
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
