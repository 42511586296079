/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-nested-ternary */
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import Currency from '../Currrency/Currency';
import StarRating from '../StarRating';
import styles from './CheckboxCustom.module.css';

type Props = {
  isChecked: boolean;
  setIsChecked: Dispatch<SetStateAction<boolean>> | Function;
  label: string | ReactElement;
  id: string;
  secondaryText?: string;
  isDarkBorder?: boolean;
  isInvalid?: boolean;
  showStarRating?: boolean;
  starRating?: string;
  isRPColor?: boolean;
};

const onlyShowAvailableText = 'Only show hotels with availability';
export default function CheckboxCustom({
  secondaryText = '',
  isDarkBorder = false,
  isInvalid = false,
  showStarRating = false,
  starRating = '0.0',
  isRPColor = false,
  isChecked,
  setIsChecked,
  label,
  id,
}: Props) {
  const toggleChecked = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
  };

  return (
    <div
      className={`relative w-full block ${
        isInvalid
          ? styles.checkboxCommonRed
          : isDarkBorder
          ? styles.checkboxCommonDark
          : styles.checkboxCommon
      } ${secondaryText ? styles.multiLine : ''}`}
    >
      <input type="checkbox" id={id} checked={isChecked} onChange={toggleChecked} />
      <label
        htmlFor={id}
        className={`-mb-1 inline-block leading-5 w-full ${
          label === onlyShowAvailableText ? styles.label : ''
        } ${secondaryText ? 'ml-12' : 'ml-0'}`}
      >
        {label}
        {showStarRating && (
          <div className="inline-block float-right mr-10">
            <StarRating rating={String(starRating)} size="5" isRPColor={isRPColor} />
          </div>
        )}
        {secondaryText && (
          <>
            <br />
            <p className="opacity-50 -mt-0.5">
              <Currency value={secondaryText} valueClasses="text-base" isoCodeClasses="text-base" />
            </p>
          </>
        )}
      </label>
    </div>
  );
}

export function CheckboxCustomV2({
  secondaryText = '',
  isDarkBorder = false,
  isInvalid = false,
  showStarRating = false,
  starRating = '0.0',
  isRPColor = false,
  isChecked,
  setIsChecked,
  label,
  id,
}: Props) {
  const toggleChecked = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
  };

  return (
    <div
      className={`relative w-full block ${
        isInvalid
          ? styles.checkboxCommonRedV2
          : isDarkBorder
          ? styles.checkboxCommonDarkV2
          : styles.checkboxCommonV2
      } ${secondaryText ? styles.multiLine : ''}`}
    >
      <input type="checkbox" id={id} checked={isChecked} onChange={toggleChecked} />
      <label
        htmlFor={id}
        className={`-mb-1 inline-block leading-5 w-full ${
          label === onlyShowAvailableText ? styles.label : ''
        } ${secondaryText ? 'ml-12' : 'ml-0'}`}
      >
        {label}
        {showStarRating && (
          <div className="inline-block float-right mr-10">
            <StarRating rating={String(starRating)} size="5" isRPColor={isRPColor} />
          </div>
        )}
        {secondaryText && (
          <>
            <br />
            <p className="opacity-50 -mt-0.5">
              <Currency value={secondaryText} valueClasses="text-base" isoCodeClasses="text-base" />
            </p>
          </>
        )}
      </label>
    </div>
  );
}
