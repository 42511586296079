import React from 'react';
import { marketingEmailSignupId } from '@constants/marketing';
import withPromoLogic, { PromoLogicProps } from '@components/CaptureEmail/withPromoLogic';

const promoConfig = {
  original: {
    main_text: 'Unlock access to exclusive savings',
    cta_label: 'Enter Email',
  },
  sale: {
    main_text: 'Unlock $50 off for Spring Break',
    cta_label: 'Get Code',
  },
};

function CaptureEmailFooter({ springSaleCampaign, handleClick }: PromoLogicProps) {
  return (
    <div className="bg-rp-primary flex flex-col items-center justify-center mt-10 py-5 px-8 space-y-3.5 w-full d:flex-row d:space-x-8 d:py-8 d:px-0 d:mt-0 d:space-y-0">
      <div className="-tracking-0.32 font-normal font-rp-basetica max-w-259px text-2xl text-center text-white d:space-x-8 d:space-y-0 d:text-32 d:text-justify d:max-w-full">
        {promoConfig[springSaleCampaign].main_text}
      </div>

      <button
        className={`bg-white h-12 rounded-md font-rp-pn-semi-bold text-rp-primary-black-medium text-base w-full -tracking-0.2 d:w-40 ${marketingEmailSignupId}`}
        type="button"
        onClick={handleClick}
      >
        {promoConfig[springSaleCampaign].cta_label}
      </button>
    </div>
  );
}

export default withPromoLogic(CaptureEmailFooter, 'CaptureEmailFooter');
