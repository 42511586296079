import { useState, useEffect, useCallback, useRef } from 'react';

export default function useDoOnce(callback: Function) {
  const [done, setDone] = useState(false);
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return useCallback(() => {
    if (done) return;
    setDone(true);
    callbackRef.current();
  }, [done]);
}
