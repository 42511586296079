/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
import HotelDetails from '@customTypes/hotel-details';
import { ProductDetails } from '@customTypes/product-details';
import PopularHotelData from '@customTypes/popular-hotel-data';
import DayPassesByLocationType from '@customTypes/day-passes-by-location';
import { SmartCalendarAvailability } from '@customTypes/smart-calendar';
import ReviewResponse from '@customTypes/reviews-response';
import { CartItemDetails } from '@customTypes/cart-items';
import HotelApi, {
  HotelApiProduct,
  HotSpotHotelApi,
  HotelApiAlgolia,
} from '@customTypes/hotel-api';
import { CheckoutInfo } from '@customTypes/checkout-info';
import { Bookings } from '@customTypes/bookings';
import ClientSecret from '@customTypes/client-secret';

import inventoryTypes from '@constants/inventoryTypes';

import UserDetails from '@customTypes/user-details';
import BookingData from '@customTypes/booking-data';
import { CurrencyData } from '@customTypes/currency';
import User from '@customTypes/user';
import BookingDetails, {
  AdultGuest,
  ChildGuest,
  BillingDetails,
} from '@customTypes/booking-details';
import NewFeedback from '@customTypes/new-feedback';
import { GuestInfo } from '@customTypes/guest-info';

import { Location } from '@components/Hotels/types';

import { AlgoliaInsightsPayload } from '@customTypes/algolia';
import { HotelApiResponseItem } from '@customTypes/hotel-api-response';
import { NextAvailableApiResponse } from '@customTypes/next-available-hotels';
import { NextDatesApiResponse } from '@customTypes/next-available-dates';
import { TopThreeReviewsApiResponse } from '@customTypes/seoCityPages';
import { GiftCardPaymentResponse } from '@customTypes/payments';
import { InvitedUser } from '@customTypes/invited-user';
import { SrpFilterOptions } from '@customTypes/srp-filters';
import { AuthData, OauthAuthData } from '@customTypes/auth';
import { SearchCategories } from '@customTypes/search';

import setJwtTokenHeader from '@helpers/JwtTokenHeaderFormatter';
import { getDateWithDashes } from '@helpers/date';
import { getInventoryGroupedByDate } from '@helpers/smartCalendar';

import getConfig from 'next/config';

import Axios from 'axios';

import AXIOS from './axios';

type AccessToken = string;

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const axiosShopApiInstance = Axios.create({
  baseURL: serverRuntimeConfig.shop_api_base_url || publicRuntimeConfig.shop_api_base_url,
});

const hotelsV4FieldMapping: { [key: string]: string } = {
  availability_relative_to_today: 'availability',
  city_id: 'cityId',
  avg_rating: 'avgRating',
  city_hotels_count: 'cityHotelsCount',
  city_name: 'cityName',
  city_sort_order: 'citySortOrder',
  closed_for_season: 'closedForSeason',
  created_at: 'createdAt',
  distance_text: 'distanceText',
  favorites_count: 'favoritesCount',
  image_desktop: 'imageDesktop',
  product_type_sort_order: 'productTypeSortOrder',
  reopen_date: 'reopenDate',
  reviews_count: 'reviewsCount',
  short_description: 'shortDescription',
  star_classification: 'hotelStar',
  country_name: 'country',
  state_name: 'state',
  allow_booking_before_days: 'allowBooking',
};

const hotelsV5FieldMapping: { [key: string]: string } = {
  ...hotelsV4FieldMapping,
  hotel_star: 'hotelStart',
  allow_booking: 'allowBooking',
  desktop_img: 'imageDesktop',
  reviews: 'reviewsCount',
  short_desc: 'shortDescription',
};

const transformHotelsData = (
  hotelsResponse: HotelApiResponseItem | HotelApiResponseItem[],
  keysToMap: { [key: string]: string },
): HotelApiResponseItem | HotelApiResponseItem[] => {
  if (Array.isArray(hotelsResponse)) {
    return hotelsResponse.map((response) => {
      const hotspotHotels = response.hot_spot_hotels || [];
      const hotSpotHotelIds = hotspotHotels.map((hotel) => hotel.hotel_id);
      return {
        ...response,
        hotels: response.hotels.map((hotel) => {
          const newKeys: any = {};
          Object.entries(hotel).forEach(([key, value]) => {
            if (keysToMap[key]) {
              newKeys[keysToMap[key]] = value;
              return;
            }
            newKeys[key] = value;
          });

          // TODO: what if there are two campaigns running at the same time?
          const hotSpot = hotspotHotels.find((h) => h.hotel_id === hotel.id);
          newKeys.hotSpot = hotSpotHotelIds.includes(hotel.id) && hotSpot?.active ? hotSpot : null;
          const hotSpotProductIds = hotSpot?.product_ids || [];
          newKeys.products = newKeys.products?.map((product: HotelApiProduct) => ({
            ...product,
            is_hot_spot: hotSpotProductIds.includes(product.id),
          }));
          return newKeys;
        }),
      };
    });
  }
  const hotspotHotels = hotelsResponse.hot_spot_hotels || [];
  const hotSpotHotelIds = hotspotHotels.map((hotel) => hotel.hotel_id);
  return {
    ...hotelsResponse,
    hotels: hotelsResponse.hotels.map((hotel) => {
      const newKeys: any = {};
      Object.entries(hotel).forEach(([key, value]) => {
        if (keysToMap[key]) {
          newKeys[keysToMap[key]] = value;
          return;
        }
        newKeys[key] = value;
      });
      const hotSpot = hotspotHotels.find((h) => h.hotel_id === hotel.id);
      newKeys.hotSpot = hotSpotHotelIds.includes(hotel.id) && hotSpot?.active ? hotSpot : null;
      const hotSpotProductIds = hotSpot?.product_ids || [];
      newKeys.products = newKeys.products?.map((product: HotelApiProduct) => ({
        ...product,
        is_hot_spot: hotSpotProductIds.includes(product.id),
      }));
      return newKeys;
    }),
  };
};

const getProductDetails = async (id: number, date?: string) => {
  const customAPIConfig = {
    baseURL: serverRuntimeConfig.shop_api_base_url || publicRuntimeConfig.shop_api_base_url,
    params: {},
  };

  // Only send date param if the date is present.
  if (date) {
    customAPIConfig.params = { ...customAPIConfig.params, date };
  }

  const productDetails: ProductDetails = await AXIOS.get(`hotels/${id}/products`, customAPIConfig);
  return productDetails;
};

const getHotelDetails = async (slug: string | string[]): Promise<HotelDetails> => {
  const encodedSlug = encodeURIComponent(slug.toString());
  const uri = `hotels/${encodedSlug}/details`;
  const response = await axiosShopApiInstance.get(uri);
  return response.data;
};

const getHotelDetailsV2 = async (slug: string | string[]): Promise<HotelDetails> => {
  const encodedSlug = encodeURIComponent(slug.toString());
  const response = await axiosShopApiInstance.get(`/v2/hotels/${encodedSlug}/details`);
  return response.data;
};

const getPopularHotels = async () => {
  const popularHotels: PopularHotelData = await AXIOS.get('hotels/top');
  return {
    hotels: popularHotels.hotels.map((hotel) => {
      const hotSpot = popularHotels.hot_spot_hotels.find((h) => h.hotel_id === hotel.id);
      return {
        ...hotel,
        hotSpot: hotSpot && hotSpot.active ? hotSpot : null,
      };
    }),
  };
};

const getDayPassesByLocation = async () => {
  const popularHotels: DayPassesByLocationType[] = await AXIOS.get('locations', {
    params: {
      by_country: true,
    },
  });
  return popularHotels;
};

const getSmartCalendarAvailability = async (
  hotelId: number,
  productId: string,
  startDate: string,
  endDate: string,
): Promise<SmartCalendarAvailability> => {
  const availability = await AXIOS.get(
    `smart_calendar/${hotelId}/product_inventories/${productId}`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  );
  return availability;
};

const getSmartCalendarTimeGroupAvailability = async (
  hotelId: number,
  productId: string,
  startDate: string,
  endDate: string,
): Promise<SmartCalendarAvailability> => {
  const availability = await AXIOS.get(
    `smart_calendar/${hotelId}/product_time_group_inventories/${productId}`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  );

  const response = getInventoryGroupedByDate(availability);

  return response;
};

const getReviews = async (hotelId: number, page: number, per_page: number = 10) => {
  const reviewsData: ReviewResponse = await AXIOS.get(`hotels/${hotelId}/reviews`, {
    params: {
      page,
      per_page,
    },
  });
  return reviewsData;
};

const getAllHotelReviews = async (page?: number | null) => {
  const reviews: ReviewResponse = await AXIOS.get('reviews', {
    params: {
      page,
    },
  });

  return reviews;
};

const getUserRegistered = async (email: string) => {
  const user = await AXIOS.get('oauth/user_registration', {
    params: {
      email,
    },
  });
  return user;
};

const createCartAndAddItems = async (data: any, user?: User) => {
  if (user && user?.token) {
    const headers = setJwtTokenHeader(user);
    const cartData: CartItemDetails = await AXIOS.postWithHeaders('carts', data, { headers });
    return cartData;
  }
  const cartData: CartItemDetails = await AXIOS.post('carts', data);
  return cartData;
};

const updateCart = async (cartId: string, data: any, user?: User) => {
  if (user && user?.token) {
    const headers = setJwtTokenHeader(user);
    const cartData: CartItemDetails = await AXIOS.postWithHeaders(`carts/${cartId}/update`, data, {
      headers,
    });
    return cartData;
  }
  const cartData: CartItemDetails = await AXIOS.post(`carts/${cartId}/update`, data);
  return cartData;
};

const getCartItems = async (cartId: string, user?: User) => {
  if (user && user?.token) {
    const headers = setJwtTokenHeader(user);
    const cartData: CartItemDetails = await AXIOS.get(`carts/${cartId}`, { headers });
    return cartData;
  }
  const cartData: CartItemDetails = await AXIOS.get(`carts/${cartId}`);
  return cartData;
};

const removeCartItem = async (cartId: string, itemId: string, user?: User) => {
  if (user && user?.token) {
    const headers = setJwtTokenHeader(user);
    const cartData: CartItemDetails = await AXIOS.delete(`carts/${cartId}/items/${itemId}`, {
      headers,
    });
    return cartData;
  }
  const cartData: CartItemDetails = await AXIOS.delete(`carts/${cartId}/items/${itemId}`);
  return cartData;
};

const deleteCart = async (cartId: string, user?: User) => {
  if (user && user?.token) {
    const headers = setJwtTokenHeader(user);
    const cartData: CartItemDetails = await AXIOS.delete(`carts/${cartId}`, { headers });
    return cartData;
  }
  const cartData: CartItemDetails = await AXIOS.delete(`carts/${cartId}`);
  return cartData;
};

const getLocations = async (queryString: string) => {
  const encodedUrl = encodeURIComponent(queryString);
  const locations: Location[] = await AXIOS.get(`typeahead?q=is:place name:${encodedUrl}&limit=20`);
  return locations;
};

const getHotelsV5 = async (queryString: string, data: any) => {
  const hotels: HotelApiResponseItem | HotelApiResponseItem[] = await AXIOS.postWithHeaders(
    `search/algolia_hotels_v5?q=${queryString}`,
    data,
    { withCredentials: true },
  );
  return transformHotelsData(hotels, hotelsV5FieldMapping);
};

type SearchV5QueryParams = {
  searchType?: 'state' | 'city' | 'country' | 'hotel';
  searchValue?: number | string;
  stage?: number;
  page?: number;
  limit?: number;
  offset?: number;
  showOnlyAvailableHotels?: boolean;
  date?: Date;
};

type NewGetHotelsV5Response = {
  hotels: HotelApi[];
  nextStage?: number;
  nextPage?: number;
  nextOffset?: number;
};

export const NewGetHotelsV5 = async (
  params: SearchV5QueryParams,
  filters: Array<Array<string>> = [],
): Promise<NewGetHotelsV5Response> => {
  // Define the type of search:
  // We can search by city, state, country, or a specific hotel
  const queryType = (() => {
    if (!(params.searchType && params.searchValue)) return '';
    const value = params.searchValue.toString();
    if (params.searchType === 'city') return `city_id:${value}`;
    if (params.searchType === 'state') return `state_code:${value}`;
    if (params.searchType === 'country') return `country_name:${value}`;
    return `name:${value}`;
  })();

  // Setup Parameters
  // Confluence on how "stages" in search works:
  // https://resortpass.atlassian.net/wiki/spaces/TECH/pages/1670971398/Algolia+v2+using+stages
  const stage = params.stage || 1;
  const pageNumber = params.page || 0;
  const limit = params.limit || 30;
  const offset = params.offset || 0;
  const showOnlyAvailableHotels = params.showOnlyAvailableHotels || false;
  const query: Array<string> = [
    `page=${pageNumber}`,
    `max_hits_per_page=${limit}`,
    `only_available=${showOnlyAvailableHotels}`,
    `stage=${stage}`,
  ];

  if (queryType) query.push(`q=${queryType}`);

  if (params.date) {
    query.push(`date=${getDateWithDashes(params.date)}`);
  }

  const payload = filters.length ? { category_filter: filters } : {};
  const queryString = query.join('&');

  // Get the search results
  const result = await getHotelsV5(queryString, payload);
  const response = Array.isArray(result) ? result : [result];
  const hotels: HotelApi[] = [];

  let nextPage = pageNumber;
  for (let i = 0; i < response.length; i += 1) {
    const numResultsToConsume = limit - hotels.length;

    if (i === 0) {
      hotels.push(...response[i].hotels.slice(offset, numResultsToConsume));
    } else {
      hotels.push(...response[i].hotels.slice(0, numResultsToConsume));
    }

    // we've reached our desired number of results
    if (hotels.length === limit) {
      // determine if the results included using hotels from the next stage
      if (response[i].stage !== stage) {
        nextPage = 0; // reset the next page request if we've moved to the next stage
      }

      // see if we've consumed all of the hotels in the page we're in
      if (response[i].hotels.length > numResultsToConsume) {
        // we haven't consumed all of the hotels,
        // so return the same stage and page number, but provide an offet into
        // the current page.
        return {
          hotels,
          nextPage,
          nextStage: response[i].stage,
          nextOffset: numResultsToConsume,
        };
      }
      // see if there is a next page we can request
      const totalPages = Math.ceil(response[i].total / limit);
      if (nextPage + 1 < totalPages) {
        return {
          hotels,
          nextStage: response[i].stage,
          nextPage: nextPage + 1,
          nextOffset: offset,
        };
      }
      // see if there is a next stage we can request
      if (i < response.length - 1) {
        return {
          hotels,
          nextStage: response[i + 1].stage,
          nextPage: 0,
          nextOffset: 0,
        };
      }
    }
  }

  // we have no more pages or stages to iterate through
  return {
    hotels,
  };
};

const getHotelsV6 = async (queryString: string, data: any) => {
  let searchQueryString = queryString;
  if (
    queryString.indexOf('city_id:') === 0 ||
    queryString.indexOf('state_code:') === 0 ||
    queryString.indexOf('country_name:') === 0 ||
    queryString.indexOf('name:') === 0
  ) {
    searchQueryString = `q=${queryString}`;
  }

  const hotels: HotelApiResponseItem | HotelApiResponseItem[] = await AXIOS.postWithHeaders(
    `search/algolia_hotels_v6?${searchQueryString}`,
    data,
    { withCredentials: true },
  );

  return transformHotelsData(hotels, hotelsV5FieldMapping);
};

type SearchV6QueryParams = {
  searchType?: 'state' | 'city' | 'country' | 'hotel' | 'implicit';
  searchValue?: number | string;
  stage?: number;
  page?: number;
  limit?: number;
  exclude?: number[];
  showOnlyAvailableHotels?: boolean;
  date?: Date;
  city?: string;
  state?: string;
  country?: string;
  aliasId?: number;
};

type NewGetHotelsV6Response = {
  hotels: HotelApiAlgolia[];
  hotSpotHotels: HotSpotHotelApi[];
  nextExclude?: number[];
  nextStage?: number;
  nextPage?: number;
};

export const NewGetHotelsV6 = async (
  params: SearchV6QueryParams,
  filters: Array<Array<string>> = [],
): Promise<NewGetHotelsV6Response> => {
  // Define the type of search:
  // We can search by city, state, country, or a specific hotel
  const queryType = (() => {
    if (!(params.searchType && params.searchValue)) return '';
    if (params.searchType === 'implicit') return '';
    const value = params.searchValue.toString();
    if (params.searchType === 'city') return `city_id:${value}`;
    if (params.searchType === 'state') return `state_code:${value}`;
    if (params.searchType === 'country') return `country_name:${value}`;
    return `name:${value}`;
  })();

  // Search City/State/Country implicitly via name instead of ID
  const implicitParams: Array<string> = (() => {
    if (params.searchType !== 'implicit') return [];
    const query: Array<string> = [];
    if (params.city) query.push(`city=${encodeURIComponent(params.city)}`);
    if (params.state) query.push(`state=${encodeURIComponent(params.state)}`);
    if (params.country) query.push(`country=${encodeURIComponent(params.country)}`);
    return query;
  })();

  // Setup Parameters
  // Confluence on how "stages" in search works:
  // https://resortpass.atlassian.net/wiki/spaces/TECH/pages/1670971398/Algolia+v2+using+stages
  const stage = params.stage || 1;
  const pageNumber = params.page || 0;
  const limit = params.limit || 30;
  const showOnlyAvailableHotels = params.showOnlyAvailableHotels || false;
  const exclude = params.exclude || [];
  const query: Array<string> = [
    ...(queryType ? [queryType] : []),
    ...implicitParams,
    ...(params.date ? [`date=${getDateWithDashes(params.date)}`] : []),
    ...(params.aliasId ? [`city_id=${params.aliasId}`] : []),
    `page=${pageNumber}`,
    `max_hits_per_page=${limit}`,
    `only_available=${showOnlyAvailableHotels}`,
    `stage=${stage}`,
  ];
  const payload = {
    ...(filters.length ? { category_filter: filters } : {}),
    ...(exclude.length ? { exclude_hotels_ids: exclude } : {}),
  };
  const response = await getHotelsV6(query.join('&'), payload);
  const results = Array.isArray(response) ? response : [response];
  const hotels: HotelApiAlgolia[] = [];
  const hotSpotHotels: HotSpotHotelApi[] = [];
  // Loop through the results and consume the hotels until we've reached the desired limit
  // or if we've exhausted all stages.
  for (let i = 0; i < results.length; i += 1) {
    // Skip over any stages that contain 0 result
    if (results[i].total !== 0) {
      // Consume the hotels from the current response
      const result = results[i] as HotelApiResponseItem;
      const numResultsToConsume = limit - hotels.length;
      const algoliaHotels = result.hotels.map<HotelApiAlgolia>((hotel) => ({
        ...hotel,
        queryID: result.queryID,
        indexName: result.indexName ?? '',
        isNearBy: result.stage === 3,
      }));

      hotels.push(...algoliaHotels.slice(0, numResultsToConsume));
      hotSpotHotels.push(...(result.hot_spot_hotels ?? []));

      // Checked if we've reached the limit
      if (hotels.length === limit) {
        // ---------------------------------------------------------------
        // Same Stage Results --------------------------------------------
        // ---------------------------------------------------------------
        // This occurs when the hotels returned from the call all belong
        // to the same stage.
        // ---------------------------------------------------------------
        if (result.stage === stage) {
          // see if there is another page of results
          const totalPages = Math.ceil(result.total / limit);
          if (totalPages > pageNumber + 1) {
            return {
              hotels,
              hotSpotHotels,
              nextStage: stage,
              nextPage: pageNumber + 1,
              nextExclude: exclude,
            };
          }

          // no additional pages in the current stage, check for next stages
          // (skip over empty result stages)

          for (let j = i + 1; j < results.length; j += 1) {
            if (results[j].total !== 0) {
              return {
                hotels,
                hotSpotHotels,
                nextStage: results[j].stage,
                nextPage: 0,
              };
            }
          }
        }

        // ---------------------------------------------------------------
        // Cross-Stage Results -------------------------------------------
        // ---------------------------------------------------------------
        // This occurs when we need to consume hotels from multiple stages
        // stages.
        // ---------------------------------------------------------------
        if (result.stage !== undefined && result.stage !== stage) {
          // check if we consumed all of the hotels on the page
          if (result.hotels.length > numResultsToConsume) {
            return {
              hotels,
              hotSpotHotels,
              nextStage: result.stage,
              nextPage: 0,
              // Optimization Per Tahseen: if we have not consumed all the hotels on the
              // current page, we need to include the hotel ids of the ones we've consumed
              // so that our backend knows to exclude them in the next request.
              nextExclude: result.hotels.slice(0, numResultsToConsume).map((h) => h.id),
            };
          }

          // check to see if there's another page
          const totalPages = Math.ceil(result.total / limit);
          if (totalPages > 1) {
            return {
              hotels,
              hotSpotHotels,
              nextStage: result.stage,
              nextPage: 1,
            };
          }

          // check to see if there's another stage to return
          // (skip 0 hotel stages)
          for (let j = i + 1; j < results.length; j += 1) {
            if (results[j].total !== 0) {
              return {
                hotels,
                hotSpotHotels,
                nextStage: results[j].stage,
                nextPage: 0,
              };
            }
          }
        }
      }
    }
  }
  return { hotels, hotSpotHotels };
};

export async function getNewAndNotableHotels() {
  // -------------------------------------------------------
  // New And Notable ---------------------------------------
  // -------------------------------------------------------
  // Hotels created in the last 90 days...
  const dates = (() => {
    const today = new Date();
    const pad = (n: number) => (n < 10 ? `0${n}` : n.toString());
    return Array(...Array(90))
      .map((_, index) => {
        if (index === 0) return today;
        return new Date(new Date().setDate(today.getDate() - index));
      })
      .map((d) => `created_at:'${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}'`);
  })();

  // Notable hotel must contain one of these product types:
  // Day Pass, Family Pass , Cabana, Daybed, Pool Chair, Spa Pass,
  // Spa Treatment, Massage, Facial, Nails, Couples Spa
  const productTypeIds = [1, 2, 3, 4, 7, 13, 50, 51, 52, 53, 55].map((i) => `product_type_id:${i}`);

  // find hotels with no ratings (new) or hotels with >= 4.5 (notable)
  const reviewScore = ['rating>=4.5', 'rating=0.0'];
  const hotelData = await NewGetHotelsV5({}, [
    dates,
    productTypeIds,
    reviewScore,
    ['closed_for_season:false'],
  ]);
  return hotelData.hotels;
}

const getHotels = async (queryString: string) => {
  const hotels: { hotels: HotelApi[]; queryID: string; total: number } = await AXIOS.get(
    `search/hotels?q=${queryString}`,
  );
  return hotels;
};

const getHotelsByCityId = async (id: number, limit: number, offset: number) => {
  const hotels: HotelApiResponseItem = await AXIOS.get(
    `search?q=city_id:${id}&limit=${limit}&offset=${offset}`,
  );
  return transformHotelsData(hotels, hotelsV4FieldMapping);
};
const deleteAddOn = async (cartId: string, addOnId: number, user?: User) => {
  if (user && user?.token) {
    const headers = setJwtTokenHeader(user);
    const cartData: CartItemDetails = await AXIOS.delete(`carts/${cartId}/addon_items/${addOnId}`, {
      headers,
    });
    return cartData;
  }
  const cartData: CartItemDetails = await AXIOS.delete(`carts/${cartId}/addon_items/${addOnId}`);
  return cartData;
};

const getCheckoutInfo = async (cartId: string, user: User | undefined) => {
  if (user && user?.token) {
    const headers = setJwtTokenHeader(user);
    const checkoutData: CheckoutInfo = await AXIOS.get(`carts/${cartId}/checkout_info`, {
      headers,
    });
    return checkoutData;
  }
  const checkoutData: CheckoutInfo = await AXIOS.get(`carts/${cartId}/checkout_info`);
  return checkoutData;
};

const applyCouponCode = async (cartId: string, data: any, user: User) => {
  if (user && user?.token) {
    const headers = setJwtTokenHeader(user);
    const checkoutData: CheckoutInfo = await AXIOS.postWithHeaders(
      `carts/${cartId}/apply_coupon`,
      data,
      { headers },
    );
    return checkoutData;
  }
  const checkoutData: CheckoutInfo = await AXIOS.post(`carts/${cartId}/apply_coupon`, data);
  return checkoutData;
};

const deleteCoupon = async (cartId: string, data: any, user: User) => {
  if (user && user?.token) {
    const headers = setJwtTokenHeader(user);
    const checkoutData: CheckoutInfo = await AXIOS.postWithHeaders(
      `carts/${cartId}/delete_coupon`,
      data,
      { headers },
    );
    return checkoutData;
  }
  const checkoutData: CheckoutInfo = await AXIOS.post(`carts/${cartId}/delete_coupon`, data);
  return checkoutData;
};

/**
 * @function getBookings gets bookings_history
 * @param type - the type of bookings to get
 * @param perPage - how many results to return in a single request
 * @param pageNumber - the pagination number to get
 * @param token - the logged in user's `Bearer JWT`
 * @returns Bookings
 */
const getBookings = async (
  type: string | string[],
  perPage: string,
  pageNumber: number,
  token: string,
): Promise<Bookings> => {
  const response: Promise<Bookings> = await AXIOS.get(
    `bookings?bookings_type=${type}&per_page=${perPage}&page=${pageNumber}`,
    {
      headers: {
        Jwt_Authorization: token,
      },
    },
  );
  return response;
};

const getBookingsV2 = async (
  type: string | string[],
  perPage: string,
  pageNumber: number,
  token: string,
): Promise<Bookings> => {
  const response = await axiosShopApiInstance.get(
    `users/bookings?bookings_type=${type}&per_page=${perPage}&page=${pageNumber}`,
    {
      headers: {
        jwt_authorization: token,
      },
    },
  );

  return response.data;
};

const createClientSecret = async (
  cartId: string,
  firstName: string,
  lastName: string,
  email: string | undefined,
  billingFormData: UserDetails,
  phoneNumber: string,
  receiveSpecialOffers: boolean,
  currencyCode: string,
  countryName: string,
  user: User,
  paymentMethodId?: string | null,
) => {
  const params = paymentMethodId ? { payment_method: paymentMethodId } : {};
  const data: any = {
    cart_id: cartId,
    inventory_type: inventoryTypes.RESORT_PASS,
    enable_gdpr: receiveSpecialOffers,
    billing_details: {
      first_name: billingFormData.first_name ? billingFormData.first_name : firstName,
      last_name: billingFormData.last_name ? billingFormData.last_name : lastName,
      zip: billingFormData.zip ?? '12345',
      phone: phoneNumber,
      country: countryName,
    },
    user_details: {
      first_name: firstName,
      last_name: lastName,
      email,
    },
    metadata: {
      agent: 'resortpass.www',
      currency_code: currencyCode,
      country_code: currencyCode === 'USD' ? 'US' : currencyCode,
    },
  };
  if (user && user?.token) {
    const headers = setJwtTokenHeader(user);
    const clientSecret: ClientSecret = await AXIOS.postWithHeaders('payments/for_booking', data, {
      params,
      headers,
    });
    return clientSecret;
  }
  const clientSecret: ClientSecret = await AXIOS.post('payments/for_booking', data);
  return clientSecret;
};

const createBooking = async (
  cartId: string,
  firstName: string,
  lastName: string,
  email: string | undefined,
  accountEmail: string | undefined,
  receiveSMS: boolean,
  receiveSpecialOffers: boolean,
  guestInfo: GuestInfo,
  clientSecret: ClientSecret | undefined,
  billingFormData: UserDetails,
  phoneNumber: string,
  currencyCode: string,
  countryName: string,
  user: User,
  rakutenCookie: string | undefined,
  utmParams: string | null,
  paymentMethodId?: string | null,
  deviceId?: string | null,
  amplitudeSessionId?: number | null,
) => {
  const params = paymentMethodId ? { payment_method: paymentMethodId } : {};
  const data: any = {
    cart_id: cartId,
    email: accountEmail,
    inventory_type: inventoryTypes.RESORT_PASS,
    enable_gdpr: receiveSpecialOffers,
    receive_sms: receiveSMS,
    user_details: {
      first_name: firstName,
      last_name: lastName,
      email: accountEmail ?? email,
    },
    billing_details: {
      first_name: billingFormData.first_name ? billingFormData.first_name : firstName,
      last_name: billingFormData.last_name ? billingFormData.last_name : lastName,
      zip: billingFormData.zip ? billingFormData.zip : '12345',
      country: countryName,
      phone: phoneNumber,
    },
    metadata: {
      payment_id: clientSecret?.payment_id,
      agent: 'resortpass.www',
      currency_code: currencyCode,
      country_code: currencyCode === 'USD' ? 'US' : currencyCode,
      utm_parameters: utmParams,
      device_id: deviceId ?? null,
      session_id: amplitudeSessionId?.toString() ?? null,
    },
    rakutenCookie: rakutenCookie || null,
  };
  if (guestInfo && guestInfo.length > 0) {
    data.guests_info = guestInfo;
  }
  if (user && user?.token) {
    const headers = setJwtTokenHeader(user);
    const bookingData: BookingData = await AXIOS.postWithHeaders('bookings', data, {
      params,
      headers,
      withCredentials: true,
    });
    return bookingData;
  }
  const bookingData: BookingData = await AXIOS.post('bookings', data);
  return bookingData;
};

class UnpaidBookingDetailsError extends Error {
  public data: BookingDetails;

  constructor(message: string, bookingDetails: BookingDetails) {
    super(message);
    this.data = bookingDetails;
  }
}

const getBookingDetails = async (
  bookingId: string | string[],
  accessId?: string | string[] | undefined,
  userToken?: User | string | undefined,
  checkForBookingStatus: boolean = false,
) => {
  const token = userToken
    ? typeof userToken === 'string'
      ? `Bearer ${userToken}`
      : `Bearer ${userToken.token}`
    : '';

  const headers = { JWT_Authorization: token };
  const params = accessId ? { access_id: accessId } : {};
  const bookingData: BookingDetails = await AXIOS.get(`bookings/${bookingId}/details`, {
    params,
    headers,
  });

  if (
    checkForBookingStatus &&
    ['completed', 'closed', 'cancelled'].indexOf(bookingData.booking_status) < 0
  ) {
    throw new UnpaidBookingDetailsError('Booking is Pending Payment', bookingData);
  }

  return bookingData;
};

const getYotpoLeaveReviewUrl = (productId: string, email: string, name: string) => {
  const appKey = process.env.NEXT_PUBLIC_YOTPO_APP_KEY;
  let baseUrl = process.env.NEXT_PUBLIC_YOTPO_LEAVE_REVIEW_URL;

  baseUrl = baseUrl!.replace('[[APP KEY]]', appKey!);
  baseUrl = baseUrl!.replace('[[EMAIL]]', email!);
  baseUrl = baseUrl!.replace('[[NAME]]', name!);
  baseUrl = baseUrl!.replace('[[PRODUCT ID]]', productId);

  return baseUrl;
};

const getCurrencyData = async (ip?: string) => {
  const ipQs = ip ? `?ip=${ip}` : '';
  const currencyData: CurrencyData = await AXIOS.get(`currency_data${ipQs}`, {
    headers: {
      Authorization: 'Bearer: UlVYaEFqSGJNZTc0ZFZKRFp1MlNZM04xb3JMY2xhcWlD',
    },
  });
  return currencyData;
};

const cancelBooking = async (
  id: string | string[] | number,
  user: User,
  refundMethod: string,
): Promise<{}> => {
  const token = user?.token && `Bearer ${user.token}`;
  const headers = { JWT_AUTHORIZATION: token };
  const data: object = { method: refundMethod };
  const res = await AXIOS.postWithHeaders(`bookings/resortpass/${id}/cancel`, data, { headers });
  return res;
};

const updateUser = async (user: User, fName: string | null, lName: string | null) => {
  const url = `users/${user?.id}/accounts_details`;
  const token = user?.token && `Bearer ${user.token}`;
  const headers = { JWT_AUTHORIZATION: token };
  const data: object = { first_name: fName, last_name: lName };
  const res = AXIOS.putWithHeaders(url, data, { headers });
  return res;
};

const getUpdatedUser = async (userToken: string, impersonationToken: string | undefined) => {
  const headers: { jwt_authorization: string; 'x-resortpass-impersonation-token'?: string } = {
    jwt_authorization: `Bearer ${userToken}`,
  };
  if (impersonationToken) {
    headers['x-resortpass-impersonation-token'] = `Bearer ${impersonationToken}`;
  }
  const res = await AXIOS.get('session', { headers });
  return res;
};

const updateBookingDetails = async (
  user: User | AccessToken,
  bookingId: number,
  billingDetails: BillingDetails,
  guestInfo: (AdultGuest | ChildGuest)[],
) => {
  const headers: { [key: string]: string } = {};
  const params: { [key: string]: string } = {};
  if (typeof user === 'string') {
    params.access_id = user;
  } else {
    const token = user?.token && `Bearer ${user.token}`;
    headers.JWT_AUTHORIZATION = token;
  }
  const formattedBillingDetails = {
    ...billingDetails,
    email: billingDetails.email || (typeof user !== 'string' && user.email) || '',
  };
  const data: object = { billing_details: formattedBillingDetails, guests_info: guestInfo };
  const res = await AXIOS.postWithHeaders(`bookings/${bookingId}/update`, data, {
    headers,
    params,
  });
  return res;
};

const changePassword = async (
  currentPassword: string,
  newPassword: string,
  confirmNewPassword: string,
  user: User,
) => {
  const token = user?.token && `Bearer ${user.token}`;
  const headers = { JWT_AUTHORIZATION: token };
  const data: object = {
    current_password: currentPassword,
    password: newPassword,
    password_confirmation: confirmNewPassword,
  };
  const res = await AXIOS.putWithHeaders('users/change_password', data, { headers });
  return res;
};

const lockCartInventory = async (cartId: string, user?: User) => {
  const token = user?.token && `Bearer ${user.token}`;
  const headers = token ? { JWT_AUTHORIZATION: token } : undefined;
  const api = `carts/${cartId}/lock`;
  const res = await AXIOS.postWithHeaders(api, null, { headers });
  return res;
};

const releaseCartInventory = async (cartId: string, user?: User) => {
  const token = user?.token && `Bearer ${user.token}`;
  const headers = token ? { JWT_AUTHORIZATION: token } : undefined;
  const api = `carts/${cartId}/release`;
  const res = await AXIOS.postWithHeaders(api, null, { headers });
  return res;
};

const trackAlgoliaEvent = async (data: AlgoliaInsightsPayload) => {
  const payload: object = {
    event_type: data.event_type,
    index_name: data.index_name,
    object_id: data.object_id,
    position: data.position,
    query_id: data.query_id,
    user_token: data.user_token,
  };

  const response = await AXIOS.post('insights/algolia', payload);
  return response;
};

const redeemGiftCard = async (redeemCode: string, user: User) => {
  const data = {
    redemption_code: redeemCode,
  };

  const headers = {
    JWT_AUTHORIZATION: user?.token && `Bearer ${user.token}`,
  };

  const response = await AXIOS.postWithHeaders('gift_cards/redeem ', data, {
    headers,
  });

  return response;
};

const createUserFromGuest = async (
  first_name: string,
  last_name: string,
  password: string,
  guest_access_id: string | string[],
): Promise<User> => {
  const payload: object = {
    first_name,
    last_name,
    password,
    guest_access_id,
  };
  const headers = { USER_AGENT: 'www' };
  const res = await AXIOS.postWithHeaders('users/complete_account_creation', payload, { headers });
  return res;
};

const createFeedback = async (bookingId: number, user: User, feedback: NewFeedback) => {
  const token = user?.token && `Bearer ${user.token}`;
  const headers = { JWT_AUTHORIZATION: token };
  const res = await AXIOS.postWithHeaders(`bookings/${bookingId}/feedback`, feedback, { headers });
  return res;
};

async function submitSurvey({
  accessToken,
  data,
  user,
}: {
  accessToken?: string;
  data: {
    bookingId: number;
    survey: { question: string; answer: string; other?: string }[];
    userId?: number;
  };
  user?: User;
}) {
  const headers = user ? { JWT_AUTHORIZATION: `Bearer ${user.token}` } : {};

  const response = await axiosShopApiInstance.post(
    `surveys${accessToken ? `?access_token=${accessToken}` : ''}`,
    data,
    {
      headers,
    },
  );

  return response;
}

const createEmailSubscription = async (email: string, searchTerm: string) => {
  const data: object = {
    email,
    search_term: searchTerm,
  };
  const res = await AXIOS.post('email_subscriptions', data);
  return res;
};

const getDeserializedToken = async (token: string | string[]) => {
  const res = await AXIOS.get(`feedback/token?token=${token}`);
  return res;
};

const createFeedbackViaToken = async (data: NewFeedback) => {
  const review: object = data;
  const res = await AXIOS.post('feedback/submit', review);
  return res;
};

const addFavorites = async (user: User, hotelID: string) => {
  try {
    const data = {
      body: [hotelID],
    };
    const headers = setJwtTokenHeader(user);
    const response = await axiosShopApiInstance.post('/users/favorites', data, { headers });
    return response;
  } catch (err) {
    return 'error';
  }
};

const removeFavorites = async (user: User, hotelID: number) => {
  try {
    const headers = setJwtTokenHeader(user);
    const response = await axiosShopApiInstance
      .delete(`/users/favorites/${hotelID}`, { headers })
      .then((res) => res.data);
    return response;
  } catch (err) {
    return 'error';
  }
};

const addFavoritesInBatch = async (user: User, hotelIDs: any) => {
  try {
    const data = {
      body: hotelIDs,
    };
    const headers = setJwtTokenHeader(user);
    const response = await axiosShopApiInstance.post('/users/favorites', data, { headers });
    return response;
  } catch (err) {
    return 'error';
  }
};

const accessHotelMetadataUsingHotelIds = async (
  hotelIds: number[] | string[],
  includeProducts = true,
) => {
  try {
    const data: any = {
      body: hotelIds,
    };
    const params: any = { includeProducts };
    const response = await axiosShopApiInstance
      .post('/hotels/metadata', data, { params })
      .then((res) => res.data);
    return response.result;
  } catch (err) {
    return 'error';
  }
};

const getSpaHotelsById = async (hotelIds: number[] | string[]) => {
  try {
    const data: any = {
      body: hotelIds,
    };
    const response = await axiosShopApiInstance.post('/hotels/metadata/spa', data);
    const hotels = response.data;
    return hotels.result;
  } catch (err) {
    return 'error';
  }
};

const accessAllFavorites = async (user: User, includeProducts?: boolean) => {
  try {
    const headers = setJwtTokenHeader(user);
    const response = await axiosShopApiInstance
      .get(`/users/favorites/metadata${includeProducts ? '?includeProducts=true' : ''}`, {
        headers,
      })
      .then((res) => res.data);
    return response.result;
  } catch (err) {
    return 'error';
  }
};

const shareBookingDetailsLoggedUser = async (
  bookingId: any,
  user: any,
  message: any,
  emails: any,
) => {
  try {
    const headers = setJwtTokenHeader(user);
    const params = { message, email: emails };
    const response: any = await AXIOS.postWithHeaders(
      `bookings/${bookingId}/share`,
      {},
      { params, headers },
    );
    return response?.booking_share_emails && response?.booking_share_emails.length > 0
      ? 'success'
      : 'error';
  } catch (err) {
    return 'error';
  }
};

const shareBookingDetailsGuestUser = async (
  bookingId: any,
  access_id: any,
  message: any,
  emails: any,
) => {
  try {
    const params = { message, email: emails, access_id };
    const response: any = await AXIOS.postWithHeaders(
      `bookings/${bookingId}/share`,
      {},
      { params },
    );
    return response?.booking_share_emails && response?.booking_share_emails.length > 0
      ? 'success'
      : 'error';
  } catch (err) {
    return 'error';
  }
};

const createGiftCard = async (payload: {}) => {
  const response = await AXIOS.post('gift_cards', payload);
  return response;
};

const processGiftCardPayment = async (
  giftCardId: string | string[] | undefined,
  billingDetails: any,
) => {
  const response: GiftCardPaymentResponse = await AXIOS.post(
    `/gift_cards/purchase/${giftCardId}`,
    billingDetails,
  );
  return response;
};

const getNextAvailableDates = async (
  productId: number,
  hotelId: number,
  offset: number,
  when: Date,
) => {
  const params = {
    product_id: productId,
    hotel_id: hotelId,
    offset,
    when: getDateWithDashes(when),
  };
  const response: NextDatesApiResponse = await axiosShopApiInstance
    .get('recommendations/next-available', { params })
    .then((res) => res.data);
  return response;
};

const getResponseWithUrl = async (url: string) => {
  const response = await axiosShopApiInstance.get(url).then((res) => res.data);
  return response;
};

const getNearbyHotels = async (productId: number, offset: number, when: Date) => {
  const params = {
    product_id: productId,
    offset,
    when: getDateWithDashes(when),
  };

  const response: NextAvailableApiResponse = await axiosShopApiInstance
    .get('recommendations/nearby-on', { params })
    .then((res) => res.data);

  return response;
};

const getSeoCityIdMetadata = async (id: string) => {
  try {
    const response: TopThreeReviewsApiResponse | any = await axiosShopApiInstance.get(
      `citypages/${id}`,
    );
    return response;
  } catch (err) {
    return 'error';
  }
};

const getFilterOptions = async () => {
  const response: SrpFilterOptions = await AXIOS.get('/search/hotel_amenities_filters_v1');
  return response;
};

const saveCreditCard = async (user: User, saveDetails: boolean) => {
  const url = `users/${user?.id}/cc_details`;
  const token = user?.token && `Bearer ${user.token}`;
  const headers = { JWT_AUTHORIZATION: token };
  const data: object = { save_cc_details: saveDetails };
  const res = AXIOS.putWithHeaders(url, data, { headers });
  return res;
};

const login = async (
  email: string,
  password: string,
  cartId: string | undefined,
): Promise<User> => {
  const headers = { USER_AGENT: 'www' };
  const data: AuthData = {
    user: {
      email,
      password,
    },
  };

  if (cartId) {
    data.metadata = { cart_id: cartId };
  }

  const res = await AXIOS.postWithHeaders('login', data, { headers, withCredentials: true });

  return res;
};

const signUp = async (
  email: string,
  password: string,
  passwordConfirmation: string,
  token?: string,
): Promise<User> => {
  const headers = { USER_AGENT: 'www' };
  const response = await AXIOS.postWithHeaders(
    'sign_up',
    {
      user: {
        email,
        password,
        password_confirmation: passwordConfirmation,
        token,
      },
    },
    { headers },
  );

  return response;
};

const signUpWithName = async (
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  passwordConfirmation: string,
  token?: string,
): Promise<User> => {
  const headers = { USER_AGENT: 'www' };
  const response = await AXIOS.postWithHeaders(
    'sign_up',
    {
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        password_confirmation: passwordConfirmation,
        token,
      },
    },
    { headers },
  );

  return response;
};

const oauthSignUp = async (userInformation: any, cartId?: string): Promise<User> => {
  const headers = {
    Authorization: 'Bearer cmVzb3J0cGFzc191c2VyOnIzdjEzd3B3ZA==',
  };
  const [firstName, lastName] = userInformation.name ? userInformation.name.split(' ') : '';
  const sessionExpires = new Date(userInformation.expires_at);
  const provider =
    userInformation.provider === 'google' ? 'google_oauth2' : userInformation.provider;
  const sessionToken =
    userInformation.provider === 'google' ? userInformation.id_token : userInformation.access_token;

  const refreshToken = userInformation.provider === 'apple' ? userInformation.refresh_token : null;

  const authData: OauthAuthData = {
    'omniauth.auth': {
      provider,
      uid: userInformation.sub,
      info: {
        email: userInformation.email,
        name: userInformation.name,
        first_name: firstName,
        last_name: lastName,
        image: userInformation.image,
      },
      credentials: {
        token: sessionToken,
        expires_at: sessionExpires.getTime(),
        expires: true,
        ...(refreshToken ? { refresh_token: refreshToken } : {}),
      },
    },
  };

  if (cartId) {
    authData.metadata = { cart_id: cartId };
  }

  const response = await AXIOS.postWithHeaders('oauth', authData, {
    headers,
    withCredentials: true,
  });

  return response;
};

async function getInvitedUserFromToken(token: string): Promise<InvitedUser> {
  const res: any = await axiosShopApiInstance.get(`shared_bookings?token=${token}`);

  return {
    email: res.data.email,
    firstName: res.data.first_name,
    lastName: res.data.last_name,
    active: res.data.active,
    provider: res.data.provider,
    bookingID: res.data.bookingid,
    token,
  };
}

/**
 * sendUserInvites
 *
 * Guest Growth Loop - For each guest, send an invitation to a booking with an optional
 * message.
 */
async function sendUserInvites(
  user: User | AccessToken,
  bookingId: number,
  emails: Array<string>,
  message = '',
) {
  const emailsPresent = emails.filter((e) => e.trim() !== '');
  const params = typeof user === 'string' ? { access_token: user } : {};
  const data = { emails: emailsPresent, message };
  const headers = typeof user === 'string' ? {} : setJwtTokenHeader(user);
  const response = await axiosShopApiInstance.post(`/shared_bookings/${bookingId}/invite`, data, {
    headers,
    params,
  });
  return response;
}

const getYotpoRatings = async (productId: number) => {
  const res = await Axios.get(
    `${process.env.NEXT_PUBLIC_YOTPO_RATINGS_API_URL}${process.env.NEXT_PUBLIC_YOTPO_APP_KEY}/product/${productId}/ratings`,
  );
  return {
    averageScore: res.data.bottomline.averageScore,
    totalReviews: res.data.bottomline.totalReviews,
  };
};

const sendEmailToIterable = async (email: string) =>
  axiosShopApiInstance.post('iterable/subscribe_user', { email });

async function getProductCategories(): Promise<SearchCategories> {
  const response = await axiosShopApiInstance.get('product_categories');

  return response.data;
}

// https://amplitude.com/docs/apis/experiment/experiment-management-api-flags#add-users-to-variant
async function excludeUsersFromAFlag({
  flagKey,
  flagVariant, // Name of the variant (ex: "on", "exclude"...)
  idsToExclude, // User ID or Device ID
}: {
  flagKey: string;
  flagVariant: string;
  idsToExclude: string[] | number[];
}) {
  return Axios.post(
    `https://experiment.amplitude.com/api/1/flags/${flagKey}/variants/${flagVariant}/users`,
    {
      inclusions: idsToExclude,
    },
    {
      headers: {
        Authorization: `Bearer ${process.env.AMPLITUDE_MANAGEMENT_API_KEY}`,
      },
    },
  );
}

export {
  accessAllFavorites,
  excludeUsersFromAFlag,
  accessHotelMetadataUsingHotelIds,
  addFavorites,
  addFavoritesInBatch,
  applyCouponCode,
  cancelBooking,
  changePassword,
  createBooking,
  createCartAndAddItems,
  createClientSecret,
  createEmailSubscription,
  createFeedback,
  createFeedbackViaToken,
  createGiftCard,
  createUserFromGuest,
  deleteAddOn,
  deleteCart,
  deleteCoupon,
  getAllHotelReviews,
  getBookingDetails,
  getBookings,
  getBookingsV2,
  getCartItems,
  getCheckoutInfo,
  getCurrencyData,
  getDayPassesByLocation,
  getDeserializedToken,
  getFilterOptions,
  getHotelDetails,
  getHotelDetailsV2,
  getHotels,
  getHotelsByCityId,
  getHotelsV5,
  getInvitedUserFromToken,
  getLocations,
  getNearbyHotels,
  getNextAvailableDates,
  getPopularHotels,
  getProductCategories,
  getProductDetails,
  getResponseWithUrl,
  getReviews,
  getSeoCityIdMetadata,
  getSmartCalendarAvailability,
  getSmartCalendarTimeGroupAvailability,
  getSpaHotelsById,
  getUpdatedUser,
  getUserRegistered,
  getYotpoLeaveReviewUrl,
  getYotpoRatings,
  lockCartInventory,
  login,
  oauthSignUp,
  processGiftCardPayment,
  redeemGiftCard,
  releaseCartInventory,
  removeCartItem,
  removeFavorites,
  saveCreditCard,
  sendEmailToIterable,
  sendUserInvites,
  shareBookingDetailsGuestUser,
  shareBookingDetailsLoggedUser,
  signUp,
  signUpWithName,
  submitSurvey,
  trackAlgoliaEvent,
  updateBookingDetails,
  updateCart,
  updateUser,
};
