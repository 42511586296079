import React, { ReactNode, useCallback, useState, useRef, useEffect } from 'react';
import cn from '@helpers/className';
import { ChevronUpIcon } from '@resortpass/solar-icons';
import styles from './CollapsibleContainer.module.css';

type Props = {
  title: string;
  children: ReactNode;
  height: string;
  noBorder?: boolean;
};

export default function CollapsibleContainer({ title, height, children, noBorder = false }: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const toggleExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <div className="w-full mt-5 overflow-hidden">
      <div
        className="flex justify-between items-center pb-5 cursor-pointer"
        onClick={toggleExpanded}
        onKeyDown={toggleExpanded}
        role="button"
        tabIndex={0}
      >
        <p className="font-rp-pn-semi-bold text-19">{title}</p>

        <div className={`h-7 w-7 relative ${!isExpanded ? 'transform rotate-180 mr-3' : 'mr-2'}`}>
          <ChevronUpIcon />
        </div>
      </div>
      <div
        className={`w-full transform transition-all ease-in-out duration-300 text-base border-rp-gray-divider overflow-hidden ${
          styles.collapsibleContainer
        } ${noBorder ? 'border-b-0' : 'border-b'}`}
        style={
          isExpanded
            ? { height: `${height}`, overflow: 'hidden' }
            : { height: '0px', overflowY: 'hidden' }
        }
      >
        {children}
      </div>
    </div>
  );
}

type Props2 = {
  title: string;
  noBorder?: boolean;
  children: ReactNode;
  className?: string;
};

export function CollapsibleContainer2({ title, children, className, noBorder = false }: Props2) {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [initialRect, setInitialRect] = useState<DOMRect | null>(null);
  const toggleExpanded = () => setIsExpanded(!isExpanded);
  const contentContainer = useRef<HTMLDivElement>(null);
  const isNoBorder = !!noBorder;

  // This useEffect is used to get the initial height of the content container.
  // This is needed to animate the height of the content container when it is expanded or collapsed.
  // It also listens to the window resize event to recalculate the height of the content container.
  useEffect(() => {
    if (contentContainer.current) {
      setInitialRect(contentContainer.current.getBoundingClientRect());
    }

    let listener: (arg: UIEvent) => void | null;
    if (window) {
      listener = () => {
        if (contentContainer.current) {
          contentContainer.current.style.height = 'auto';
          setInitialRect(contentContainer.current.getBoundingClientRect());
        }
      };
      window.addEventListener('resize', listener);
    }

    return () => {
      if (listener) {
        window.removeEventListener('resize', listener);
      }
    };
  }, []);

  useEffect(() => {
    if (contentContainer.current && initialRect) {
      const style = isExpanded ? `${initialRect.height}px` : '0px';
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(() => {
          if (contentContainer.current) contentContainer.current.style.height = style;
        });
      } else {
        contentContainer.current.style.height = style;
      }
    }
  }, [isExpanded, initialRect]);

  return (
    <div
      className={cn({
        'border-b': !isNoBorder,
        'border-b-0': isNoBorder,
        ...(className ? { [className]: true } : {}),
      })}
    >
      <div
        className={cn({
          'flex justify-between items-center cursor-pointer mb-5': true,
        })}
        style={{
          ...(isExpanded ? {} : { marginBottom: '0px' }),
        }}
        onClick={toggleExpanded}
        onKeyDown={toggleExpanded}
        role="button"
        tabIndex={0}
      >
        <p className="font-rp-pn-semi-bold text-19">{title}</p>

        <div
          className={cn({
            relative: true,
            'transform rotate-180': !isExpanded,
          })}
        >
          <ChevronUpIcon />
        </div>
      </div>
      <div
        ref={contentContainer}
        className="w-full transform transition-all ease-in-out duration-300 text-base border-rp-gray-divider overflow-hidden"
      >
        {children}
      </div>
    </div>
  );
}
