import React, { useEffect, useState, useRef } from 'react';
import NextImage, { StaticImageData } from 'next/image';

type SSRImageProps = {
  url: string | StaticImageData;
  classes?: string;
  [x: string]: any;
};

export default function ImageLoaded({ url, classes, ...rest }: SSRImageProps) {
  const [loadedImage, setLoadedImage] = useState<HTMLImageElement | StaticImageData>();

  useEffect(() => {
    if (typeof url === 'string') {
      const image = new Image();
      image.src = url;
      image.onload = () => setLoadedImage(image);
    } else {
      setLoadedImage(url);
    }
  }, [url]);

  return loadedImage ? <NextImage src={loadedImage} className={classes} {...rest} /> : null;
}

ImageLoaded.defaultProps = {
  classes: '',
};

type ImageBlurProps = {
  src: string;
  blur: string; // src for the blurred image while the main src is loading
  scaleOnBlur?: boolean; // scale the blurred image so that we can crop out the white edges
  [k: string]: any;
};

export function ImageWithBlur({ src, blur, scaleOnBlur = true, ...rest }: ImageBlurProps) {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    const img = new Image();
    img.src = src;
    const listener = () => {
      if (imgRef.current) {
        imgRef.current.src = src;
        setLoaded(true);
      }
    };
    img.addEventListener('load', listener);
    return () => {
      img.removeEventListener('load', listener);
    };
  }, [src]);
  return (
    <img
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      src={blur}
      ref={imgRef}
      style={{
        ...(loaded
          ? {}
          : {
            filter: 'blur(20px)',
            ...(scaleOnBlur ? { transform: 'scale(1.1)' } : {}),
          }),
      }}
    />
  );
}
