import DrawerManager from '@solar/common/Drawer/DrawerManager';
import React, { createContext, useContext, useState, ReactNode, useMemo, useCallback } from 'react';

interface DrawerState {
  type: string;
  props: {
    showHeader?: boolean;
    title?: string;
    subTitle?: string;
    height?: string;
    [key: string]: any;
  };
  component?: React.ReactNode;
  componentKey?: string;
}

interface DrawerContextValue {
  openDrawerWithComponent: (component: React.ReactNode, key: string, props?: any) => void;
  openDrawer: (type: string, props?: any) => void;
  closeDrawer: () => void;
  drawerState: DrawerState | null;
  rerender: (key: string, component: React.ReactNode) => void;
}

const DrawerContext = createContext<DrawerContextValue | undefined>(undefined);

export function DrawerProvider({ children }: { children: ReactNode }) {
  const [drawerState, setDrawerState] = useState<DrawerState | null>(null);

  const openDrawer = useCallback((type: string, props: any = {}) => {
    setDrawerState({ type, props });
  }, []);

  const openDrawerWithComponent = useCallback(
    (component: React.ReactNode, key: string, props: any = {}) => {
      setDrawerState({ type: 'component', props, component, componentKey: key });
    },
    [],
  );

  const closeDrawer = useCallback(() => {
    setDrawerState(null);
  }, []);

  const rerender = useCallback(
    (key: string, component: React.ReactNode) => {
      setDrawerState((prevState) => {
        if (prevState && prevState.componentKey === key) {
          return {
            ...prevState,
            component,
          };
        }
        return prevState;
      });
    },
    [setDrawerState],
  );

  const value = useMemo(
    () => ({
      drawerState,
      openDrawer,
      closeDrawer,
      openDrawerWithComponent,
      rerender,
    }),
    [drawerState, openDrawer, closeDrawer, openDrawerWithComponent, rerender],
  );

  return (
    <DrawerContext.Provider value={value}>
      {children}

      {drawerState && <DrawerManager />}
    </DrawerContext.Provider>
  );
}

export const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }
  return context;
};
