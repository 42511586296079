import React from 'react';
import Paragraph from '@solar/common/Paragraph/Paragraph';
import { getFormattedDate } from '@helpers/date';
import { CalendarIcon } from '@resortpass/solar-icons';
import useIsMobile from '@hooks/useIsMobile';

type Props = {
  value?: string | Date;
  showCalendarIcon?: boolean;
  handleOnInputClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
};

export default function DatePickerControl({
  value,
  handleOnInputClick = () => {},
  showCalendarIcon = true,
}: Props) {
  const isMobile = useIsMobile({ isSolarSystem: true });

  return (
    <div
      onClick={handleOnInputClick}
      onKeyDown={handleOnInputClick}
      role="button"
      tabIndex={0}
      className="cursor-pointer flex flex-1 flex-shrink-0 items-center relative"
    >
      {showCalendarIcon && (
        <div className="mr-spacing-sm flex-shrink-0">
          <CalendarIcon />
        </div>
      )}

      {value !== undefined ? (
        <Paragraph size={isMobile ? 'sm' : 'md'} className="text-right">
          {getFormattedDate(value)}
        </Paragraph>
      ) : null}
    </div>
  );
}
