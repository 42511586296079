import { useRouter } from 'next/router';
import { useState, useEffect, useRef } from 'react';

function useIsStickyHeaderVisible() {
  const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false);
  const router = useRouter();
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const isFixed = getComputedStyle(document.body).position === 'fixed';
      const currentScrollY = window.pageYOffset;
      if (isFixed) {
        setIsStickyHeaderVisible(lastScrollY.current > 760);
      } else {
        lastScrollY.current = currentScrollY;
        setIsStickyHeaderVisible(currentScrollY > 760);
      }
    };

    const checkStickyHeader = () => {
      if (router.pathname === '/') {
        // Initially check if we're already scrolled past the threshold
        setIsStickyHeaderVisible(window.pageYOffset > 760);
        window.addEventListener('scroll', handleScroll);
      } else {
        // For other paths, always show sticky header
        setIsStickyHeaderVisible(true);
      }
    };

    // Call the function to set initial state based on current pathname
    checkStickyHeader();

    const handleRouteChange = () => {
      checkStickyHeader();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    // Clean up event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.pathname, router.events]);

  return isStickyHeaderVisible;
}

export default useIsStickyHeaderVisible;
