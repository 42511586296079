import React from 'react';
import { marketingEmailSignupId } from '@constants/marketing';
import withPromoLogic, { PromoLogicProps } from './withPromoLogic';

const promoConfig = {
  original: 'Unlock Savings',
  sale: 'Get $50 Off',
};

function CaptureEmailNavMenuItem({ springSaleCampaign, handleClick }: PromoLogicProps) {
  return (
    <button
      className={`text-xl text-left font-rp-pn-semi-bold cursor-pointer hover:underline bg-savings-gradient text-transparent bg-clip-text ${marketingEmailSignupId}`}
      type="button"
      onClick={handleClick}
    >
      {promoConfig[springSaleCampaign]}
    </button>
  );
}

export default withPromoLogic(CaptureEmailNavMenuItem, 'CaptureEmailNavMenuItem');
