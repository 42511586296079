import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import closeIcon from '@assets/images/close-black.svg';
import closeIconLight from '@assets/images/close-light.svg';
import { CheckboxCustomV2 as CheckboxCustom } from '@components/common/CheckboxCustom/CheckboxCustom';
import { CollapsibleContainer2 as CollapsibleContainer } from '@components/common/CollapsibleContainer/CollapsibleContainer';
import ButtonPrimary from '@components/common/ButtonPrimary';
import { SearchState } from '@customTypes/search';
import AmenityToImageNameMap from '@constants/amenityImageMap';
import { getStaticIconsUrl } from '@utils/cloudflare';
import cn from '@helpers/className';
import {
  AmenitiesFriendlyNames,
  HotelStarsFriendlyNames,
  GuestRatingFriendlyNames,
  VibesFriendlyNames,
  useSearchContext,
} from '@context/SearchContext2025';
import styles from './SRPFilters.module.css';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onChange?: (s: SearchState) => void;
  onApply?: (s: SearchState) => void;
};

export default function SRPFilters({
  isOpen = false,
  onClose = () => {},
  onChange = () => {},
  onApply = () => {},
}: Props) {
  const searchContext = useSearchContext();
  const {
    searchParams: { filters },
  } = searchContext;

  const [fireOnChange, setFireOnChange] = useState(false);
  useEffect(() => {
    if (fireOnChange) {
      onChange(searchContext.searchParams);
      setFireOnChange(false);
    }
  }, [fireOnChange, onChange, searchContext.searchParams]);

  const handleOnlyAvailable = (onlyAvailable: boolean) => {
    searchContext.setOnlyAvailable(onlyAvailable);
    setFireOnChange(true);
  };

  const handleClearHotelStars = () => {
    searchContext.setHotelStars({
      '=5': false,
      '>=4': false,
      '>=3': false,
    });
    setFireOnChange(true);
  };

  const getHotelStarsFilterSetter = (star: keyof SearchState['filters']['hotel_stars']) => () => {
    searchContext.setHotelStars({
      // clear hotel class rating filters
      ...{
        '=5': false,
        '>=4': false,
        '>=3': false,
      },
      // set selected hotel class rating filter
      ...{ [star]: !filters.hotel_stars[star] },
    });
    setFireOnChange(true);
  };

  const getAmenityFilterSetter = (amenity: keyof SearchState['filters']['amenities']) => () => {
    searchContext.setAmenities({
      [amenity]: !filters.amenities[amenity],
    });
    setFireOnChange(true);
  };

  const getVibeFilterSetter = (vibe: keyof SearchState['filters']['vibes']) => () => {
    searchContext.setVibes({
      [vibe]: !filters.vibes[vibe],
    });
    setFireOnChange(true);
  };

  const getGuestRatingFilterSetter =
    (rating: keyof SearchState['filters']['guest_ratings']) => () => {
      searchContext.setGuestRatings({
        [rating]: !filters.guest_ratings[rating],
      });
      setFireOnChange(true);
    };

  type Filter<T> = {
    label: string;
    value: T;
  };

  const AmenityFilters: Filter<keyof SearchState['filters']['amenities']>[] = Object.entries(
    AmenitiesFriendlyNames,
  ).map(([value, label]) => ({ label, value: value as keyof SearchState['filters']['amenities'] }));

  const GuestRatingFilters: Filter<keyof SearchState['filters']['guest_ratings']>[] =
    Object.entries(GuestRatingFriendlyNames).map(([value, label]) => ({
      label,
      value: value as keyof SearchState['filters']['guest_ratings'],
    }));
  const HotelStarFilters: Filter<keyof SearchState['filters']['hotel_stars']>[] = Object.entries(
    HotelStarsFriendlyNames,
  ).map(([value, label]) => ({
    label,
    value: value as keyof SearchState['filters']['hotel_stars'],
  }));

  const VibeFilters: Filter<keyof SearchState['filters']['vibes']>[] = Object.entries(
    VibesFriendlyNames,
  ).map(([value, label]) => ({ label, value: value as keyof SearchState['filters']['vibes'] }));
  // Applied filters
  // This is a list of filters that are currently applied to the search results.
  // Used to present the 'chips' that show the user what filters are currently applied
  // in the mobile view
  const appliedSRPFilters: Array<{ label: string; remove: () => void }> = [
    ...(filters.only_available
      ? [{ label: 'Available', remove: () => handleOnlyAvailable(false) }]
      : []),
    ...HotelStarFilters.filter((item) => filters.hotel_stars[item.value]).map((item) => ({
      label: item.label,
      remove: getHotelStarsFilterSetter(item.value),
    })),
    ...AmenityFilters.filter((item) => filters.amenities[item.value]).map((item) => ({
      label: item.label,
      remove: getAmenityFilterSetter(item.value),
    })),
    ...VibeFilters.filter((item) => filters.vibes[item.value]).map((item) => ({
      label: item.label,
      remove: getVibeFilterSetter(item.value),
    })),
    ...GuestRatingFilters.filter((item) => filters.guest_ratings[item.value]).map((item) => ({
      label: item.label,
      remove: getGuestRatingFilterSetter(item.value),
    })),
  ];

  const removeAllFilters = () => {
    searchContext.clearFilters();
    setFireOnChange(true);
  };

  return (
    <div className="w-full relative max-h-full h-screen overflow-y-auto desktop:h-full desktop:w-auto desktop:overflow-x-hidden">
      <div className="desktop:hidden fixed px-4 py-3 text-15 font-rp-pn-semi-bold w-full rounded-t-xl bg-white border-b border-rp-gray-divider z-100">
        <div className="text-center text-base text-rp-primary-black relative">
          <button type="button" className="opacity-60 absolute left-0" onClick={onClose}>
            <Image src={closeIcon} width={14} height={14} alt="close-filters" />
          </button>
          <span>Filters</span>
          <button
            type="button"
            onClick={removeAllFilters}
            className="text-rp-primary-dark font-rp-cera-medium tracking-tight cursor-pointer whitespace-nowrap absolute right-0"
          >
            Clear all
          </button>
        </div>
      </div>

      <div className="pb-30 mt-20 desktop:mt-7 desktop:pb-0">
        {appliedSRPFilters.length > 0 ? (
          <div className="desktop:hidden flex flex-wrap gap-3 border-b border-rp-gray-divider pb-5 px-4">
            {appliedSRPFilters.map((item, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.label}-${index}`}
                className="flex text-sm justify-end pl-5 pr-3 py-1.5 rounded-l-full rounded-r-full whitespace-nowrap bg-rp-light-background text-rp-primary-black"
              >
                <div className="mr-3">{item.label}</div>
                <div className="w-4 h-4.5 cursor-pointer px-3px">
                  <Image src={closeIconLight} alt="remove-item" onClick={item.remove} />
                </div>
              </div>
            ))}
          </div>
        ) : null}

        {/* -------------------------------------------------------------
         // Availability Filter -----------------------------------------
         // -------------------------------------------------------------
        */}
        <CollapsibleContainer
          className="py-5 px-4 desktop:px-0"
          title={`Availability${filters.only_available ? ' (1)' : ''}`}
        >
          <div className="mt-0.5">
            <CheckboxCustom
              label="Only show hotels with availability"
              isChecked={filters.only_available}
              setIsChecked={() => handleOnlyAvailable(!filters.only_available)}
              id="availability-check-box"
              isDarkBorder
            />
          </div>
        </CollapsibleContainer>

        {/* -------------------------------------------------------------
         // Hotel Class Filters -----------------------------------------
         // -------------------------------------------------------------
        */}
        <CollapsibleContainer className="py-5 px-4 desktop:px-0" title="Hotel Class">
          <div className="flex flex-row gap-2.5 text-sm overflow-x-auto pb-4 overflow-hidden desktop:overflow-x-hidden mt-0.5 desktop:flex-wrap desktop:pb-0 desktop:mt-0">
            <button
              className={`border border-solid px-5 py-2 rounded-l-full rounded-r-full whitespace-nowrap ${
                Object.entries(filters.hotel_stars).every(([, value]) => !value)
                  ? 'border-rp-primary bg-rp-primary-sky-blue text-rp-primary-dark'
                  : 'border-rp-gray-divider bg-none text-rp-primary-black'
              }`}
              type="button"
              onClick={handleClearHotelStars}
            >
              All
            </button>

            {HotelStarFilters.map((item) => (
              <button
                className={`border border-solid px-5 py-2 rounded-l-full rounded-r-full whitespace-nowrap ${
                  filters.hotel_stars[item.value]
                    ? 'border-rp-primary bg-rp-primary-sky-blue text-rp-primary-dark'
                    : 'border-rp-gray-divider bg-none text-rp-primary-black'
                }`}
                type="button"
                onClick={getHotelStarsFilterSetter(item.value)}
                key={item.label}
              >
                {item.label}
              </button>
            ))}
          </div>
        </CollapsibleContainer>

        {/* -------------------------------------------------------------
         // Amenities---------------------------------------------------
         // -------------------------------------------------------------
        */}

        <CollapsibleContainer
          className="py-5 px-4 desktop:px-0"
          title={(() => {
            const count = Object.values(filters.amenities).filter(Boolean).length;
            return `Amenities${count ? ` (${count})` : ''}`;
          })()}
        >
          <div className="flex flex-col overflow-hidden gap-5 mt-0.5 desktop:mt-0">
            {AmenityFilters.map((item) => (
              <div className="flex items-center" key={item.value}>
                <div className="relative h-4 w-4 mr-2">
                  <Image
                    src={getStaticIconsUrl(AmenityToImageNameMap[`${item.value}`])}
                    layout="fill"
                    alt="beach access"
                  />
                </div>
                <CheckboxCustom
                  label={item.label}
                  isChecked={filters.amenities[item.value]}
                  setIsChecked={getAmenityFilterSetter(item.value)}
                  id={item.label.toString()}
                  isDarkBorder
                />
              </div>
            ))}
          </div>
        </CollapsibleContainer>

        {/* -------------------------------------------------------------
         // Vibes ------------------------------------------------------
         // -------------------------------------------------------------
         */}

        <CollapsibleContainer
          className="py-5 px-4 desktop:px-0"
          title={(() => {
            const count = Object.values(filters.vibes).filter(Boolean).length;
            return `Vibes${count ? ` (${count})` : ''}`;
          })()}
        >
          <div className="flex flex-col overflow-hidden gap-5 mt-0.5 desktop:mt-0">
            {VibeFilters.map((item) => (
              <div className="flex items-center" key={item.value}>
                <CheckboxCustom
                  label={item.label}
                  isChecked={filters.vibes[item.value]}
                  setIsChecked={getVibeFilterSetter(item.value)}
                  id={item.label}
                  isDarkBorder
                />
              </div>
            ))}
          </div>
        </CollapsibleContainer>

        <CollapsibleContainer
          className="py-5 px-4 desktop:px-0"
          title={(() => {
            const count = Object.values(filters.guest_ratings).filter(Boolean).length;
            return `Guest Rating${count ? ` (${count})` : ''}`;
          })()}
          noBorder
        >
          <div className="flex flex-col overflow-hidden gap-5 mt-0.5 desktop:mt-0">
            {GuestRatingFilters.map((item) => (
              <div className="flex items-center" key={item.value}>
                <CheckboxCustom
                  label={item.label}
                  isChecked={filters.guest_ratings[item.value]}
                  setIsChecked={getGuestRatingFilterSetter(item.value)}
                  id={item.label}
                  isDarkBorder
                />
              </div>
            ))}
          </div>
        </CollapsibleContainer>

        <button
          type="button"
          onClick={removeAllFilters}
          className="hidden text-left text-base text-rp-primary font-rp-cera-medium tracking-tight cursor-pointer mt-1 underline desktop:block"
        >
          Clear all
        </button>
      </div>
      <div
        className={cn({
          'fixed bg-white w-full px-22px pb-6 pt-4 border-t border-rp-gray-divider desktop:hidden':
            true,
          [styles.footerOpen]: isOpen,
          [styles.footerClose]: !isOpen,
        })}
      >
        {/* Book now button */}
        <div className="h-12 desktop:mb-2">
          <ButtonPrimary onClick={() => onApply(searchContext.searchParams)}>
            Show Results
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
}
