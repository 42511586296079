import React, { ReactNode, useContext } from 'react';
import cn from '@helpers/className';
import Paragraph from '@solar/common/Paragraph/Paragraph';
import Link from 'next/link';
import { TabsContext } from './TabsContext';

type TabProps = {
  children: ReactNode;
  value: string;
  icon?: ReactNode;
  iconPosition?: 'left' | 'top';
  href?: string;
  className?: string;
};

function Tab({
  children,
  className = '',
  icon = undefined,
  iconPosition = undefined,
  value,
  href,
}: TabProps) {
  const context = useContext(TabsContext);

  if (!context) {
    throw new Error('Tab must be used within a Tabs component');
  }

  const { activeTab, setActiveTab, size, onSelect } = context;
  const isActive = activeTab === value;

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveTab(value);
    onSelect?.(value);
  };

  return (
    <Link href={href || '#'} passHref>
      <a
        data-testid={`tab-${value}`}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleClick(e as any);
          }
        }}
        aria-selected={isActive}
        role="tab"
        tabIndex={0}
        className={`${cn({
          'cursor-pointer hover:text-solar-alert-red hover:border-solar-surface-brand-shadow': true,
          'border-b border-solar-surface-brand-shadow -mb-px': isActive,
        })} ${className || ''}`.trim()}
      >
        <div
          className={cn({
            'flex items-center': true,
            'text-solar-primary': isActive,
            'text-solar-secondary': !isActive,
            'flex-col py-3 d:space-x-spacing-xs d:flex-row': iconPosition === 'left',
            'flex-col justify-center py-spacing-sm space-y-2px': iconPosition === 'top',
            'justify-center py-3': !icon,
          })}
        >
          {icon || null}

          <Paragraph
            size={size === 'md' ? 'sm' : 'xs'}
            className={cn({
              'text-solar-primary': isActive,
              'text-solar-secondary': !isActive,
            })}
          >
            {children}
          </Paragraph>
        </div>
      </a>
    </Link>
  );
}

export default Tab;
