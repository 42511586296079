type AmenityToImageNameMapType = {
  [key: string]: string;
};

const AmenityToImageNameMap: AmenityToImageNameMapType = {
  'airport-shuttle': 'airport_transfer.svg',
  'all-inclusive': 'all-inclusive.svg',
  beach: 'beach.svg',
  cabana: 'cabana.svg',
  drink: 'drink.svg',
  'fitness-center': 'gym.svg',
  food: 'food.svg',
  hottub: 'hottub.svg',
  lazyriver: 'lazyriver.svg',
  lockers: 'lockers.svg',
  lounger: 'lounger.svg',
  'luggage-storage': 'luggage_storage.svg',
  pool: 'pool.svg',
  restrooms: 'bathroom.svg',
  'rooftop-pool': 'rooftop_pool.svg',
  showers: 'shower.svg',
  spa: 'spa.svg',
  towels: 'towel.svg',
  waterslide: 'waterslide.svg',
  wheelchair: 'wheelchair.svg',
};

export default AmenityToImageNameMap;
