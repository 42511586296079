import { useState, useEffect } from 'react';

function useBannerMargin() {
  const [headerMarginTop, setHeaderMarginTop] = useState<string>('0px');

  useEffect(() => {
    const fixLayoutWithUnbounceStickyBar = (timerInterval?: number) => {
      const banner = document.getElementsByClassName('ub-emb-bar-frame')[0];
      if (banner) {
        const styles = getComputedStyle(banner);
        const bodyMarginTop = styles.height;
        setHeaderMarginTop(bodyMarginTop);

        const buttonClose = document.querySelectorAll('.ub-emb-bar-frame .ub-emb-close')[0];
        if (buttonClose) {
          buttonClose.addEventListener('click', () => setHeaderMarginTop('0px'));
        }
        if (timerInterval) clearInterval(timerInterval);
      }
    };

    const interval = window.setInterval(() => {
      fixLayoutWithUnbounceStickyBar(interval);
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return headerMarginTop;
}

export default useBannerMargin;
