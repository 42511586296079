import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import useScrollLock from '@hooks/useScrollLock';
import resortPassAnimation from '../../../assets/images/RP_LoadingAnimation.json';
import styles from './ResortPassAnimation.module.css';

export default function ResortPassAnimation() {
  const animation = useRef<any>();
  useScrollLock(true);

  useEffect(() => {
    const randomName = `${Math.floor(Math.random() * 10000)}_${new Date().getTime()}`;
    if (lottie) {
      lottie.loadAnimation({
        container: animation.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: resortPassAnimation,
        name: randomName,
      });
    }

    return () => {
      if (lottie) {
        lottie.destroy(randomName);
      }
    };
  }, []);

  return (
    <div className={`${styles.rpSpinner}`}>
      <div className={`${styles.lottie}`} ref={animation} />
    </div>
  );
}
