import React from 'react';
import SRPFilters from '@components/SearchExperiment/SRPFilters/SRPFilters';
import { SearchState } from '@customTypes/search';
import { useDrawer } from '@context/DrawerContext';
import { useAmplitudeApplyFilterTracker } from '@hooks/useAmplitudeTrackers';
import { useSearchContext } from '@context/SearchContext2025';

export default function FiltersDrawer() {
  const { closeDrawer } = useDrawer();
  const { search } = useSearchContext();

  const onSearch = () => {
    closeDrawer();
    search();
  };

  const applyFilterTracker = useAmplitudeApplyFilterTracker();
  const onFilterChange = (p: SearchState) => {
    applyFilterTracker(p);
  };

  return <SRPFilters onClose={closeDrawer} onApply={onSearch} onChange={onFilterChange} isOpen />;
}
