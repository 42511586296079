/* eslint-disable function-paren-newline */
import inventoryTypes from '@constants/inventoryTypes';
import sessionStorageKeys from '@constants/sessionStorageKeys';

import actionTypes from '@context/actionTypes';

import { AddonItems, CartItem, CartItemDetails } from '@customTypes/cart-items';
import { CartPayload } from '@customTypes/cart-payload';
import { CheckoutInfo, CheckoutItem } from '@customTypes/checkout-info';
import { UserAction } from '@customTypes/context';
import User from '@customTypes/user';

import useSessionStorage from '@hooks/useSessionStorage';

import {
  createCartAndAddItems,
  deleteAddOn,
  deleteCart,
  getCartItems,
  removeCartItem,
  updateCart,
} from '@utils/services';

import React from 'react';

import Product from '@customTypes/product';
import { getDateWithDashes, getDateWithSlashes } from './date';
import toggleErrorPopup from './toggleErrorPopup';

const { getItem, setItem, removeItem } = useSessionStorage();
const cartIdKey = sessionStorageKeys.CART_ID;
const cartDateKey = sessionStorageKeys.CART_DATE;
const cartNumberOfItemsKey = sessionStorageKeys.CART_NUMBER_OF_ITEMS;

const updateSessionKeys = (cartData: CartItemDetails, date: Date | undefined) => {
  setItem(cartIdKey, cartData.id);
  setItem(cartDateKey, date ? getDateWithSlashes(date) : '');
};

const updateCartItemsCount = (cartData: CartItemDetails, dispatch: React.Dispatch<UserAction>) => {
  let count = 0;
  cartData.items.forEach((item) => {
    if (item.individual) {
      count += item.no_of_adults + item.no_of_childs;
    } else {
      count += 1;
    }
    if (item.addon_items && item.addon_items.length) {
      item.addon_items.forEach((addOn) => {
        count += addOn.units;
      });
    }
  });
  setItem(cartNumberOfItemsKey, count.toString());
  dispatch({ type: actionTypes.UPDATE_NUMBER_OF_CART_ITEMS, payload: count });
  return count;
};

const createCartItems = async (
  data: CartPayload,
  date: Date | undefined,
  dispatch: React.Dispatch<UserAction>,
  user: User,
) => {
  const cartData = await createCartAndAddItems(data, user);
  updateSessionKeys(cartData, date);
  updateCartItemsCount(cartData, dispatch);
  return cartData;
};

const deleteCartAndRemoveKeys = (
  cartId: string,
  dispatch: React.Dispatch<UserAction>,
  user?: User,
) => {
  deleteCart(cartId, user);
  removeItem(cartIdKey);
  removeItem(cartDateKey);
  removeItem(cartNumberOfItemsKey);
  dispatch({ type: actionTypes.UPDATE_NUMBER_OF_CART_ITEMS, payload: 0 });
};

const updateCartItems = async (
  data: CartPayload,
  date: Date | undefined,
  dispatch: React.Dispatch<UserAction>,
  cartData?: CartItemDetails | undefined,
  productId?: number,
  user?: User,
) => {
  const cartId = getItem(cartIdKey);
  const existingItem = cartData?.items.find((item) => item.product_id === productId);
  if (existingItem && existingItem.individual) {
    const itemAdded = data.items.find((item) => item.product_id === productId);
    if (itemAdded) {
      itemAdded.id = existingItem.id;
      itemAdded.no_of_adults += existingItem.no_of_adults;
      itemAdded.no_of_childs += existingItem.no_of_childs;
    }
  }
  const updatedCartData = await updateCart(cartId, data, user);
  updateSessionKeys(updatedCartData, date);
  updateCartItemsCount(updatedCartData, dispatch);
  return updatedCartData;
};

const createOrUpdateCartItems = async (
  data: CartPayload,
  date: Date | undefined,
  cartData: CartItemDetails | undefined,
  productId: number,
  hotelId: number | undefined,
  dispatch: React.Dispatch<UserAction>,
  user: User,
) => {
  const cartId = getItem(cartIdKey);
  const cartDate = getItem(cartDateKey);
  // update cart only when date is same and hotel is same. create a new cart otherwise.
  if (
    cartId &&
    cartData &&
    hotelId &&
    date &&
    cartDate === getDateWithSlashes(date) &&
    cartData.items.length > 0 &&
    hotelId === cartData.items[0].hotel_id
  ) {
    const updatedCartData = await updateCartItems(data, date, dispatch, cartData, productId, user);
    return updatedCartData;
  }
  if (cartId) {
    deleteCartAndRemoveKeys(cartId, dispatch, user);
  }
  const updatedCartData = await createCartItems(data, date, dispatch, user);
  return updatedCartData;
};

const prepareJsonData = (
  cartData: CartItemDetails | undefined,
  item: CartItem,
  hotelId: number | undefined,
  productType: string,
  isRemoveAll: boolean,
) => {
  const jsonData: CartPayload = {
    items: [],
    inventory_type: inventoryTypes.RESORT_PASS,
  };
  if (cartData) {
    const cartDate = getItem(cartDateKey);
    cartData?.items.forEach((cartItem) => {
      const cartItemData = {
        product_id: cartItem.product_id,
        hotel_id: hotelId ?? 0,
        booked_date: cartDate ? getDateWithDashes(new Date(cartDate)) : '',
        no_of_adults: cartItem.no_of_adults,
        no_of_childs: cartItem.no_of_childs,
        no_of_infants: 0,
        id: cartItem.id,
      };
      if (cartItem.id === item.id) {
        if (productType === 'child' && cartItemData.no_of_childs > 0) {
          if (isRemoveAll) {
            cartItemData.no_of_childs = 0;
          } else {
            cartItemData.no_of_childs -= 1;
          }
        } else if (cartItem.no_of_adults > 0) {
          if (isRemoveAll) {
            cartItemData.no_of_adults = 0;
          } else {
            cartItemData.no_of_adults -= 1;
          }
        }
      }
      jsonData.items.push(cartItemData);
    });
  }
  return jsonData;
};

const removeSingleCartItem = async (
  cartId: string,
  item: CartItem | CheckoutItem,
  dispatch: React.Dispatch<UserAction>,
  setIsSpinnerLoading: React.Dispatch<React.SetStateAction<boolean>>,
  productType: string | null,
  hotelId: number | undefined,
  cartData?: CartItemDetails | undefined,
  user?: User,
  isRemoveAll: boolean = false,
) => {
  try {
    setIsSpinnerLoading(true);
    const cartDate = getItem(cartDateKey);
    let cartItem = item as CartItem | undefined;
    // remove all items when non-individual billing
    if (
      !productType ||
      (productType &&
        productType === 'adult' &&
        item.no_of_adults === 1 &&
        item.no_of_childs === 0) ||
      (productType && productType === 'adult' && isRemoveAll)
    ) {
      const updatedCartData = await removeCartItem(cartId, item.id.toString(), user);
      updateCartItemsCount(updatedCartData, dispatch);
      setIsSpinnerLoading(false);
      return updatedCartData;
    }
    if (!cartData) {
      // eslint-disable-next-line no-param-reassign
      cartData = await getCartItems(cartId, user);
      cartItem = cartData.items.find((cart) => cart.id === item.id);
    }
    const data = prepareJsonData(
      cartData,
      cartItem as CartItem,
      hotelId,
      productType?.toString(),
      isRemoveAll,
    );
    const updatedCartData = await updateCartItems(
      data,
      new Date(cartDate),
      dispatch,
      undefined,
      undefined,
      user,
    );
    updateCartItemsCount(updatedCartData, dispatch);
    setIsSpinnerLoading(false);
    return updatedCartData;
  } catch (error) {
    setIsSpinnerLoading(false);
    toggleErrorPopup(error, dispatch);
  }
  return cartData;
};

const removeAddOnItem = async (
  addOn: AddonItems,
  setIsSpinnerLoading: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: React.Dispatch<UserAction>,
  cartData?: CartItemDetails | undefined,
  user?: User,
) => {
  try {
    setIsSpinnerLoading(true);
    const cartId = getItem(cartIdKey);
    const updatedCartData = await deleteAddOn(cartId, addOn.id, user);
    updateCartItemsCount(updatedCartData, dispatch);
    setIsSpinnerLoading(false);
    return updatedCartData;
  } catch (error) {
    setIsSpinnerLoading(false);
    toggleErrorPopup(error, dispatch);
  }
  return cartData;
};

/**
 * Retrieves the products in the cart based on checkout data and a list of products.
 *
 * @param {CheckoutInfo | undefined} checkoutData - The checkout information.
 * @param {Product[]} products - The list of products.
 * @returns {Product[]} - An array of products in the cart.
 */
const productsInTheCart = (checkoutData: CheckoutInfo | undefined | null, products: Product[]) => {
  if (checkoutData && products) {
    const uniqueProductIds = Array.from(new Set(checkoutData.items.map((item) => item.product_id)));

    const nonTimeSlotProductsInTheCart = products.filter((product: Product) =>
      uniqueProductIds.includes(product.product_id),
    );

    const timeSlotProductsInTheCart = products.reduce((result: Product[], product: Product) => {
      if (product.is_product_time_group) {
        const matchingIds =
          product.children &&
          product.children.filter((child) => uniqueProductIds.includes(child.product_id));

        if (matchingIds) {
          result.push(...matchingIds);
        }
      }

      return result;
    }, []);

    return [...timeSlotProductsInTheCart, ...nonTimeSlotProductsInTheCart];
  }

  return [];
};

export {
  createCartItems,
  updateCartItems,
  createOrUpdateCartItems,
  removeSingleCartItem,
  removeAddOnItem,
  deleteCartAndRemoveKeys,
  productsInTheCart,
};
