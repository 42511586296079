const cloudFlareBaseUrl = process.env.NEXT_PUBLIC_CLOUDFLARE_RESIZE_IMAGE_LINK;
const s3AssetsBaseUrl = process.env.NEXT_PUBLIC_S3_STATIC_ASSETS_LINK;
const assetsBaseUrl = process.env.NEXT_PUBLIC_DYNAMIC_ASSETS_LINK;
const CDN_URL = 'https://static-assets.resortpass.com/assets';

const isAvif = /.*\.(avif)$/i;

const getCdnImageUrl = (
  width: number,
  name?: string,
  urlPrefix?: string,
  quality?: number,
  dpr?: number,
): string => {
  const widthText = width.toString();
  const qualityText = quality ? quality.toString() : '95';
  const dprText = dpr ? dpr.toString() : '2';

  const baseAssetUrl = `${urlPrefix || urlPrefix === '' ? urlPrefix : assetsBaseUrl}${name}`;
  if (isAvif.test(baseAssetUrl)) return baseAssetUrl;

  return `${cloudFlareBaseUrl}/quality=${qualityText},width=${widthText},dpr=${dprText},format=auto/${
    urlPrefix || urlPrefix === '' ? urlPrefix : assetsBaseUrl
  }${name}`;
};

const getStaticImageUrl = (name: string) => `${s3AssetsBaseUrl}${name}`;
const getStaticFileUrl = (name: string) => `${s3AssetsBaseUrl}${name}`;

const getStaticIconsUrl = (name: string) => `${s3AssetsBaseUrl}/www-icons/${name}`;

const getProductTypeIcon = (productType: string) =>
  `${s3AssetsBaseUrl}/www-icons/product-types/${productType}`;

export {
  getCdnImageUrl,
  getStaticImageUrl,
  getProductTypeIcon,
  getStaticIconsUrl,
  getStaticFileUrl,
  CDN_URL,
};
