/* eslint-disable no-underscore-dangle */
import sessionStorageKeys from '@constants/sessionStorageKeys';

import { CheckoutInfo } from '@customTypes/checkout-info';
import HotelDetails from '@customTypes/hotel-details';
import { Events } from '@customTypes/iterable';
import Product from '@customTypes/product';
import User from '@customTypes/user';

import { getDateWithDashes } from '@helpers/date';
import { calculateTotalPrice } from '@helpers/product';
import { getParamIfSource } from '@helpers/utmParams';

import axios from 'axios';

import { isEmpty } from 'lodash';

import getToken from './token';

declare global {
  interface Window {
    Iterable: any;
  }
}

type IterableInit = {
  userToken: string;
  email: string;
  utmParams: any;
};

const ITERABLE_API_BASE_URL = 'https://api.iterable.com';
class Iterable {
  private static instance: Iterable;

  _apiKey: string | undefined;

  _email: string = '';

  _userToken: string | undefined;

  _campaignId: number | null = null;

  _templateId: number | null = null;

  private constructor({ userToken, email, utmParams }: IterableInit) {
    if (!userToken) {
      return;
    }

    if (!process.env.NEXT_PUBLIC_ITERABLE_KEY) {
      throw new Error('Missing Iterable Key');
    }

    this._apiKey = process.env.NEXT_PUBLIC_ITERABLE_KEY;
    this._userToken = userToken;
    this._email = email;

    const campaignId = getParamIfSource('utm_campaign', 'iterable', utmParams);
    const templateId = getParamIfSource('utm_templateId', 'iterable', utmParams);

    this._campaignId = campaignId ? +campaignId.replace(/\D/g, '') : null;
    this._templateId = templateId ? +templateId.replace(/\D/g, '') : null;
  }

  /**
   * The static method that controls the access to the Iterable singleton instance.
   *
   * Subclass the singleton and keep just one instance of each subclass around.
   */
  public static async getInstance({
    user,
    email,
    utmParams,
  }: {
    user: User;
    email: string;
    utmParams: any;
  }): Promise<any> {
    if (!Iterable.instance) {
      try {
        const userToken = await getToken(email);
        Iterable.instance = new Iterable({ userToken, email, utmParams });

        await Iterable.instance.updateUser(user);
      } catch (error) {
        // We need to handle this gracefully
      }
    }

    return Iterable.instance;
  }

  trackCustomEvent = (eventName: Events, data: any) => {
    const payload = {
      email: this._email,
      eventName,
      dataFields: {
        ...data,
      },
      campaignId: this._campaignId,
      templateId: this._templateId,
    };

    try {
      return axios.post(`${ITERABLE_API_BASE_URL}/api/events/track`, payload, {
        headers: {
          'Api-Key': this._apiKey,
          Authorization: `Bearer ${this._userToken}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (e: any) {
      //
    }

    return null;
  };

  /**
   * Iterable Update cart event.
   * POST - /api/commerce/updateCart
   * "Add to Cart" button was clicked on a hotel detail page.
   * @param addOnItems
   * @param adultsCount
   * @param childrenCount
   * @param date
   * @param hotelDetails
   * @param product
   */
  addedToCart = (
    addOnItems: any,
    adultsCount: number,
    childrenCount: number,
    date: Date,
    hotelDetails: HotelDetails,
    product: Product,
  ) => {
    const data = [
      {
        id: product.product_id.toString(),
        name: product.name,
        price: product.adult_price,
        quantity: adultsCount,
        dataFields: {
          addToCartDate: getDateWithDashes(new Date()),
          bookingSource: 'web',
          cartAdults: adultsCount,
          cartChildren: childrenCount,
          cartProducts: [
            {
              adultPrice: +product.adult_price,
              adultTotal: +product.adult_price * adultsCount,
              childrenPrice: +product.children_price,
              childrenTotal: +product.children_price * childrenCount,
              productAdults: adultsCount,
              productChildren: childrenCount,
              productName: product.name,
              productType: product.product_type,
            },
          ],
          cartTotal: calculateTotalPrice(product, adultsCount, childrenCount, addOnItems),
          hotelCity: hotelDetails.city_name,
          hotelID: hotelDetails.id,
          hotelRegion: hotelDetails.region,
          hotelState: hotelDetails.code,
          stayDate: getDateWithDashes(date),
        },
      },
    ];

    try {
      return axios.post(
        `${ITERABLE_API_BASE_URL}/api/commerce/updateCart`,
        {
          user: {
            email: this._email,
          },
          items: data,
        },
        {
          headers: {
            'Api-Key': this._apiKey,
            Authorization: `Bearer ${this._userToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
    } catch (error) {
      //
    }

    return null;
  };

  /**
   * A hotel detail page is visited by the user.
   * @param hotel: HotelDetails
   */
  hotelViewed = (hotel: HotelDetails) => {
    const data = {
      browsingSource: 'web',
      hotelCity: hotel.city_name,
      hotelID: hotel.id,
      hotelRegion: hotel.region,
      hotelState: hotel.code,
      viewDate: getDateWithDashes(new Date()),
    };

    this.trackCustomEvent('viewHotel', data);
  };

  /**
   * Iterable search event.
   * The user performed a search on the website.
   * @param searchTerm string
   * @param searchDate string
   */
  search = (searchTerm: string, searchDate: Date | undefined) => {
    const data = {
      searchTerm,
      searchDate: searchDate ? getDateWithDashes(searchDate) : '',
      searchPerformedDate: getDateWithDashes(new Date()),
      searchURL: window.location.href,
      searchSource: 'web',
    };

    this.trackCustomEvent('searchCompleted', data);
  };

  /**
   * Iterable started checkout event.
   * The "Proceed to Checkout" button is clicked on the Cart page.
   * @param checkoutInfo
   * @param hotelDetails
   * @param date
   */
  startedCheckout = (checkoutInfo: CheckoutInfo, hotelDetails: HotelDetails, date: Date) => {
    const data = {
      cartAdults: checkoutInfo.items.reduce((sum, item) => sum + item.no_of_adults, 0),
      cartChildren: checkoutInfo.items.reduce((sum, item) => sum + item.no_of_childs, 0),
      cartSource: 'web',
      cartProducts: checkoutInfo.items.map((item) => ({
        adultPrice: item.adult_price,
        adultTotal: item.adult_total,
        childrenPrice: item.child_price,
        childrenTotal: item.child_total,
        productAdults: item.no_of_adults,
        productChildren: item.no_of_childs,
        productName: item.name,
        productType: item.product_type,
      })),
      cartTotal: checkoutInfo.subtotal,
      hotelCity: hotelDetails.city_name,
      hotelID: checkoutInfo.hotel_id.toString(),
      hotelRegion: hotelDetails.region,
      hotelState: hotelDetails.code,
      startCheckoutDate: getDateWithDashes(new Date()),
      stayDate: getDateWithDashes(date),
    };

    this.trackCustomEvent('startedCheckout', data);
  };

  /**
   * Updates user data or adds a user if none exists.
   * POST - /api/users/update
   * @param user
   * @return
   */
  updateUser = (user: User | undefined) => {
    const data = {
      email: this._email,
      dataFields: {
        ...(user && user?.birth_day ? { birthday: user.birth_day } : {}),
        ...(user && user.city ? { city: user.city } : {}),
        ...(user && user.first_name ? { firstName: user.first_name } : {}),
        ...(user && user.last_name ? { lastName: user.last_name } : {}),
      },
    };

    try {
      if (this._email === 'undefined@placeholder.email') return null;

      return axios.post(`${ITERABLE_API_BASE_URL}/api/users/update`, data, {
        headers: {
          'Api-Key': this._apiKey,
          Authorization: `Bearer ${this._userToken}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (e: any) {
      //
    }

    return null;
  };

  /**
    Sets a new email for the user and updates the user token, then updates the user data.
    @async
    @param {string} email - The new email to be set for the user.
    @returns {Promise<void>} - A Promise that resolves
      when the user data has been updated successfully.
  */
  useANewEmail = async (email: string) => {
    this._email = email;
    this._userToken = await getToken(email).then((token) => token);

    await this.updateUser(undefined);
  };

  /**
   * Change a user's email address.
   * All profile data and events will be migrated to the new email address.
   * POST - /api/users/updateEmail
   * It will automatically generate a new JWT token for the newEmail
   * @param user
   * @param newEmail
   * @param oldEmail
   * @returns
   */
  updateUserEmail = async (newEmail: string, currentEmail: string, user?: User) => {
    if (
      isEmpty(newEmail) ||
      !currentEmail.endsWith('@placeholder.email') ||
      newEmail === currentEmail
    ) {
      return null;
    }

    const data = {
      currentEmail,
      newEmail,
      merge: true,
    };

    try {
      const response = await axios.post(`${ITERABLE_API_BASE_URL}/api/users/updateEmail`, data, {
        headers: {
          'Api-Key': this._apiKey,
          Authorization: `Bearer ${this._userToken}`,
          'Content-Type': 'application/json',
        },
      });

      this._email = newEmail;
      this._userToken = await getToken(this._email).then((token) => token);

      if (user) {
        await Iterable.instance.updateUser(user);
      }

      return response;
    } catch (error) {
      //
    }

    return null;
  };

  userLogout = async () => {
    const placeholderEmail = `${sessionStorage.getItem(
      sessionStorageKeys.SESSION_ID,
    )}@placeholder.email`;

    if (placeholderEmail) {
      this._email = placeholderEmail;
      this._userToken = await getToken(placeholderEmail).then((token) => token);

      await Iterable.instance.updateUser({
        available_credit: '',
        avatar: {
          url: null,
          results: {
            url: null,
          },
          details: {
            url: null,
          },
        },
        birth_day: null,
        city: null,
        email: '',
        first_name: null,
        id: 0,
        is_impersonated: false,
        last_name: null,
        payment_methods: [],
        save_cc_details: false,
        token: '',
      });
    }
  };
}

export default Iterable;
