const AVAILABILITY = {
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
  SOLD_OUT: 'Sold Out',
};

const PRODUCT_TITLE_CATEGORY_TEXT = {
  spa: 'The Spa at ',
  pool: 'The Pool at ',
  'day room': 'Day Rooms at ',
};

const PRODUCT_CATEGORY_TEXT = {
  spa: 'spa',
  pool: 'pool',
  'day room': 'day room',
};

export default AVAILABILITY;

export { PRODUCT_TITLE_CATEGORY_TEXT, PRODUCT_CATEGORY_TEXT };
