import { SearchState } from '@customTypes/search';
import { GetDefaultSearchState } from '@context/SearchContext2025';
import { Location } from '@components/Hotels/types';

// eslint-disable-next-line import/prefer-default-export
export function isUrlParamPresent(paramName: string) {
  const params = new URLSearchParams(window.location.search);

  return params.has(paramName);
}

const searchPathPrefix = '/s/';
/**
 * Parse the search URL and return a SearchState object
 * @param url - The URL to parse
 * @returns SearchState
 *
 * @example input:
 *   https://www.resortpass.com/s/us/fl/miami/spa?date=2025-01-27
 *       &amenities=all-inclusive&amenities=beach&amenities=fitness-center
 *       &amenities=spa&amenities=rooftop-pool&amenities=hottub
 *       &amenities=lazyriver&amenities=waterslide
 *       &hotel_stars=%3D5
 *       &vibes=family-friendly&vibes=party&vibes=serene&vibes=luxe&vibes=trendy
 *       &only_available=true
 *       &guest_rating=%3E%3D3.8
 */
export function ParseSearchUrl(url: string | URL, userLocation?: Location | null): SearchState {
  const searchState: SearchState = GetDefaultSearchState();
  let urlObj: URL;
  if (typeof url === 'string') {
    if (url.startsWith('http')) {
      urlObj = new URL(url);
    } else if (url.startsWith('/')) {
      // we don't care about the origin,
      // so we'll just construct a URL with a dummy origin
      urlObj = new URL(`http://localhost${url}`);
    } else {
      urlObj = new URL(`http://localhost/${url}`);
    }
  } else {
    urlObj = url;
  }

  let path = urlObj.pathname;
  const isNearMe = path.startsWith(`${searchPathPrefix}near-me`);

  // check for nextjs ssr JSON api
  const nextjsJSONAPI = /\/_next\/data\/[^/]+\/(.+).json(.*)/;
  if (nextjsJSONAPI.test(path)) {
    path = path.replace(nextjsJSONAPI, '/$1$2');
  }

  // check if the URL is a search URL
  if (!path.startsWith(searchPathPrefix)) throw new Error(`Invalid search URL: path=${path}`);

  // remove the search path prefix
  let searchUrl = path.slice(searchPathPrefix.length);

  // check if it is the "near-me" search
  if (isNearMe) {
    const containsCategory = searchUrl.split('/').length > 1;
    searchUrl = [
      searchUrl.replace(
        'near-me',
        userLocation
          ? `${userLocation.country_code}/${userLocation.state_code}/${userLocation.city_name}`
          : 'us/ny/new york',
      ),
      ...(containsCategory ? [] : ['day-passes']),
    ].join('/');
  }

  const [countryCode, stateCode, city, productCategory] = ((searchPath: string) => {
    const split = searchPath.split('/');
    // city search
    if (split.length === 4) {
      return split.map(decodeURIComponent);
    }

    // state search
    if (split.length === 3) {
      return [split[0], split[1], '', split[2]].map(decodeURIComponent);
    }

    // country search
    if (split.length === 2) {
      return [split[0], '', '', split[1]].map(decodeURIComponent);
    }
    throw new Error('Invalid search URL');
  })(searchUrl);

  searchState.country_code = countryCode;
  searchState.state_code = stateCode;
  searchState.city = city;
  searchState.product_category = productCategory === 'day-passes' ? '' : productCategory;
  searchState.q = urlObj.searchParams.get('q') || '';
  searchState.date = urlObj.searchParams.get('date') || '';
  searchState.hotel_name = urlObj.searchParams.get('hotel_name') || '';
  searchState.filters.only_available = urlObj.searchParams.get('only_available') === 'true';
  searchState.filters.only_recent = urlObj.searchParams.get('only_recent') === 'true';
  searchState.search_term = urlObj.searchParams.get('search_term') || '';

  // near me doesn't have a searchTerm, so we try to populate it via the location name
  if (isNearMe && userLocation && !searchState.search_term) {
    searchState.search_term = userLocation.name;
  }

  const amenities = urlObj.searchParams.getAll('amenities');
  const hotelStars = urlObj.searchParams.getAll('hotel_stars');
  const guestRating = urlObj.searchParams.getAll('guest_rating');
  const vibes = urlObj.searchParams.getAll('vibes');
  const productTypes = urlObj.searchParams.getAll('pid');
  searchState.filters.product_types = productTypes
    .map((pid) => parseInt(pid, 10))
    .filter((pid) => !Number.isNaN(pid));

  const cityAliasId = urlObj.searchParams.get('city_alias_id');
  if (cityAliasId) {
    const aliasId = parseInt(cityAliasId, 10);
    if (!Number.isNaN(aliasId)) {
      searchState.filters.city_alias_id = aliasId;
    }
  }

  searchState.filters.amenities = {
    'all-inclusive': amenities.includes('all-inclusive'),
    beach: amenities.includes('beach'),
    'fitness-center': amenities.includes('fitness-center'),
    spa: amenities.includes('spa'),
    'rooftop-pool': amenities.includes('rooftop-pool'),
    hottub: amenities.includes('hottub'),
    lazyriver: amenities.includes('lazyriver'),
    waterslide: amenities.includes('waterslide'),
  };

  searchState.filters.hotel_stars = {
    '=5': hotelStars.includes('=5'),
    '>=4': hotelStars.includes('>=4'),
    '>=3': hotelStars.includes('>=3'),
  };

  searchState.filters.guest_ratings = {
    '>=3.8': guestRating.includes('>=3.8'),
  };

  searchState.filters.vibes = {
    'family-friendly': vibes.includes('family-friendly'),
    party: vibes.includes('party'),
    serene: vibes.includes('serene'),
    luxe: vibes.includes('luxe'),
    trendy: vibes.includes('trendy'),
  };

  return searchState;
}

/**
 * Generate a search URL path from a SearchState object
 * @param searchState - The SearchState object
 * @returns string
 *
 * @example
 * "/s/us/fl/miami/spa?q=&date=2025-01-27&amenities=all-inclusive&amenities=beach
 *  &amenities=fitness-center&amenities=spa&amenities=rooftop-pool
 *  &amenities=hottub&amenities=lazyriver&amenities=waterslide
 *  &hotel_stars=%3D5
 *  &vibes=family-friendly&vibes=party&vibes=serene&vibes=luxe&vibes=trendy
 *  &only_available=true&guest_rating=%3E%3D3.8"
 *  &hotel_name=hotel-name
 */
export function GenerateSearchUrl(searchState: SearchState): string {
  const url = new URL('https://www.resortpass.com');
  url.pathname = searchPathPrefix;

  const {
    country_code: countryCode,
    state_code: stateCode,
    city,
    product_category: productCategory,
    q,
    date,
    filters,
  } = searchState;

  if (!countryCode) {
    throw new Error('Invalid search state');
  }

  url.pathname += `${countryCode.toLowerCase()}/`;
  if (stateCode) {
    url.pathname += `${stateCode.toLowerCase()}/`;
  }
  if (city) {
    url.pathname += `${city.toLowerCase()}/`;
  }

  if (!productCategory) {
    url.pathname += 'day-passes';
  } else {
    url.pathname += productCategory;
  }

  if (q) {
    url.searchParams.set('q', q);
  }

  // Date || 'any'
  if (date) {
    url.searchParams.set('date', date);
  }

  if (searchState.filters.only_available) {
    url.searchParams.set('only_available', 'true');
  }

  if (searchState.filters.only_recent) {
    url.searchParams.set('only_recent', 'true');
  }

  if (searchState.hotel_name) {
    url.searchParams.set('hotel_name', searchState.hotel_name);
  }

  if (searchState.search_term) {
    url.searchParams.set('search_term', searchState.search_term);
  }

  if (searchState.filters.city_alias_id) {
    url.searchParams.set('city_alias_id', searchState.filters.city_alias_id.toString());
  }

  Object.entries(filters.amenities).forEach(([amenity, value]) => {
    if (value) {
      url.searchParams.append('amenities', amenity);
    }
  });

  Object.entries(filters.hotel_stars).forEach(([star, value]) => {
    if (value) {
      url.searchParams.append('hotel_stars', star);
    }
  });

  Object.entries(filters.guest_ratings).forEach(([rating, value]) => {
    if (value) {
      url.searchParams.append('guest_rating', rating);
    }
  });

  Object.entries(filters.vibes).forEach(([vibe, value]) => {
    if (value) {
      url.searchParams.append('vibes', vibe);
    }
  });

  filters.product_types.forEach((pid) => {
    url.searchParams.append('pid', pid.toString());
  });

  return `${url.pathname}?${url.searchParams.toString()}`;
}
