import React, { useState, useEffect } from 'react';
import Tabs from '@solar/common/Tabs/Tabs';
import useIsMobile from '@hooks/useIsMobile';
import SearchControls from '@components/SearchExperiment/SearchControls/SearchControls';
import MobileSearchControls from '@components/SearchExperiment/SearchControls/MobileSearchControls';
import { useSearchContext } from '@context/SearchContext2025';
import { SEARCH_DEFAULT_FILTERS } from '@constants/SearchDefaultOptions';
import { applyUserLocation } from '@helpers/location';
import { useUserLocation } from '@context/GeoIpDetectionContext';
import { generateProductTabsHref } from '@helpers/filters';
import { useAmplitudeApplyProductCategoryTracker } from '@hooks/useAmplitudeTrackers';

type Props = {
  isStickyHeaderVisible: boolean;
};

export default function SearchBar({ isStickyHeaderVisible }: Props) {
  const [showDateSelector, setShowDateSelector] = useState<boolean>(false);
  const [showLocationResults, setShowLocationResults] = useState<boolean>(false);

  const userLocation = useUserLocation();
  const isMobile = useIsMobile({ isSolarSystem: true });
  const {
    setSearchTerm,
    setCountryCode,
    setCity,
    setHotelName,
    setStateCode,
    setProductCategory,
    setAliasId,
    searchParams,
  } = useSearchContext();

  const amplitudeProductCategoryTracker = useAmplitudeApplyProductCategoryTracker();
  const onHomePageCategorySelect = (value: string) => {
    const lc = value.toLowerCase();
    if (lc === 'all') {
      setProductCategory('');
    } else {
      setProductCategory(lc);
    }
    amplitudeProductCategoryTracker(lc);
  };

  // Scroll to search results when location results are shown
  useEffect(() => {
    if (showDateSelector || showLocationResults) {
      // Search result div is placed in homepage video to make sure it
      // scroll until the beginning of the title
      const searchResultsElement = document.getElementById('search-results');

      if (searchResultsElement) {
        searchResultsElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    return () => {
      window.onbeforeunload = () => {
        window.scrollTo(0, 0);
      };
    };
  }, [showDateSelector, showLocationResults]);

  // Hide the date selector and location results when the user scrolls past the search bar.
  useEffect(() => {
    if (isStickyHeaderVisible) {
      setShowDateSelector(false);
      setShowLocationResults(false);
    }
  }, [isStickyHeaderVisible]);

  useEffect(() => {
    if (userLocation) {
      applyUserLocation(userLocation, {
        setCountryCode,
        setCity,
        setHotelName,
        setStateCode,
        setSearchTerm,
        setAliasId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation]);

  return (
    <div
      id="search-bar"
      className="flex flex-col w-full bg-solar-surface-primary px-spacing-md pb-spacing-md text-solar-primary space-y-spacing-md desktop:px-spacing-none desktop:rounded-solar-sm desktop:border d:border-solar-secondary desktop:shadow-solar-search"
    >
      <Tabs defaultValue="all" onSelect={onHomePageCategorySelect} size="md">
        <Tabs.List className="justify-between desktop:justify-start desktop:space-x-spacing-xl desktop:px-4">
          {SEARCH_DEFAULT_FILTERS.map((filter) => (
            <Tabs.Tab
              key={filter.name}
              icon={filter.icon}
              iconPosition="left"
              value={filter.name}
              href={generateProductTabsHref(searchParams, filter.name)}
              className="capitalize flex-1 w-full desktop:flex-none desktop:w-auto"
            >
              {filter.name}
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </Tabs>

      {isMobile ? (
        <MobileSearchControls />
      ) : (
        <div className="px-spacing-md">
          <SearchControls layout="row-same-width" buttonVariant="text" dropdownLayout="compact" />
        </div>
      )}
    </div>
  );
}
