import React from 'react';
import Paragraph from '../Paragraph/Paragraph';

type Props = {
  text: string;
  size?: 'sm' | 'md' | 'lg';
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  className?: string;
};

const textSizeMapping: Record<'sm' | 'md' | 'lg', 'xs' | 'sm' | 'md'> = {
  sm: 'xs',
  md: 'sm',
  lg: 'md',
};

export default function Chip({ size = 'md', leftIcon, rightIcon, text, className }: Props) {
  return (
    <div
      className={`bg-solar-surface-secondary flex items-center px-spacing-sm py-spacing-xs rounded-solar-xxl space-x-spacing-xs text-solar-primary ${className}`.trim()}
    >
      {leftIcon || null}

      <Paragraph size={textSizeMapping[size]}>{text}</Paragraph>

      {rightIcon || null}
    </div>
  );
}

Chip.defaultProps = {
  size: 'md',
  leftIcon: null,
  rightIcon: null,
  className: '',
};
