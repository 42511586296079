import React, { createContext, useContext, useMemo } from 'react';

export type SpringSaleCampaignFeatureFlagType = 'original' | 'sale';

export type FeatureFlagsType = {
  springSaleCampaign: SpringSaleCampaignFeatureFlagType;
};

const FeatureFlagContext = createContext<FeatureFlagsType | undefined>(undefined);

export const useFeatureFlagContext = () => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error('useFeatureFlagContext must be used within a FeatureFlagContext');
  }

  return context;
};

type FeatureFlagProviderProps = {
  children: React.ReactNode;
  value: {
    springSaleCampaign: SpringSaleCampaignFeatureFlagType;
  };
};

export function FeatureFlagProvider({ children, value }: FeatureFlagProviderProps) {
  const { springSaleCampaign } = value;

  const contextValue = useMemo(
    () => ({
      springSaleCampaign,
    }),
    [springSaleCampaign],
  );

  return <FeatureFlagContext.Provider value={contextValue}>{children}</FeatureFlagContext.Provider>;
}
